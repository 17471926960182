import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";

const devConfig = {
  apiKey: "AIzaSyA3xCnhE5fPjWLr6AxtAxD1RU_SLZ61VEk",
  authDomain: "prccrm-65ae7.firebaseapp.com",
  databaseURL: "https://prccrm-65ae7.firebaseio.com",
  projectId: "prccrm-65ae7",
  storageBucket: "prccrm-65ae7.appspot.com",
  messagingSenderId: "664341207876",
  appId: "1:664341207876:web:c91e0c97360409b64c5511",
};

const prodConfig = {
  apiKey: "AIzaSyAbit3zZP4l9PgpXbxsBC0ymMsRcsU5FJ0",
  authDomain: "eden-73426.firebaseapp.com",
  databaseURL: "https://eden-73426.firebaseio.com",
  projectId: "eden-73426",
  storageBucket: "eden-73426.appspot.com",
  messagingSenderId: "398306459930",
  appId: "1:398306459930:web:3c2f2a2d2bbe6098a82ca3",
  measurementId: "G-LFCR976X54",
};

const devApp = firebase.initializeApp(devConfig); // prccrm app - development
const prodApp = firebase.initializeApp(prodConfig, "production"); // EDEN app - production

let namespace;
let storage;
let auth;
let db;

if (process.env.REACT_APP_ENV === "production") {
  namespace = prodApp;
  storage = firebase.storage(prodApp);
  auth = firebase.auth(prodApp);
  db = firebase.firestore(prodApp);
} else {
  namespace = devApp;
  storage = firebase.storage();
  auth = firebase.auth();
  db = firebase.firestore();
}

export { storage, auth, db };
export default namespace;
