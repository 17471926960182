import React, { useState, useEffect } from 'react';
import StudentContent from './StudentsContent';
import { getDocID } from '../../global/globalFunctions';

const Students = () => {
	const [teacherDocId, setTeacherDocId] = useState(false);

	useEffect(() => {
		const getTeacherDocId = async () => {
			try {
				const procId = await getDocID();
				setTeacherDocId(procId);
			} catch (error) {
				console.log('getTeacherDocId', error);
			}
		};
		getTeacherDocId();
	}, []);
	return <StudentContent teacherDocId={teacherDocId} userType="teacher" />;
};

export default Students;
