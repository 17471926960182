import React, { useState, useEffect } from 'react';
import Home from '../../Home';
import HomeStudent from '../../HomeStudent';
import {
	getDocID,
	getProject,
	getStudentArray,
	getStudentNotebooks,
	getNotebooks,
	getNotebookPurchases,
	getProjectsLevel1,
	getProjectsLevel2,
	getProjectScoring,
	getSubmittedNotebookID,
	deleteSubmittedNotebookID,
	saveCompetition,
	getGroups,
	addNewNotebookGroup,
	addNewNotebook,
	updateNotebook,
	deleteProject,
} from '../../global/globalFunctions';
// import styles from "./Project.module.css";
import ShowProject from './ShowProject';
import NotebooksTable from './NotebooksTable';
import appBase from '../../Auth/base';
import Spinner from '../../UI/Spinner/Spinner';
import Modal from '../../UI/Modals/RegularModal';
import FullscreenModal from '../../UI/Modals/FullscreenModal';
import AddEditNotebook from './AddEditNotebook';
import ShowNotebook from './ShowNotebook';
import ShowCompetition from './ShowCompetition';
import AddEditProject from '../Projects/AddEditProjects';
import { useHistory } from 'react-router';
import { ROUTES } from '../../global/constants/routes';
import { useAuthContext } from '../../context';

const Project = (props) => {
	const { teacherId, userType } = useAuthContext();
	const [spinner, setSpinner] = useState(true);
	const [projectID] = useState(props.match.params.id);
	const [project, setProject] = useState(false);
	const [projectList, setProjectList] = useState([]);
	const [projectDropdown, setProjectDropdown] = useState([]);
	const [scoring, setScoring] = useState([]);
	const [projectDropdown2, setProjectDropdown2] = useState([]);
	const [notebookList, setNotebookList] = useState([]);
	const [notebookListDisplay, setNotebookListDisplay] = useState([]);
	const [filteredNotebook, setFilteredNotebook] = useState({
		id: null,
		title: null,
	});
	const [notebookGroups, setNotebookGroups] = useState([]);
	const [teacherDocID, setTeacherDocID] = useState(false);
	const [modalNew, setModalNew] = useState({
		show: false,
	});
	const [modalEdit, setModalEdit] = useState(false);
	const [projectNotebookArr, setProjectNotebookArr] = useState({});
	const [showNotebookModal, setShowNotebookModal] = useState(false);
	const [competitionModal, setCompetitionModal] = useState({
		show: false,
		notebookID: null,
	});
	const [selectedNotebook, setSelectedNotebook] = useState(null);
	const [studentList, setStudentList] = useState([]);
	const [studentNotebook, setStudentNotebook] = useState([]);
	const [submittedNotebookID, setSubmittedNotebookID] = useState(null);

	const history = useHistory();

	const getGroupTitle = (id) => {
		if (id === null) {
			return null;
		}
		const ttl = notebookGroups.filter((g) => g.id === id);
		return ttl[0].title;
	};

	const openModalNew = (type, nbInfo) => {
		setModalNew({ ...nbInfo, show: true, type: type });
	};
	const openModalEdit = () => {
		setModalEdit(true);
	};
	const closeModalEdit = () => {
		setModalEdit(false);
	};
	const closeModalNew = () => {
		setModalNew({ ...modalNew, show: false });
	};

	useEffect(() => {
		const getProjects = async () => {
			try {
				const processedLevel1 = await getProjectsLevel1();
				setProjectDropdown(processedLevel1);
				const processedLevel2 = await getProjectsLevel2(processedLevel1);
				setProjectDropdown2(processedLevel2);
			} catch (error) {
				// setSpinner(false);
				console.log('getProjects catch error', error);
			}
		};
		getProjects();
	}, []);

	useEffect(() => {
		const getUIDandProject = async () => {
			try {
				let processedTeacherDocID = teacherId;
				if (userType !== 'mentor') {
					processedTeacherDocID = await getDocID();
				}
				setTeacherDocID(processedTeacherDocID);
				const processedResponse = await getProject(processedTeacherDocID, projectID);
				setProject(processedResponse);

				const procSubmittedNotebookID = await getSubmittedNotebookID(projectID);
				setSubmittedNotebookID(procSubmittedNotebookID);

				const processedNotebookGroups = await getGroups(processedTeacherDocID, projectID);
				setNotebookGroups(processedNotebookGroups);

				const processNotebooks = await getNotebooks(processedTeacherDocID, projectID, processedNotebookGroups);

				const flattenedArray = await processNotebooks.map((nb) => {
					let ar = [];
					nb.scores.forEach(
						(n, i) =>
							(ar = {
								...ar,
								[n.id]: n.score,
								compete: procSubmittedNotebookID === nb.id ? 1 : 0,
							})
					);
					return { ...nb, ...ar };
				});

				setNotebookList(flattenedArray);
				setNotebookListDisplay(flattenedArray);

				const processedScoring = await getProjectScoring(processedTeacherDocID, projectID);
				setScoring(processedScoring);

				setSpinner(false);
			} catch (error) {
				console.log('async error', error);
				setSpinner(false);
			}
		};
		getUIDandProject();
	}, [projectID, teacherId, userType]);

	const closeShowNotebookModal = () => {
		setShowNotebookModal(false);
		// setSelectedNotebook([]);
	};

	const closeCompetitionModal = () => {
		setCompetitionModal({ ...competitionModal, show: false });
		// setSelectedNotebook([]);
	};

	const headerData = () => {
		let headers = [
			{
				id: 'title',
				numeric: false,
				disablePadding: true,
				label: 'Notebook',
			},
			{
				id: 'fileSize',
				numeric: false,
				disablePadding: true,
				label: 'Size',
			},
			{
				id: 'isTrial',
				numeric: false,
				disablePadding: true,
				label: 'Type',
			},
			{
				id: 'createdDT',
				numeric: false,
				disablePadding: false,
				label: 'Created On',
			},
		];
		scoring.forEach((s) => {
			const arr = {
				id: s.id,
				numeric: true,
				disablePadding: true,
				label: s.title,
			};
			headers.push(arr);
		});
		const competeHeader = {
			id: 'compete',
			numeric: true,
			disablePadding: false,
			label: 'Compete',
		};
		headers.push(competeHeader);
		if (notebookGroups.length > 0) {
			const groupHeader = {
				id: 'groupTitle',
				numeric: false,
				disablePadding: true,
				label: 'Group',
			};
			headers = [groupHeader, ...headers];
		}
		return headers;
	};

	let returnVal = <Spinner />;
	if (!spinner) {
		returnVal = (
			<>
				<Modal show={modalNew.show} header={modalNew.type + ' Notebook'} handleClose={closeModalNew}>
					<AddEditNotebook
						projectNotebookArr={projectNotebookArr}
						groups={notebookGroups}
						handleClose={closeModalNew}
						data={modalNew}
						handleSave={(event) => {
							// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
							// add final check here to get notebooks remaining and ensure there are enough purchased
							event.preventDefault();
							closeModalNew();
							const procAddNewNB = async () => {
								try {
									setSpinner(true);
									const { title, nmGroup, txtGroup, hdnTrial } = event.target.elements;

									let newNotebook = {
										title: title.value,
									};
									if (modalNew.type === 'New') {
										newNotebook = {
											...newNotebook,
											createdDT: new Date(),
											projectID: projectID,
											isViewable: true,
											isTrial: hdnTrial.value === 'true' ? true : false,
										};
									}

									let newGroupID = null;
									let newGroupTitle = null;
									if (nmGroup) {
										if (nmGroup.value.length > 0 && nmGroup.value !== 'new') {
											newNotebook = { ...newNotebook, group: nmGroup.value };
										}
									} else if (txtGroup) {
										if (txtGroup.value.length > 0) {
											newGroupID = await addNewNotebookGroup(teacherDocID, projectID, txtGroup.value);
											newNotebook = {
												...newNotebook,
												group: newGroupID,
											};
											setNotebookGroups([{ id: newGroupID, title: txtGroup.value }, ...notebookGroups]);
											newGroupTitle = txtGroup.value;
										}
									}
									if (modalNew.type === 'New') {
										const newNotebookID = await addNewNotebook(teacherDocID, newNotebook);
										if (newNotebookID) {
											const dateAdd = new Date().getTime();
											const addedNotebook = {
												...newNotebook,
												id: newNotebookID,
												createdDT: Math.round(dateAdd / 1000),
												groupTitle:
													newNotebook.group && !newGroupTitle
														? getGroupTitle(newNotebook.group)
														: newGroupTitle
														? newGroupTitle
														: '',
												scores: [],
											};
											// console.log(addedProject);
											setNotebookList([addedNotebook, ...notebookList]);
											setNotebookListDisplay([addedNotebook, ...notebookList]);
											setFilteredNotebook({ id: null, title: null });
										}
									} else if (modalNew.id) {
										//if editing, then ID will exist
										const updateNotebookID = await updateNotebook(teacherDocID, modalNew.id, newNotebook);
										if (updateNotebookID) {
											const updatedNotebook = notebookList.map((el) =>
												el.id === modalNew.id
													? {
															...el,
															title: newNotebook.title,
															group: newNotebook.group,
															groupTitle:
																newNotebook.group && !newGroupTitle
																	? getGroupTitle(newNotebook.group)
																	: newGroupTitle
																	? newGroupTitle
																	: '',
													  }
													: el
											);
											setNotebookList(updatedNotebook);
											setNotebookListDisplay(updatedNotebook);
											setFilteredNotebook({ id: null, title: null });
										}
									}
									setSpinner(false);
								} catch (error) {
									console.log('ERROR ADDING NOTEBOOK', error);
								}
							};
							procAddNewNB();
						}}
					/>
				</Modal>
				<Modal show={modalEdit} handleClose={closeModalEdit}>
					<AddEditProject
						handleClose={closeModalEdit}
						project={project}
						projectDD1={projectDropdown}
						projectDD2={projectDropdown2}
						notebookCt={notebookList.length}
						handleDelete={async () => {
							try {
								await deleteProject(teacherDocID, projectID);
								history.push(ROUTES.teachers.projects);
							} catch (err) {
								console.log(err);
							}
							closeModalEdit();
						}}
						handleSave={(event) => {
							closeModalEdit();
							event.preventDefault();
							const { title, projType, projType2 } = event.target.elements;
							const titleVal = title.value;
							const projTypeVal = projType.value;
							let projType2Val = null;
							if (projType2) {
								projType2Val = projType2.value;
							}
							appBase
								.firestore()
								.collection('users/' + teacherDocID + '/projects/')
								.doc(project.id)
								.update({
									title: titleVal,
									type1: projTypeVal,
									type2: projType2Val,
									// createdDT: new Date(),
									// created: appBase.firestore.Timestamp.now(),
								})
								.then(() => {
									// console.log(response);
									// setSpinner(true);
									function typeTitleByID(array, id) {
										// console.log(array);
										return array.find((element) => {
											return element.id === id;
										});
									}
									const typeDisplay1 = typeTitleByID(projectDropdown, projTypeVal).title;
									const typeDisplay2 = projType2Val ? ' - ' + typeTitleByID(projectDropdown2, projType2Val).title : '';
									const typeDisplay = typeDisplay1 + typeDisplay2;
									// const dateAdd = new Date().getTime();
									const addedProject = {
										id: project.id,
										title: titleVal,
										type: typeDisplay,
										// type1: projTypeVal,
										// type2: projType2Val,
										// createdDT: Math.round(dateAdd / 1000),
									};
									// console.log(addedProject);
									setProjectList([addedProject, ...projectList]);
								})
								.catch((error) => {
									console.log(error);
								});
						}}
					/>
				</Modal>
				<FullscreenModal header={'Notebook Options'} show={showNotebookModal} handleClose={closeShowNotebookModal}>
					{/* <div> */}
					<ShowNotebook
						label="Type to Search for Team Member"
						studentList={studentList}
						notebookID={selectedNotebook}
						studentNotebook={studentNotebook}
						projectID={projectID}
						projectName={'This Project'}
						teacherDocID={teacherDocID}
						handleClose={closeShowNotebookModal}
						editNotebook={(notebookInfo) => {
							// console.log(notebookInfo);
							openModalNew('Edit', notebookInfo);
						}}
					/>
				</FullscreenModal>
				<FullscreenModal header={'Competition'} show={competitionModal.show} handleClose={closeCompetitionModal}>
					<ShowCompetition
						handleClose={closeCompetitionModal}
						handleSave={(arr, nbID) => {
							// console.log(arr, nbID);
							const newArr = { ...arr, date: new Date() };
							saveCompetition(teacherDocID, projectID, nbID, newArr);
							setSubmittedNotebookID(nbID);
							closeCompetitionModal();
						}}
						teacherDocID={teacherDocID}
						projectID={projectID}
						notebookID={competitionModal.notebookID}
						project={project}
					/>
				</FullscreenModal>
				<div>
					<ShowProject
						project={project}
						handleClose={closeShowNotebookModal}
						notebookGroups={notebookGroups}
						openModalEdit={openModalEdit}
						filteredNotebook={filteredNotebook}
						handleFilterSelect={(id) => {
							// setFilteredGroupID(id === 0 ? null : id);
							let filteredNotebooks = [];
							if (id !== 0 && id !== 1) {
								filteredNotebooks = notebookList.filter((nb) => nb.group === id);
							} else if (id === 1) {
								filteredNotebooks = notebookList.filter((nb) => !nb.group);
							}
							// console.log(filteredNotebooks);
							if (id === 0) {
								setNotebookListDisplay(notebookList);
								setFilteredNotebook({ id: null, title: null });
							} else if (id === 1) {
								setNotebookListDisplay(filteredNotebooks);
								setFilteredNotebook({
									id: id,
									title: 'Showing Ungrouped',
								});
							} else {
								setNotebookListDisplay(filteredNotebooks);
								setFilteredNotebook({
									id: id,
									title: 'Showing ' + getGroupTitle(id),
								});
							}
						}}
						addButtonClicked={(event) => {
							event.preventDefault();
							const getPurchases = async () => {
								try {
									const processedNotebook = await getNotebookPurchases(teacherDocID);
									setProjectNotebookArr(processedNotebook);
								} catch (error) {
									console.log(error);
								}
							};
							getPurchases();
							openModalNew('New', []);
						}}
					/>
					<div
						style={{
							paddingBottom: 7,
							paddingLeft: 10,
							fontSize: 16,
							fontWeight: 'normal',
						}}
					>
						<span style={{ fontWeight: 500 }}>{filteredNotebook.title}</span>
						{' (' + notebookListDisplay.length + ' Notebooks)'}
					</div>
					<NotebooksTable
						dataArr={notebookListDisplay}
						userType={userType}
						scoring={scoring}
						headerData={headerData()}
						submittedNotebookID={submittedNotebookID}
						hasGroups={notebookGroups.length > 0}
						competition={(nid, type) => {
							if (type === 'add') {
								setCompetitionModal({ show: true, notebookID: nid });
							} else if (type === 'remove') {
								deleteSubmittedNotebookID(projectID);
								setSubmittedNotebookID(null);
							}
						}}
						notebookClick={(id) => {
							setSpinner(true);
							const processStudents = async () => {
								try {
									const processedStudents = await getStudentArray(teacherDocID);
									setStudentList(processedStudents);
									const processedStudentNotebook = await getStudentNotebooks(teacherDocID, projectID);
									setStudentNotebook(processedStudentNotebook);
									setSpinner(false);
								} catch (error) {
									console.log('async error', error);
									setSpinner(false);
								}
							};
							processStudents();

							setSelectedNotebook(id);
							setShowNotebookModal(true);
						}}
					/>
					{/* <pre>notebookGroups: {JSON.stringify(notebookGroups, 0, 3)}</pre> */}
					{/* <pre>notebookListDisplay: {JSON.stringify(notebookListDisplay, 0, 3)}</pre> */}
					{/* <div>selectedNotebook: {selectedNotebook}</div> */}
				</div>
			</>
		);
	}
	if (userType === 'mentor') {
		return <HomeStudent>{returnVal}</HomeStudent>;
	} else {
		return <Home>{returnVal}</Home>;
	}
};

export default Project;
