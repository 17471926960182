import React, { useState, useEffect } from "react";
import { getProject } from "../../global/globalFunctions";
import classes from "./Notebook.module.css";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import EDPGraphic from "../../img/EDP_Loop_Graphic.jpg";
import appSettings from "../../appSettings";

const Notebook = (props) => {
  const [project, setProject] = useState([]);

  useEffect(() => {
    const procProj = async () => {
      try {
        const procProject = await getProject(
          props.teacherDocID,
          props.projectID
        );
        setProject(procProject);
      } catch (error) {
        console.log(error);
      }
    };
    procProj();
  }, [props.projectID, props.teacherDocID]);
  return (
    <div className="Notebook">
      <h2>{project.title}</h2>
      {/* <CKEditor data={data} onChange={onChange} placeholder="Override Placeholder" /> */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/* Eventually add project desc as import from project type or sub-type. For now we'll hard-code */}
          {/* <div>{ReactHtmlParser(project.desc)}</div> */}
          <div>
            <p>
              <strong>Main Start Page:</strong> Select which step you are
              working in—your program manager/teacher may need to approve yours
              or your teams progress before moving to your next step-- In
              Example- IE: moving from Ideation to Design-Build
              ---Teacher/Project Manager approves drawings, designs, all
              research and brainstorming and documentation of the solutions
              selected and why BEFORE parts are provided to begin building or
              prototyping. All steps may be navigated to from each page at will
              by team members—as multiple steps may require documentation on a
              single day. Team members must select the appropriate design step
              to make an entry--choose carefully as all entries are permanent
              and time and date stamped.
            </p>
            <p>
              NOTES: This is a STEM electronic/digital engineering notebook–
              complete your text, edits and spell checks before clicking "ADD
              NEW ENTRY, as this becomes undeletable or changeable, deletions
              are seen as &nbsp;a<s> single strike through</s> per standards for
              engineering notebooks. You may add a comment and explain why you
              made a deletion. Your work, and deletion notations, for each page
              is date and time stamped as you go..
            </p>
            <ul>
              <li>
                Team members may work remotely online together on each step as
                you progress, your program manager/teacher will provide access
                as you move from IDEATION steps to DESIGN-BUILD steps to
                IMPLEMENT
              </li>
              <li>Add as many pages as needed for your project to each step</li>
              <li>
                Add pictures as JPEGS, CAD files and research, PDF Files, and
                videos using you tube links where appropriate to fully describe
                details of your project. To add videos, create a youtube link
                and add the link. (
                <a href="https://www.wikihow.com/Create-Your-Own-YouTube-Video#:~:text=1%20Think%20about%20what%20you%20want%20your%20video,be%20using%20to%20record%20your%20movie.%20See%20More\">
                  How to make a you tube video
                </a>
                )
              </li>
            </ul>
            <p>
              Select the appropriate engineering/technological design step you
              are in as you work through and document your work and progress.
              Each step is sortable by date or by step to easily track your
              progress.&nbsp;
            </p>
            <p>
              <strong>
                Note: You can not add any info to any past date, but can
                reference a step and date to the current date for others to
                understand your actions and/or iteration.
              </strong>
            </p>
          </div>
        </Grid>
        <Grid item sm={4}>
          {props.steps.map((s, i) => {
            return (
              <div key={i}>
                <div
                  className={classes.heading}
                  style={{ color: appSettings.edpColors[i] }}
                >
                  {s.title}
                </div>
                {s.process.map((p, index) => {
                  return (
                    <ListItem
                      key={index}
                      button
                      // component={Link}
                      // to={`/user/notebook/${props.project.id}/${props.notebookID}/${p.id}`}
                      // className={classes.listItem}
                      onClick={() => props.passProcessID(p.id)}
                      style={{ padding: 2 }}
                    >
                      <div
                        className={classes.listItemText}
                        style={{
                          color: appSettings.edpColors[i],
                          textDecoration: "underline",
                        }}
                      >
                        {p.seq}. {p.title}
                      </div>
                    </ListItem>
                  );
                })}
              </div>
            );
          })}
        </Grid>
        <Grid item sm={8}>
          <div style={{ marginTop: 30 }}>
            <img src={EDPGraphic} alt="logo" height="200" />
          </div>
        </Grid>
      </Grid>
      <div
        style={{
          marginTop: 20,
          marginBottom: 100,
          color: "red",
          fontStyle: "italic",
        }}
      >
        Note: The information you document for each step should be
        understandable for others to replicate your project precisely.
      </div>
      {/* <pre>{JSON.stringify(project, 0, 3)}</pre> */}
    </div>
  );
};

export default Notebook;
