import React, { useState, useEffect } from 'react';
import Home from '../../Home';
import appBase from '../../Auth/base';
import { getDocID, getTeacherProfile, getNotebookPurchases } from '../../global/globalFunctions';
import Spinner from '../../UI/Spinner/Spinner';
import Textbox from '../../UI/Forms/Textbox';
import Button from '../../UI/Forms/Button';
import SimpleTable from '../../UI/Tables/SimpleTable';
import Snackbar from '../../UI/Alerts/Snackbar';
import Help from '../../UI/Modals/Help';
import POModal from '../../UI/Modals/RegularModal';
import PurchaseTable from './PurchaseTable';
import PoDialog from './PoDialog';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import PaymentDialog from './PaymentDialog';
// import Invoice from "./Invoice";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <div>{children}</div>}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const pricing = [12.99, 11.99, 10.99, 9.99, 9.99];

const Account = () => {
	const [spinner, setSpinner] = useState(true);
	const [purchaseList, setPurchaseList] = useState([]);

	// const [userDocID, setUserDocID] = useState(false);
	const [notebookPurchases, setNotebookPurchases] = useState([]);

	const [value, setValue] = useState(0);
	const [items, setItems] = useState({
		index: 0,
		quantity: 0,
		amount: 0,
		pricePer: 0,
	});
	const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
	const [poDialog, setPoDialog] = useState(false);
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: '',
		type: 'error',
	});
	const [teacherProfile, setTeacherProfile] = useState([]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const useStyles = makeStyles((theme) => ({
		root: {
			display: 'flex',
		},
		tabs: {
			flexGrow: 1,
			backgroundColor: theme.palette.background.paper,
			margin: 10,
		},
		toolbar: theme.mixins.toolbar,
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
		},
	}));
	const classes = useStyles();

	const getPurchases = (response) => {
		return new Promise((resolve, reject) => {
			appBase
				.firestore()
				.collection('users')
				.doc(response)
				.collection('purchase')
				.orderBy('purchaseDT', 'desc')
				.get()
				.then((snapshot) => {
					const getPurchases = snapshot.docs.map((doc) => ({
						id: doc.id,
						quantity: doc.data().quantity,
						purchaseDT: doc.data().purchaseDT.toDate(),
						amount: doc.data().amount,
					}));
					resolve(getPurchases);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	useEffect(() => {
		const getUIDandPurchases = async () => {
			try {
				const response = await getDocID();
				const procTeacherProfile = await getTeacherProfile(response);
				setTeacherProfile(procTeacherProfile);
				const processedResponse = await getPurchases(response);
				// setUserDocID(response);
				setPurchaseList(processedResponse);
				const processedNotebook = await getNotebookPurchases(response);
				setNotebookPurchases(processedNotebook);
				setSpinner(false);
			} catch (error) {
				setSpinner(false);
				console.log(error);
			}
		};
		getUIDandPurchases();
		// console.log("mounted");
		return () => {
			// console.log("unmounted");
		};
	}, []);

	const handlePurchase = (type) => {
		if (items.quantity > 0) {
			if (type === 'cc') {
				setPaymentDialogOpen(true);
			} else if (type === 'po') {
				// console.log("po");
				setPoDialog(true);
			}
		} else {
			setSnackbar({
				open: true,
				message: 'Please enter Notebook Quantity',
				severity: 'error',
			});
		}
	};

	const makePurchase = () => {
		return (
			<div>
				<div style={{ fontWeight: 'bold' }}>
					<POModal show={poDialog} header="Purchase Order Request" handleClose={() => setPoDialog(false)}>
						<PoDialog teacherProfile={teacherProfile} purchaseData={items} handleClose={() => setPoDialog(false)} />
					</POModal>
					<Help helpId="GVX1wghVqClQVs9t3BEV" preText="Your Purchases" />
				</div>
				<div>
					Total notebooks purchased:
					<strong> {notebookPurchases.purchaseQty}</strong>
				</div>
				<div>
					Total notebooks created:
					<strong> {notebookPurchases.notebookCt}</strong>
				</div>
				{notebookPurchases.trialNotebookCt > 0 && (
					<div>
						Total trial notebooks created:
						<strong> {notebookPurchases.trialNotebookCt}</strong>
					</div>
				)}
				<div>
					Purchased notebooks remaining:
					<strong> {notebookPurchases.purchaseQty - notebookPurchases.notebookCt}</strong>
				</div>
				<div style={{ margin: '20px 0' }}>
					<SimpleTable selIndex={items.index} />
				</div>
				{/* <Divider style={{ margin: "15px 0" }} /> */}
				<div style={{ margin: '10px 0', fontWeight: 'bold' }}>
					Enter the quantity of notebooks you wish to purchase and pay by secure credit card order. For Purchase Orders,
					a minimum purchase of $200 is required.
				</div>
				<div>
					<Textbox
						label="Enter Notebook Quantity"
						custom="number"
						name="notebookQty"
						variant="outlined"
						maxlength={3}
						inputProps={{
							autocomplete: false,
						}}
						// width="10%"
						textChange={(name, val) => {
							const pval = parseInt(val);
							let index = 0;
							if (pval > 199) {
								index = 4;
							} else if (pval > 50) {
								index = 3;
							} else if (pval > 25) {
								index = 2;
							} else if (pval > 10) {
								index = 1;
							}
							setItems({
								index: index,
								quantity: pval,
								pricePer: pricing[index],
								amount: pval > 0 ? (pval * Math.round(pricing[index] * 100)) / 100 : 0,
							});
						}}
					/>
					{items.amount < 2000 && (
						<Button buttonType="add" buttonClicked={() => handlePurchase('cc')}>
							Pay By Credit Card
						</Button>
					)}
					{items.quantity < 200 && items.amount >= 200 && (
						<Button buttonType="add" buttonClicked={() => handlePurchase('po')}>
							Pay By Purchase Order
						</Button>
					)}
					<div style={{ fontSize: 19, color: '#777' }}>
						{items.quantity >= 200 ? (
							<span>Call +1 (321) 257-8263 for special pricing over 200 notebooks.</span>
						) : (
							<span>
								Total Checkout Price: <strong>${items.amount.toFixed(2).toLocaleString()}</strong>
							</span>
						)}
					</div>
				</div>
			</div>
		);
	};

	let returnVal = <Spinner />;
	if (!spinner) {
		returnVal = (
			<>
				<Snackbar
					open={snackbar.open}
					close={() => {
						setSnackbar({
							open: false,
							message: '',
							severity: snackbar.severity,
						});
					}}
					message={snackbar.message}
					severity={snackbar.severity}
				/>
				<PaymentDialog open={paymentDialogOpen} setOpen={setPaymentDialogOpen} items={items} />
				<div className={classes.tabs}>
					<AppBar position="static" color="default">
						<Tabs value={value} onChange={handleChange} aria-label="profile tabs">
							<Tab label="Purchase" {...a11yProps(0)} />
							<Tab label="History" {...a11yProps(1)} />
						</Tabs>
					</AppBar>
					<TabPanel value={value} index={0}>
						<Card style={{ padding: 20 }}>{makePurchase()}</Card>
					</TabPanel>
					<TabPanel value={value} index={1}>
						<Card style={{ padding: 20 }}>
							<div>
								<PurchaseTable dataArr={purchaseList} />
								{/* <div>{userDocID}</div> */}
							</div>
						</Card>
					</TabPanel>
					{/* <pre>{JSON.stringify(items, 0, 3)}</pre> */}
				</div>
			</>
		);
	}
	return <Home>{returnVal}</Home>;
};

export default Account;
