import React, { useState, useEffect } from 'react';
import { subCategory } from '../../global/globalFunctions';
import Spinner from '../../UI/Spinner/Spinner';
import Header from '../../UI/Header/Header';
import HelpModal from '../../UI/Modals/FullscreenModal';
import Video from '../../UI/Video/Video';
// import Table from "../../UI/Tables/Table";
// import Accordian from "../../UI/Accordian/Accordian";
import Alert from '../../UI/Alerts/Alert';
import { getFAQ } from '../../global/globalFunctions';
import ReactHtmlParser from 'react-html-parser';
import classes from './Help.module.css';

const DisplayFAQ = (props) => {
	const [spinner, setSpinner] = useState(true);
	const [helpArray, setHelpArray] = useState([]);
	const [filteredHelpArray, setFilteredHelpArray] = useState([]);
	const [showAlert, setShowAlert] = useState(false);
	const [clearSearch, setClearSearch] = useState(0);
	const [selectedHelp, setSelectedHelp] = useState([]);

	useEffect(() => {
		const initHelpFiles = async () => {
			try {
				const response = await getFAQ(props.type);

				setHelpArray(response);
				setFilteredHelpArray(response);
				setSpinner(false);
			} catch (error) {
				setSpinner(false);
				console.log('initHelpFiles catch error', error);
			}
		};
		initHelpFiles();
	}, [props.type]);

	const searchOnChange = (val) => {
		// console.log(clearSearch);
		const lval = val.toLowerCase();
		let newArr = [];
		if (!lval || lval.length === 0) {
			setFilteredHelpArray(helpArray);
			setShowAlert(false);
		} else {
			newArr = helpArray.filter((h) => h.title.toLowerCase().includes(lval) || h.summary.toLowerCase().includes(lval));
			setFilteredHelpArray(newArr);
			setShowAlert(newArr.length === 0);
		}
	};

	const showHelp = () => {
		return subCategory.map((v, i) => {
			const rowCt = filteredHelpArray.filter((f) => f.subcategory === v.id);
			if (rowCt.length === 0) {
				return false;
			}
			return (
				<div key={i}>
					<div className={classes.header}>{v.title}</div>
					{showHelpArr(v.id)}
				</div>
			);
		});
	};
	const showHelpArr = (id) => {
		return filteredHelpArray.flatMap((h) =>
			h.subcategory === id ? (
				<div key={h.id} className={classes.row} onClick={() => openHelpFile(h.id)}>
					{h.title}
				</div>
			) : (
				[]
			)
		);
	};
	const openHelpFile = (id) => {
		const newArr = filteredHelpArray.filter((h) => h.id === id);
		setSelectedHelp(newArr);
	};
	const closeHelpModal = () => {
		setSelectedHelp([]);
	};
	const returnVal = (
		<>
			<Header
				title={<span style={{ fontWeight: 500 }}>Help / Frequently Asked Questions</span>}
				search={true}
				searchPH="Search Help..."
				handleSearchChange={(searchVal) => {
					searchOnChange(searchVal);
				}}
				clearSearch={clearSearch}
			/>
			<HelpModal
				header={selectedHelp.length > 0 && selectedHelp[0].title}
				show={selectedHelp.length > 0 ? true : false}
				handleClose={closeHelpModal}
			>
				<div>
					{selectedHelp.length > 0 && (
						<div>
							<div className={classes.header}>{selectedHelp[0].title}</div>
							{ReactHtmlParser(selectedHelp[0].html)}
							{selectedHelp[0].videoUrl && (
								<div style={{ minWidth: 300, maxWidth: 500, marginBottom: 50 }}>
									<Video controls={true} width="100%" url={selectedHelp[0].videoUrl} />
								</div>
							)}
						</div>
					)}
				</div>
			</HelpModal>
			<Alert
				showAlert={showAlert}
				close={() => {
					setShowAlert(false);
					setFilteredHelpArray(helpArray);
					setClearSearch(clearSearch + 1);
				}}
				type="Success"
			>
				<span>Search contains no results.</span>
				{/* <span onClick={() => searchOnChange("")}>Reset</span> */}
			</Alert>
			{/* <Accordian data={filteredHelpArray} /> */}
			{showHelp()}
			<div style={{ marginTop: 100 }}>&nbsp;</div>
			{/* <pre>{JSON.stringify(selectedHelp, 0, 3)}</pre> */}
		</>
	);

	return <>{spinner ? <Spinner /> : returnVal}</>;
};

export default DisplayFAQ;
