import React, { useState, useEffect } from "react";
import { getEdenDocs } from "../../global/globalFunctions";
import ReactHtmlParser from "react-html-parser";
import FullscreenModal from "../Modals/FullscreenModal";

const Footer = () => {
  const [docs, setDocs] = useState([]);
  const [modal, setModal] = useState({ open: false, data: [] });
  useEffect(() => {
    const getDocs = async () => {
      try {
        const procDocs = await getEdenDocs();
        setDocs(procDocs);
      } catch (err) {
        console.log("docs error", err);
      }
    };
    getDocs();
  }, []);

  const closeModal = () => {
    setModal({ ...modal, open: false });
  };
  return (
    <>
      <FullscreenModal
        show={modal.open}
        header={modal.data.title}
        handleClose={closeModal}
      >
        <div>{ReactHtmlParser(modal.data.html)}</div>
      </FullscreenModal>
      <div
        style={{
          marginTop: 150,
          textAlign: "center",
          color: "#888888",
          fontSize: 12,
        }}
      >
        ©2022 HEAT Corp, Proprietary and Confidential, E.D.E.N. Patent Pending
      </div>
      <div
        style={{
          marginTop: 4,
          textAlign: "center",
          color: "#888888",
          fontSize: 12,
        }}
      >
        PO Box 60902, Palm Bay, FL 32906 |{" "}
        <a href="mailto:support@heatcorpstem.com" style={{ color: "#888888" }}>
          support@heatcorpstem.com
        </a>
      </div>
      <div style={{ marginTop: 4, textAlign: "center" }}>
        {docs?.map((d, i) => {
          return (
            <span
              key={i}
              style={{
                fontWeight: "normal",
                color: "#888888",
                cursor: "pointer",
                textDecoration: "underline",
                padding: "0 10px",
                fontSize: 12,
              }}
              onClick={() => setModal({ open: true, data: d })}
            >
              {d.title}
            </span>
          );
        })}
      </div>
      {/* <pre>{JSON.stringify(docs, 0, 3)}</pre>
      <pre>{JSON.stringify(modal, 0, 3)}</pre> */}
    </>
  );
};

export default Footer;
