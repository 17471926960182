import React, { useState, useEffect } from "react";
import { getStudentNotebook } from "../../global/globalFunctions";
import Textbox from "../../UI/Forms/Textbox";
import Checkbox from "../../UI/Forms/Checkbox";
// import appBase from "../../Auth/base";
// import nbStyles from "./Project.module.css";
import Button from "../../UI/Forms/Button";
import Help from "../../UI/Modals/Help";
import FormGroup from "@material-ui/core/FormGroup";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

function todaysDate() {
  const curr = new Date();
  curr.setDate(curr.getDate());
  const dateNew = curr.toISOString().substr(0, 10);
  return dateNew;
}

const ShowCompetition = (props) => {
  const [currDate] = useState(todaysDate);
  const [notebookInfo, setNotebookInfo] = useState([]);

  const saveHandler = (event) => {
    event.preventDefault();
    const { txtName, txtDate, chkOK } = event.target.elements;
    if (txtName && txtDate && chkOK) {
      props.handleSave(
        { signature: txtName.value, dateSigned: txtDate.value },
        props.notebookID
      );
    }
  };

  const showNotebook = () => {
    return (
      <Card style={{ background: "#ebece5" }}>
        <CardContent>
          <div>
            Project: <strong>{props.project.title}</strong>
          </div>
          <div>
            Team: <strong>{notebookInfo.title}</strong>
          </div>
          <div>
            {notebookInfo.scores?.map((s, i) => (
              <div key={i}>
                {s.title}: <strong>{s.score + " " + s.unit}</strong>
              </div>
            ))}
          </div>
          <p>
            To provide ethical and fair project notebook submissions and project
            challenges scoring, we provide this affidavit to help ensure honesty
            and fairness from all. Your teams'/school's top scores (detailed in
            the HEAT Corp STEM Challenge(s) with EDEN) will be uploaded and made
            available for students to see how they compare with other teams
            across your district, state, nationally, and internationally.
          </p>

          <p>
            By signing this, you agree that you are the Project Manager/Teacher
            that has overseen and managed the student team for the submitted
            project engineering notebook and score(s), and attest the score
            submitted/uploaded is accurate and truthful. You attest that this
            project submission and scoring is the result of entirely the
            students' work, with no adult/mentor influence aside from assistance
            and guidance (i.e. helping with a clamp for gluing a bridge part, or
            a nut or bolt that the student could not loosen, or cutting a piece
            of material that proved difficult or unsafe, is considered
            assistance and guidance. Designing, programming, cutting and
            building the project is not).
          </p>
          <p>
            If coding, you attest that the coding is 100% student done aside
            from guidance and assistance expected from a teacher/mentor (i.e.
            explaining and showing an error or examples of coding a motor speed
            is guidance and assistance. Coding it for them or providing a
            pre-written code to upload is not).
          </p>
          <form autoComplete="off" onSubmit={saveHandler}>
            <FormGroup row>
              <Textbox
                label="Your First and Last Name"
                helperText="Electronic Signature: Please enter your full name."
                InputLabelProps={{ shrink: true }}
                required={true}
                name="txtName"
                id="name"
              />
              <Textbox
                type="date"
                label="Today's Date"
                value={currDate}
                helperText="Enter or accept today's date as shown."
                InputLabelProps={{ shrink: true }}
                required={true}
                name="txtDate"
                id="date"
              />
            </FormGroup>
            <div>
              <Checkbox
                required={true}
                label="By entering my name and today's date above, I acknowledge this represents my electronic signature and my agreement to this affidavit."
                name="chkOK"
              />
            </div>
            <Button type="submit" id="btn-agree" name="btn-agree">
              I agree
            </Button>

            <Button
              id="btn-cancel"
              name="btn-cancel"
              style={{ backgroundColor: "#c9cac3", color: "#333333" }}
              buttonClicked={props.handleClose}
            >
              Cancel
            </Button>
          </form>
        </CardContent>
      </Card>
    );
  };

  useEffect(() => {
    const getNB = async () => {
      try {
        const notebookObject = await getStudentNotebook(
          props.teacherDocID,
          props.notebookID
        );
        setNotebookInfo(notebookObject);
      } catch (error) {
        console.log("COMP catch error", error);
      }
    };
    getNB();
  }, [props.teacherDocID, props.notebookID]);

  return (
    <>
      <h4>
        <Help
          helpId="4nTgKPLtlrHV3LzRXcGb"
          preText="Project and Scoring Affidavit"
        />
      </h4>
      {showNotebook()}
      {/* <pre>permissions: {JSON.stringify(notebookPermissions, null, 3)}</pre> */}
      {/* <pre>props: {JSON.stringify(props, null, 3)}</pre> */}
      {/* <pre>filteredStudents: {JSON.stringify(filteredStudents, null, 3)}</pre> */}
      {/* <pre>notebookInfo: {JSON.stringify(notebookInfo, null, 3)}</pre> */}
      {/* <pre>studentNotebook: {JSON.stringify(studentNotebook, null, 3)}</pre> */}
    </>
  );
};
export default ShowCompetition;
