import React from 'react';
import appBase, { auth, db } from '../Auth/base';
import { ROUTES } from './constants/routes';

export function toTitleCase(str) {
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
}
export const helpTypes = [
	{ id: '1', title: 'Teacher' },
	{ id: '2', title: 'Student' },
	{ id: '3', title: 'General' },
];
export const subCategory = [
	{
		id: '1',
		title: "Introduction to HEAT Corp's Engineering Design Electronic Notebook-EDEN",
	},
	{ id: '2', title: 'Terms and Definitions' },
	{ id: '3', title: 'Technical How To Questions' },
	{ id: '4', title: 'About Engineering Notebooks' },
	{
		id: '5',
		title: 'Unique Features of EDEN for STEM Projects and STEM Competitions',
	},
	{
		id: '6',
		title:
			'Using EDEN to manage the engineering design process as an instructional methodology  to manage STEM project teams',
	},
	{
		id: '7',
		title: 'Videos',
	},
];

export const getNotebookURL = (teacherDocID, notebookID) => {
	let pathname = ROUTES.teachers.openurl.replace(':teacherid', teacherDocID);
	pathname = pathname.replace(':notebookid', notebookID);
	// navigator.clipboard.writeText(`${window.location.origin}${pathname}`);
	const fullURL = `${window.location.origin}${pathname}`;
	return fullURL;
};

/**
 * Get currently logged in teacher docId through fb auth
 * or return false
 * @returns {Object} currentUser docId | false
 */
export function getDocID() {
	const uid = auth.currentUser && auth.currentUser.uid;
	if (!uid) return false;
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('users')
			.where('uid', '==', uid)
			.get()
			.then((snapshot) => {
				const newUser = snapshot.docs.map((doc) => ({
					id: doc.id,
				}));
				const uid = newUser.length && newUser[0].id;
				resolve(uid);
			})
			.catch((error) => {
				reject(error);
			});
	});
}
export function getTeacherProfile(teacherDocID) {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('users')
			.doc(teacherDocID)
			.get()
			.then((snapshot) => {
				resolve({ id: snapshot.id, ...snapshot.data() });
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getNotebookEntry(teacherDocID, notebookID, entryID) {
	return new Promise((resolve, reject) => {
		db.collection('users')
			.doc(teacherDocID)
			.collection('notebooks')
			.doc(notebookID)
			.collection('entries')
			.doc(entryID)
			.get()
			.then((snapshot) => {
				resolve({ id: snapshot.id, ...snapshot.data() });
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getEdenDoc(docID) {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('eden-docs')
			.doc(docID)
			.get()
			.then((snapshot) => {
				resolve({ id: snapshot.id, ...snapshot.data() });
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getEdenDocs() {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('eden-docs')
			.get()
			.then((snapshot) => {
				const mapdocs = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				resolve(mapdocs);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export const addNewTeacher = (res, regData) => {
	return new Promise((resolve, reject) => {
		const ctQuery = db.collection('users');
		// ctQuery.get().then((snapshot) => {
		// const tchrCount = snapshot.size + 100;
		// const tchrCount = 100;
		ctQuery
			.doc(res)
			.set(regData)
			.then(() => {
				// BELOW ADDS ONE FREE NOTEBOOK. DISABLED FOR NOW
				// ctQuery
				//   .doc(res)
				//   .collection("purchase")
				//   .add({
				//     amount: 0,
				//     purchaseDT: new Date(),
				//     quantity: 1,
				//     transactionId: "new-account",
				//   })
				//   .then(() => {
				//     resolve(true);
				//   });
				resolve(true);
			})
			.catch((err) => reject(err));
	});
	// });
};

export const projectNumberIsUnique = (teacherDocID, num) => {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('users')
			.where('number', '==', num)
			.get()
			.then((snapshot) => {
				const getAccounts = snapshot.docs.map((doc) => ({
					id: doc.id,
				}));
				let isUnique = true;
				getAccounts.forEach((n) => {
					if (n.id !== teacherDocID) {
						isUnique = false;
					}
				});
				resolve(isUnique);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

/**
 * Provide the teacher's docId to get the student's docId
 * @param {string} userDocID Teacher docId
 * @returns {string} studentDocId
 */

export const getStudents = (teacherDocId, isActive) => {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('users')
			.doc(teacherDocId)
			.collection('students')
			.where('active', '==', isActive)
			.get()
			.then((snapshot) => {
				const getStudents = snapshot.docs.map((doc) => ({
					id: doc.id,
					first: doc.data().first,
					last: doc.data().last,
					email: doc.data().email,
					school: doc.data().school,
				}));
				resolve(getStudents);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getStudentArray = (userID) => {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('users')
			.doc(userID)
			.collection('students')
			.get()
			.then((snapshot) => {
				const students = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				resolve(students);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getStudent = (teacherDocID) => {
	const uid = auth.currentUser && auth.currentUser.uid;
	if (!uid) {
		console.info('%c There is no logged in user: ', 'font-size: 16px; font-weight: bold; color: red;', uid);
		return;
	}
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('users')
			.doc(teacherDocID)
			.collection('students')
			.doc(uid)
			.get()
			.then((snapshot) => {
				// const studentInfo = snapshot.docs.map((doc) => ({
				//   id: doc.id,
				//   ...doc.data(),
				// }));

				// resolve(studentInfo);
				resolve({ id: snapshot.id, ...snapshot.data() });
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getStudentNotebooks = (userID, projectID) => {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('users')
			.doc(userID)
			.collection('student-notebook')
			.where('projectID', '==', projectID)
			.get()
			.then((snapshot) => {
				const studentNotebooks = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				resolve(studentNotebooks);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <div>{children}</div>}
		</div>
	);
}
export function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export function getStudentNotebook(teacherDocID, notebookID) {
	return new Promise((resolve, reject) => {
		const db = appBase.firestore().collection('users').doc(teacherDocID).collection('notebooks').doc(notebookID);

		db.get()
			.then((snapshot) => {
				let documentData = snapshot.data();
				db.collection('scores')
					.get()
					.then((snapshot) => {
						const getScores = snapshot.docs.map((doc) => doc.data());
						documentData['scores'] = getScores;
						resolve(documentData);
					})
					.catch((error) => {
						reject(error);
					});
			})
			.catch((error) => {
				reject(error);
			});

		// appBase
		//   .firestore()
		//   .collection("users")
		//   .doc(teacherDocID)
		//   .collection("notebooks")
		//   .doc(notebookID)
		//   .get()
		//   .then((snapshot) => {
		//     const studentNotebook = {
		//       id: snapshot.id,
		//       createdDT: snapshot.data().createdDT.seconds,
		//       projectID: snapshot.data().projectID,
		//       title: snapshot.data().title,
		//       isViewable: snapshot.data().isViewable,
		//     };
		//     resolve(studentNotebook);
		//   })
		//   .catch((error) => {
		//     reject(error);
		//   });
	});
}

export function getNotebookToc(teacherDocID, notebookID) {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('users')
			.doc(teacherDocID)
			.collection('notebooks')
			.doc(notebookID)
			.collection('toc')
			.get()
			.then((snapshot) => {
				const nbToc = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				resolve(nbToc);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export const newNotebookToc = (teacherDocID, notebookID, tocTxt) => {
	return new Promise((resolve, reject) => {
		const quer = db.collection('users').doc(teacherDocID).collection('notebooks').doc(notebookID);
		quer
			.collection('toc')
			.add({ title: tocTxt })
			.then((res) => {
				resolve(res.id);
			})
			.catch((err) => reject(err));
	});
};

export const updateEntryToc = (teacherDocID, notebookID, entryID, tocID, tocTtl) => {
	return new Promise((resolve, reject) => {
		const quer = db.collection('users').doc(teacherDocID).collection('notebooks').doc(notebookID);
		quer
			.collection('entries')
			.doc(entryID)
			.update({ tocId: tocID, tocTtl: tocTtl })
			.then(() => {
				resolve(true);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/**
 * Get the logged in user's teacher
 * @param {string} studentDocID
 * @returns teacherUid
 */
export const getTeacherDoc = async (studentDocID) => {
	const parentRef = await db.collection('users').get();

	return new Promise((resolve, reject) => {
		return parentRef.forEach(async (snapshot) => {
			const docRef = snapshot.ref.collection('students').where('uid', '==', studentDocID);

			if (docRef) {
				const doc = await docRef.get();

				if (doc.docs.length) {
					const teacherDocument = doc.docs[0].data();
					resolve(teacherDocument);
				}
			} else {
				reject('No doc found');
			}
		});
	});
};

/**
 * Get currently logged in user's uid and user type
 */
export const getLoggedInInfo = async (teacherId = '') => {
	const uid = auth.currentUser && auth.currentUser.uid;
	if (!uid) {
		console.info('%c There is no logged in user: ', 'font-size: 16px; font-weight: bold; color: red;', uid);
		return;
	}

	try {
		if (!teacherId) {
			const usersRef = db.collection('users').where('uid', '==', uid);
			const userDoc = await usersRef.get();

			if (userDoc.docs.length) {
				// If we find a doc in users collections that means a teacher is logged in
				const newUser = userDoc.docs.map((doc) => ({
					id: doc.id,
				}));
				const teacherUid = newUser[0].id;
				// A teacher is logged in
				return { id: teacherUid, userType: 'teacher' };
			} else {
				// A student is logged in
				const teacherDoc = await getTeacherDoc(uid);
				if (teacherDoc) {
					return { id: uid, userType: 'student' };
				}
			}
		} else {
			const studentsRef = db.collection('users').doc(teacherId).collection('students').where('uid', '==', uid);

			const studentDoc = await studentsRef.get();

			const newUser = studentDoc.docs.map((doc) => ({
				id: doc.id,
			}));

			const studentUid = newUser.length && newUser[0].id;
			// A student is logged in
			return { id: studentUid, userType: 'student' };
		}
	} catch (err) {
		console.error('getLoggedInInfo error: ', err);
	}
};

export function verifyTeacher(teacherDocID) {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('users')
			.doc(teacherDocID)
			.get()
			.then((snapshot) => {
				resolve(snapshot.exists);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export const getProjects = (teacherDocID) => {
	return new Promise((resolve, reject) => {
		const query = appBase.firestore().collection('users').doc(teacherDocID);

		query
			.collection('projects')
			.get()
			.then((snapshot) => {
				db.collection('users')
					.doc(teacherDocID)
					.get()
					.then((res) => {
						const getProjects = snapshot.docs.map((doc) => {
							// let size = 0;
							// query
							//   .collection("notebooks")
							//   .where("projectID", "==", doc.id)
							//   .then((snap) => {
							//     size = snap.size;
							//   });
							return {
								id: doc.id,
								title: doc.data().title,
								type1: doc.data().type1,
								type2: doc.data().type2,
								createdDT: doc.data().createdDT.seconds,
								projectNumber: res.data().number + doc.data().letter,
								notebookCt: 1,
							};
						});
						resolve(getProjects);
					});
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getProject = (teacherDocID, projectID) => {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('users')
			.doc(teacherDocID)
			.collection('projects')
			.doc(projectID)
			.get()
			.then((snapshot) => {
				const getProject = {
					id: snapshot.id,
					title: snapshot.data().title,
					desc: snapshot.data().desc,
					type1: snapshot.data().type1,
					type2: snapshot.data().type2,
					createdDT: snapshot.data().createdDT.seconds,
				};
				resolve(getProject);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const addNewProject = (teacherDocID, titleVal, projTypeVal, projType2Val, projLetter) => {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('users')
			.doc(teacherDocID)
			.collection('projects')
			.add({
				title: titleVal,
				type1: projTypeVal,
				type2: projType2Val,
				createdDT: new Date(),
				letter: projLetter,
			})
			.then((response) => {
				resolve(response.id);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getPurchase = (teacherDocID, purchaseID) => {
	return new Promise((resolve, reject) => {
		db.collection('users')
			.doc(teacherDocID)
			.collection('purchase')
			.doc(purchaseID)
			.get()
			.then((snapshot) => {
				const dta = snapshot.data();
				resolve({ ...dta, teacherDocID: teacherDocID });
			})
			.catch((err) => {
				reject('[[GET PURCHASE]]', err);
			});
	});
};
export const addPurchaseOrder = (teacherDocID, poForm, purchaseData) => {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('users')
			.doc(teacherDocID)
			.collection('purchase')
			.add({
				poApproved: false,
				poNumber: poForm.poNumber,
				poRequester: poForm.poRequester,
				poRequesterEmail: poForm.poRequesterEmail,
				poRequesterPhone: poForm.poRequesterPhone,
				poOrg: poForm.poOrg,
				poStreet1: poForm.poStreet1,
				poStreet2: poForm.poStreet2,
				poCity: poForm.poCity,
				poState: poForm.poState,
				poZip: poForm.poZip,
				amount: purchaseData.amount,
				pricePer: purchaseData.pricePer,
				poQuantity: purchaseData.quantity,
				quantity: 0,
				purchaseDT: new Date(),
			})
			.then((response) => {
				resolve(response.id);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getNotebook = (userDocID, notebookID, processID) => {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('users/' + userDocID + '/notebooks')
			.doc(notebookID)
			.collection('entries')
			.where('processID', '==', processID)
			// .orderBy("createdDT", "asc")
			.get()
			.then((snapshot) => {
				const getProjects = snapshot.docs.map((doc) => ({
					id: doc.id,
					html: doc.data().html,
					createdDT: doc.data().createdDT.seconds,
					stepID: doc.data().stepID,
					processID: doc.data().processID,
					studentID: doc.data().studentID,
					studentName: doc.data().studentName,
					strike: doc.data().strike,
					editName: doc.data().editName,
					editDT: doc.data().editDT.seconds,
				}));
				resolve(getProjects);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getEntryCount = (userDocID, notebookID) => {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('users/' + userDocID + '/notebooks')
			.doc(notebookID)
			.collection('entries')
			.get()
			.then((snapshot) => {
				resolve(snapshot.size);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

function typeTitleByID(array, id) {
	// console.log(array);
	return array.find((element) => {
		return element.id === id;
	});
}

export const addNewNotebookGroup = (teacherDocID, projectID, value) => {
	return new Promise((resolve, reject) => {
		db.collection('users')
			.doc(teacherDocID)
			.collection('projects')
			.doc(projectID)
			.collection('groups')
			.add({ title: value })
			.then((response) => {
				resolve(response.id);
			})
			.catch((error) => {
				reject('ERROR ADDING GROUP', error);
			});
	});
};

export const addNewNotebook = (teacherDocID, newNotebook) => {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('users')
			.doc(teacherDocID)
			.collection('notebooks')
			.add(newNotebook)
			.then((response) => {
				//each promise is the 3 main steps in the EDP. Replace this with a query to the "step" collection.
				const promise1 = db
					.collection('users')
					.doc(teacherDocID)
					.collection('notebooks')
					.doc(response.id)
					.collection('permissions')
					.doc('1')
					.set({
						isActive: true,
						title: 'Ideation',
					});

				const promise2 = db
					.collection('users')
					.doc(teacherDocID)
					.collection('notebooks')
					.doc(response.id)
					.collection('permissions')
					.doc('2')
					.set({
						isActive: false,
						title: 'Design-Build',
					});

				const promise3 = db
					.collection('users')
					.doc(teacherDocID)
					.collection('notebooks')
					.doc(response.id)
					.collection('permissions')
					.doc('3')
					.set({
						isActive: false,
						title: 'Implement',
					});

				Promise.all([promise1, promise2, promise3])
					.then(() => {
						resolve(response.id);
					})
					.catch((error) => {
						reject('Error in adding permissions', error);
					});
			})
			.catch((error) => {
				reject('ERROR IN ADDING NOTEBOOK', error);
			});
	});
};

export const updateNotebook = (teacherDocID, notebookID, updatedNotebook) => {
	return new Promise((resolve, reject) => {
		if (!updatedNotebook.group) {
			updatedNotebook = { ...updatedNotebook, group: null };
		}
		db.collection('users')
			.doc(teacherDocID)
			.collection('notebooks')
			.doc(notebookID)
			.update(updatedNotebook)
			.then(() => {
				resolve(notebookID);
			})
			.catch((error) => reject(error));
	});
};

export const getGroups = (teacherDocID, projectID) => {
	return new Promise((resolve, reject) => {
		db.collection('users')
			.doc(teacherDocID)
			.collection('projects')
			.doc(projectID)
			.collection('groups')
			.orderBy('title', 'asc')
			.get()
			.then((snapshot) => {
				const groups = snapshot.docs.map((doc) => ({
					id: doc.id,
					title: doc.data().title,
				}));
				resolve(groups);
			})
			.catch((error) => {
				reject('ERROR GETTING GROUPS', error);
			});
	});
};

export const getProcessTitles = (array, id) => {
	let newArray = [];
	array.forEach((step) => {
		step.process.forEach((process) => {
			if (process.id === id) {
				newArray.push({ stepTitle: step.title, processTitle: process.title });
			}
		});
	});
	return newArray;
};

export const getProjectsWithTypeTitle = (projects, type1, type2) => {
	return new Promise((resolve) => {
		let members = projects.map((project) => {
			const typeDisplay1 = typeTitleByID(type1, project.type1).title;
			const typeDisplay2 = project.type2 ? ' - ' + typeTitleByID(type2, project.type2).title : '';
			const typeDisplay = typeDisplay1 + typeDisplay2;
			return { ...project, type: typeDisplay };
		});
		// resolve(members);

		resolve(members);
	});
};

// export function getStudentInfo(userDocID) {
//   const uid = appBase.auth().currentUser.uid;
//   return new Promise((resolve, reject) => {
//     appBase
//       .firestore()
//       .collection("users")
//       .doc(userDocID)
//       .collection("students")
//       .where("uid", "==", uid)
//       .get()
//       .then((snapshot) => {
//         const studentInfo = snapshot.docs.map((doc) => ({
//           id: doc.id,
//           active: doc.data().active,
//           first: doc.data().first,
//           last: doc.data().last,
//           email: doc.data().email,
//           school: doc.data().school,
//         }));
//         resolve(studentInfo);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

export function getStudentInfo(teacherDocID, studentEmail) {
	// return teacherDocID + " " + studentEmail;
	// const uid = appBase.auth().currentUser.uid;
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('users')
			.doc(teacherDocID)
			.collection('students')
			.where('email', '==', studentEmail)
			.get()
			.then((snapshot) => {
				// resolve(snapshot.docs.length > 0);
				const studentInfo = snapshot.docs.map((doc) => ({
					id: doc.id,
					active: doc.data().active,
					// first: doc.data().first,
					// last: doc.data().last,
					// email: doc.data().email,
					// school: doc.data().school,
				}));
				resolve(studentInfo);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export const getLastPath = (str) => {
	const returnval = str.endsWith('/') ? str.slice(0, -1) : str;
	return returnval.substring(returnval.lastIndexOf('/') + 1);
};

// export const setCookie = (key, value) => {
//   window.sessionStorage.setItem(key, value);
// };

// export const getCookie = (key) => {
//   window.sessionStorage.getItem(key);
// };

// export const removeCookie = (key) => {
//   window.sessionStorage.removeItem(key);
// };

// export const removeAllCookies = () => {
//   window.sessionStorage.clear();
// };

export function getProjectsLevel1() {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('project-type')
			.orderBy('seq', 'asc')
			.get()
			.then((snapshot) => {
				const getProjects = snapshot.docs.map((doc) => {
					return {
						id: doc.id,
						title: doc.data().title,
						seq: doc.data().seq,
					};
				});
				resolve(getProjects);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getProjectTitles(type1, type2) {
	return new Promise((resolve, reject) => {
		db.collection('project-type')
			.doc(type1)
			.get()
			.then((res) => {
				const projTitle = res.data().title;
				db.collection('project-subtype')
					.doc(type2)
					.get()
					.then((res) => {
						const projSubtitle = res.data().title;
						let returnVal = projTitle;
						if (projTitle !== projSubtitle) {
							returnVal += ' - ' + projSubtitle;
						}
						resolve(returnVal);
					})
					.catch((error) => reject('ERROR GETTING SUB-PROJECT', error));
			})
			.catch((error) => reject('ERROR GETTING PROJECT', error));
	});
}

export function getProjectsLevel2() {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('project-subtype')
			.orderBy('seq', 'asc')
			.get()
			.then((snapshot) => {
				const getProjects = snapshot.docs.map((doc) => {
					return {
						id: doc.id,
						title: doc.data().title,
						seq: doc.data().seq,
						typeid: doc.data().typeid,
					};
				});
				resolve(getProjects);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

/* export const getStepsAndProcesses = () => {
  return new Promise((resolve, reject) => {
    const db = appBase.firestore();
    db.collection("step")
      .orderBy("seq", "asc")
      .get()
      .then((snapshot) => {
        let ResourcePromises = snapshot.docs.map((doc) => {
          var promises = [];
          let documentData = doc.data();
          documentData["id"] = doc.id;
          promises.push(
            db
              .collection("step")
              .doc(documentData.id)
              .collection("process")
              .orderBy("seq", "asc")
              .get()
              .then((snapshot) => {
                documentData["process"] = snapshot.docs.map((doc) => {
                  let subResourceData = doc.data();
                  subResourceData["id"] = doc.id;
                  return subResourceData;
                });
              })
          );
          // promises.push(db.collection('step').doc(documentData.id).collection('anotherSubCollection').get()
          // .then(snapshot => {
          //     documentData['process'] = snapshot.docs.map(doc => {
          //         return doc.data();
          //     })
          // }));
          return Promise.all(promises).then(function () {
            return documentData;
          });
        });

        Promise.all(ResourcePromises).then(function (allResources) {
          resolve(allResources);
        });
      })
      .catch((err) => {
        reject("Error getting steps and processes", err);
      });
  });
}; */

export const getStepsAndProcesses = () => {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('step')
			.get()
			.then((snapshot) => {
				let ResourcePromises = snapshot.docs.map(async (doc) => {
					var promises = [];
					let documentData = doc.data();
					documentData['id'] = doc.id;
					promises.push(
						db
							.collection('step')
							.doc(documentData.id)
							.collection('process')
							.orderBy('seq', 'asc')
							.get()
							.then((snapshot) => {
								documentData['process'] = snapshot.docs.map((doc) => {
									let subResourceData = doc.data();
									subResourceData['id'] = doc.id;
									return subResourceData;
								});
							})
					);
					await Promise.all(promises);
					return documentData;
				});

				Promise.all(ResourcePromises).then(function (allResources) {
					resolve(allResources);
				});
			})
			.catch((err) => {
				reject('Error getting steps and processes', err);
			});
	});
};

export const addStepsAndProcesses = (teacherDocID, projectID, stepsProcesses) => {
	const db = appBase
		.firestore()
		.collection('users')
		.doc(teacherDocID)
		.collection('projects')
		.doc(projectID)
		.collection('steps');

	stepsProcesses.forEach((s) => {
		db.doc(s.id)
			.set({ title: s.title, seq: s.seq })
			.then(() => {
				// console.log("add successful", response);
				s.process.forEach((p) => {
					db.doc(s.id)
						.collection('process')
						.doc(p.id)
						.set({
							title: p.title,
							seq: p.seq,
							desc: p.desc ? p.desc : '',
							isScoring: p.isScoring ? p.isScoring : '',
							html: p.html ? p.html : '',
							placeholder: p.placeholder ? p.placeholder : '',
						})
						.then(() => {
							// console.log("add successful", response);
						})
						.catch((err) => console.log('error in adding process', err));
				});
			})
			.catch((err) => console.log('error in adding step', err));
	});
};

export const getStepsAndProcessesProject = (teacherDocID, projectID) => {
	return new Promise((resolve, reject) => {
		const db = appBase.firestore().collection('users').doc(teacherDocID).collection('projects').doc(projectID);
		db.collection('steps')
			.get()
			.then((snapshot) => {
				let ResourcePromises = snapshot.docs.map((doc) => {
					var promises = [];
					let documentData = doc.data();
					documentData['id'] = doc.id;
					promises.push(
						db
							.collection('steps')
							.doc(documentData.id)
							.collection('process')
							.orderBy('seq', 'asc')
							.get()
							.then((snapshot) => {
								documentData['process'] = snapshot.docs.map((doc) => {
									let subResourceData = doc.data();
									subResourceData['id'] = doc.id;
									return subResourceData;
								});
							})
					);
					// promises.push(db.collection('step').doc(documentData.id).collection('anotherSubCollection').get()
					// .then(snapshot => {
					//     documentData['process'] = snapshot.docs.map(doc => {
					//         return doc.data();
					//     })
					// }));
					return Promise.all(promises).then(function () {
						return documentData;
					});
				});

				Promise.all(ResourcePromises).then(function (allResources) {
					resolve(allResources);
				});
			})
			.catch((err) => {
				reject('Error getting steps and processes', err);
			});
	});
};
export const getAndSetProjectScoring = (teacherDocID, projectID, stepID) => {
	// console.log("getAndSetProjectScoring", teacherDocID, projectID, stepID);
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('project-type')
			.doc(stepID)
			.collection('scores')
			.get()
			.then((snapshot) => {
				const getScores = snapshot.docs.map((doc) => {
					return {
						id: doc.id,
						...doc.data(),
					};
				});
				getScores.forEach((s, i) => {
					appBase
						.firestore()
						.collection('users')
						.doc(teacherDocID)
						.collection('projects')
						.doc(projectID)
						.collection('scores')
						.doc(s.id)
						.set(s);
				});
				resolve(true);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getProjectScoring = (teacherDocID, projectID) => {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('users')
			.doc(teacherDocID)
			.collection('projects')
			.doc(projectID)
			.collection('scores')
			.orderBy('seq', 'asc')
			.get()
			.then((snapshot) => {
				const getScores = snapshot.docs.map((doc) => {
					return {
						id: doc.id,
						...doc.data(),
					};
				});
				resolve(getScores);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getNotebooks = (teacherDocID, projectID, groupArr) => {
	return new Promise((resolve, reject) => {
		const db = appBase.firestore().collection('users').doc(teacherDocID).collection('notebooks');

		db.where('projectID', '==', projectID)
			.get()
			.then((snapshot) => {
				const getGroupTitle = (id) => {
					if (id === null) {
						return null;
					}
					const ttl = groupArr.filter((g) => g.id === id);
					return ttl[0].title;
				};
				let ResourcePromises = snapshot.docs.map(async (doc) => {
					let promises = [];
					let documentData = {
						id: doc.id,
						title: doc.data().title,
						fileSize: doc.data().fileSize ? doc.data().fileSize : 0,
						createdDT: doc.data().createdDT.seconds,
						isViewable: doc.data().isViewable,
						isTrial: doc.data().isTrial,

						group: doc.data().group && groupArr.length > 0 ? doc.data().group : null,
						groupTitle: doc.data().group && groupArr.length > 0 ? getGroupTitle(doc.data().group) : '',
					};
					promises.push(
						db
							.doc(doc.id)
							.collection('scores')
							.get()
							.then((snapshot) => {
								documentData['scores'] = snapshot.docs.map((doc) => {
									let subResourceData = doc.data();
									subResourceData['id'] = doc.id;
									return subResourceData;
								});
							})
					);
					await Promise.all(promises);
					return documentData;
				});

				Promise.all(ResourcePromises).then(function (allResources) {
					resolve(allResources);
				});
			})
			.catch((err) => {
				reject('Error getting notebooks', err);
			});
	});
};

export const getNotebookPermissions = (teacherDocID, notebookID) => {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('users/' + teacherDocID + '/notebooks')
			.doc(notebookID)
			.collection('permissions')
			.get()
			.then((snapshot) => {
				const getPermissions = snapshot.docs.map((doc) => {
					return {
						id: doc.id,
						isActive: doc.data().isActive,
						title: doc.data().title,
					};
				});
				resolve(getPermissions);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getNotebookFeedback = (teacherDocID, notebookID) => {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('users/' + teacherDocID + '/notebooks')
			.doc(notebookID)
			.collection('feedback')
			.orderBy('createdDT', 'asc')
			.get()
			.then((snapshot) => {
				const getFeedback = snapshot.docs.map((doc) => {
					return {
						id: doc.id,
						...doc.data(),
					};
				});
				resolve(getFeedback);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const saveNotebookEdits = (teacherDocID, notebookID, newData) => {
	appBase
		.firestore()
		.collection('users/' + teacherDocID + '/notebooks')
		.doc(notebookID)
		.update(newData)
		.then(() => {})
		.catch((error) => {
			console.log('ERROR SAVING NOTEBOOK EDITS', error);
		});
};

export const getSubmittedNotebookID = (projectID) => {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('competition')
			.doc(projectID)
			.get()
			.then((snapshot) => {
				let notebookID = snapshot.data().notebookID;
				resolve(notebookID);
			})
			.catch(() => {
				resolve('');
				// reject("NO SUBMITTED NOTEBOOK ID", error);
			});
	});
};

export const deleteSubmittedNotebookID = (projectID) => {
	appBase
		.firestore()
		.collection('competition')
		.doc(projectID)
		.delete()
		.then(() => {})
		.catch((error) => {
			console.log('ERROR DELETING SUBMITTED NOTEBOOK', error);
		});
};

export const deleteProject = (teacherDocID, projectID) => {
	return new Promise((resolve, reject) => {
		appBase
			.firestore()
			.collection('users')
			.doc(teacherDocID)
			.collection('projects')
			.doc(projectID)
			.delete()
			.then(() => {
				resolve(true);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const saveCompetition = (teacherDocID, projectID, notebookID, data) => {
	const db = appBase.firestore().collection('competition').doc(projectID);
	const addMoreData = {
		...data,
		teacherDocID: teacherDocID,
		notebookID: notebookID,
	};
	db.set(addMoreData)
		.then(() => {
			// console.log("Set new competition form entry");
			appBase
				.firestore()
				.collection('users')
				.doc(teacherDocID)
				.collection('notebooks')
				.doc(notebookID)
				.collection('scores')
				.get()
				.then((snapshot) => {
					// console.log("fetched scores from notebook");
					snapshot.docs.forEach((doc, i) => {
						db.collection('scores')
							.doc(doc.id)
							.set(doc.data())
							.then(() => {
								// console.log("Added a score in scores, iteration " + i);
							})
							.catch((error) => {
								console.log('ERROR ADDING SCORES TO COMPETITION', error);
							});
					});
				})
				.catch((error) => {
					console.log('ERROR GETTING NOTEBOOK SCORES', error);
				});
		})
		.catch((error) => {
			console.log('ERROR SAVING COMPETITION', error);
		});
};

// export const saveCompetition = (teacherDocID, projectID, notebookID, data) => {
//   const db = appBase.firestore().collection("users").doc(teacherDocID);

//   db.collection("projects")
//     .doc(projectID)
//     .collection("competition")
//     .doc(notebookID)
//     .set(data)
//     .then(() => {
//       // console.log("Set new competition form entry");
//       db.collection("notebooks")
//         .doc(notebookID)
//         .collection("scores")
//         .get()
//         .then((snapshot) => {
//           // console.log("fetched scores from notebook");
//           snapshot.docs.forEach((doc, i) => {
//             db.collection("projects")
//               .doc(projectID)
//               .collection("competition")
//               .doc(notebookID)
//               .collection("scores")
//               .doc(doc.id)
//               .set(doc.data())
//               .then(() => {
//                 // console.log("Added a score in scores, iteration " + i);
//               })
//               .catch((error) => {
//                 console.log("ERROR ADDING SCORES TO COMPETITION", error);
//               });
//           });
//         })
//         .catch((error) => {
//           console.log("ERROR GETTING NOTEBOOK SCORES", error);
//         });
//     })
//     .catch((error) => {
//       console.log("ERROR SAVING COMPETITION", error);
//     });
// };

export const saveNotebookPermissions = (teacherDocID, notebookID, stepID, newData) => {
	appBase
		.firestore()
		.collection('users/' + teacherDocID + '/notebooks')
		.doc(notebookID)
		.collection('permissions')
		.doc(stepID)
		.update(newData)
		.then(() => {})
		.catch((error) => {
			console.log('ERROR SAVING PERMISSIONS', error);
		});
};

export const upgradeNb = (teacherDocID, notebookID) => {
	appBase
		.firestore()
		.collection('users/' + teacherDocID + '/notebooks')
		.doc(notebookID)
		.update({
			isTrial: false,
			createdDT: new Date(),
		})
		.then(() => {})
		.catch((error) => {
			console.log('ERROR UPGRADING NOTEBOOK', error);
		});
};

export const addNotebookEntry = (teacherDocID, notebookID, ckData, processID, studentArray, tocCat) => {
	return new Promise((resolve, reject) => {
		let modifiedCkData = ckData;
		//stripping out pasted in styles, which causes conflicts with notebook display
		// modifiedCkData = modifiedCkData.replaceAll(/ style=".*?\"\B/g, "");
		// modifiedCkData = modifiedCkData.replaceAll(/ style='.*?\'\B/g, "");
		modifiedCkData = modifiedCkData.replaceAll('float:', 'align:');
		// console.log(modifiedCkData);

		const prefix = db.collection('users').doc(teacherDocID).collection('notebooks').doc(notebookID);

		prefix.get().then((res) => {
			let entryNo = 1;
			if (res.data().entryNo) {
				entryNo = res.data().entryNo + 1;
			}
			prefix
				.collection('entries')
				.add({
					html: modifiedCkData,
					createdDT: new Date(),
					processID: processID,
					studentID: studentArray.id,
					studentName: studentArray.first + ' ' + studentArray.last.substr(0, 1),
					entryNo: entryNo,
					tocCat: tocCat,
				})
				.then(() => {
					prefix
						.update({
							entryNo: entryNo,
						})
						.then(() => {})
						.catch((error) => {
							console.log(error);
						});
					resolve(true);
				})
				.catch((error) => {
					reject(error);
				});
		});
	});
};

export const updateNotebookSize = (teacherDocID, notebookID, newFileSize) => {
	const prefix = db.collection('users').doc(teacherDocID).collection('notebooks').doc(notebookID);

	prefix.get().then((res) => {
		let dbFileSize = 0;
		if (res.data().fileSize) {
			dbFileSize = res.data().fileSize + newFileSize;
		} else {
			dbFileSize = newFileSize;
		}
		prefix
			.update({
				fileSize: dbFileSize,
			})
			.then(() => {
				// console.log('succ', dbFileSize);
			})
			.catch((error) => {
				console.log('ERROR IN UPDATING FILE SIZE', error);
			});
	});
};

// export const addNotebookEntry = (
//   teacherDocID,
//   notebookID,
//   ckData,
//   processID,
//   studentArray
// ) => {
//   return new Promise((resolve, reject) => {
//     db.collection("users")
//       .doc(teacherDocID)
//       .collection("notebooks")
//       .doc(notebookID)
//       .collection("entries")
//       .add({
//         html: ckData,
//         createdDT: new Date(),
//         processID: processID,
//         studentID: studentArray.id,
//         studentName: studentArray.first + " " + studentArray.last.substr(0, 1),
//       })
//       .then(() => {
//         resolve(true);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };

export function getFAQ(type) {
	return new Promise((resolve, reject) => {
		db.collection('help-files')
			.orderBy('seq', 'asc')
			.get()
			.then((snapshot) => {
				const helpData = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
					createdDT: doc.data().createdDT.seconds,
					// createdDT: new Date(
					//   doc.data().createdDT.seconds * 1000
					// ).toDateString(),
				}));
				const filteredHelpData = helpData.filter(
					(h) => ((type === '2' && (h.category === type || h.category === '3')) || type === '1') && h.isFAQ
				);
				resolve(filteredHelpData);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getHelpFile(id) {
	return new Promise((resolve, reject) => {
		db.collection('help-files')
			.doc(id)
			.get()
			.then((snapshot) => {
				resolve(snapshot.data());
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getHelpFileArray(args) {
	return new Promise((resolve, reject) => {
		db.collection('help-files')
			.where('category', 'in', args.category)
			.where('subcategory', '==', args.subcategory)
			// .orderBy("seq", "asc")
			// .where(appBase.FieldPath.documentId(), "in", arr)
			.get()
			.then((snapshot) => {
				const getArr = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				resolve(getArr);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getStudentDiscussion(teacherDocID, notebookID, entryID) {
	return new Promise((resolve, reject) => {
		let initdb = db
			.collection('users')
			.doc(teacherDocID)
			.collection('notebooks')
			.doc(notebookID)
			.collection('discussion');
		// commented for now, need google permissions to set index on where clause
		if (entryID) {
			initdb = initdb.where('entryID', '==', entryID);
		}
		initdb
			// .orderBy("createdDT", "asc")
			.get()
			.then((snapshot) => {
				const getDiscussion = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				resolve(getDiscussion);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

/* export const getSteps = () => {
  return new Promise((resolve, reject) => {
    const db = appBase.firestore().collection("step");
    db.orderBy("seq", "asc")
      .get()
      .then((snapshot) => {
        const getSteps = snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            title: doc.data().title,
            seq: doc.data().seq,
          };
        });

        resolve(getSteps);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getProcesses = (stepID) => {
  return new Promise((resolve, reject) => {
    const db = appBase
      .firestore()
      .collection("step")
      .doc(stepID)
      .collection("process")
      .orderBy("seq", "asc");

    db.get()
      .then((snapshot) => {
        const getProcess = snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            title: doc.data().title,
            seq: doc.data().seq,
          };
        });
        resolve(getProcess);
      })
      .catch((error) => {
        reject(error);
      });
  });
}; */

export function getNotebookPurchases(teacherDocID) {
	return new Promise((resolve, reject) => {
		const promise1 = appBase.firestore().collection('users').doc(teacherDocID).collection('notebooks').get();

		const promise2 = appBase.firestore().collection('users').doc(teacherDocID).collection('purchase').get();

		Promise.all([promise1, promise2])
			.then((response) => {
				// const notebookCount = response[0].docs.length;

				let trialNotebookCount = 0;
				let notebookCount = 0;
				response[0].docs.forEach((doc) => {
					if (doc.data().isTrial) {
						trialNotebookCount += 1;
					} else {
						notebookCount += 1;
					}
				});
				let purchaseQty = 0;
				response[1].docs.map((doc) => (purchaseQty = purchaseQty + doc.data().quantity));
				const returnArr = {
					trialNotebookCt: trialNotebookCount,
					notebookCt: notebookCount,
					purchaseQty: purchaseQty,
					remainingQty: purchaseQty - notebookCount,
				};
				resolve(returnArr);
			})
			.catch((error) => {
				reject(error);
			});
	});
}
export function currencyFormat(num) {
	return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export const seqToLetter = (seq) => {
	const letArr = [
		'A',
		'B',
		'C',
		'D',
		'E',
		'F',
		'G',
		'H',
		'I',
		'J',
		'K',
		'L',
		'M',
		'N',
		'O',
		'P',
		'Q',
		'R',
		'S',
		'T',
		'U',
		'V',
		'W',
		'X',
		'Y',
		'Z',
	];
	let blockLetter = '';
	let block2Letter = '';
	let counter = 0;
	let blockCounter = -1;
	let block2Counter = -1;
	let returnLetter = '';
	for (let i = 0; i <= seq; i++) {
		if (counter === 26) {
			counter = 0;
			blockCounter++;
		}
		if (blockCounter === 26) {
			blockCounter = 0;
			block2Counter++;
		}
		counter++;
	}

	if (block2Counter >= 0) {
		block2Letter = letArr[block2Counter];
	}
	if (blockCounter >= 0) {
		blockLetter = letArr[blockCounter];
	}
	returnLetter = block2Letter + blockLetter + letArr[counter - 1];
	return returnLetter;
	//will accept a sequence from 0 to 18277
};

export const allStates = [
	{
		title: 'Alabama',
		id: 'AL',
	},
	{
		title: 'Alaska',
		id: 'AK',
	},
	{
		title: 'American Samoa',
		id: 'AS',
	},
	{
		title: 'Arizona',
		id: 'AZ',
	},
	{
		title: 'Arkansas',
		id: 'AR',
	},
	{
		title: 'California',
		id: 'CA',
	},
	{
		title: 'Colorado',
		id: 'CO',
	},
	{
		title: 'Connecticut',
		id: 'CT',
	},
	{
		title: 'Delaware',
		id: 'DE',
	},
	{
		title: 'District Of Columbia',
		id: 'DC',
	},
	{
		title: 'Federated States Of Micronesia',
		id: 'FM',
	},
	{
		title: 'Florida',
		id: 'FL',
	},
	{
		title: 'Georgia',
		id: 'GA',
	},
	{
		title: 'Guam',
		id: 'GU',
	},
	{
		title: 'Hawaii',
		id: 'HI',
	},
	{
		title: 'Idaho',
		id: 'ID',
	},
	{
		title: 'Illinois',
		id: 'IL',
	},
	{
		title: 'Indiana',
		id: 'IN',
	},
	{
		title: 'Iowa',
		id: 'IA',
	},
	{
		title: 'Kansas',
		id: 'KS',
	},
	{
		title: 'Kentucky',
		id: 'KY',
	},
	{
		title: 'Louisiana',
		id: 'LA',
	},
	{
		title: 'Maine',
		id: 'ME',
	},
	{
		title: 'Marshall Islands',
		id: 'MH',
	},
	{
		title: 'Maryland',
		id: 'MD',
	},
	{
		title: 'Massachusetts',
		id: 'MA',
	},
	{
		title: 'Michigan',
		id: 'MI',
	},
	{
		title: 'Minnesota',
		id: 'MN',
	},
	{
		title: 'Mississippi',
		id: 'MS',
	},
	{
		title: 'Missouri',
		id: 'MO',
	},
	{
		title: 'Montana',
		id: 'MT',
	},
	{
		title: 'Nebraska',
		id: 'NE',
	},
	{
		title: 'Nevada',
		id: 'NV',
	},
	{
		title: 'New Hampshire',
		id: 'NH',
	},
	{
		title: 'New Jersey',
		id: 'NJ',
	},
	{
		title: 'New Mexico',
		id: 'NM',
	},
	{
		title: 'New York',
		id: 'NY',
	},
	{
		title: 'North Carolina',
		id: 'NC',
	},
	{
		title: 'North Dakota',
		id: 'ND',
	},
	{
		title: 'Northern Mariana Islands',
		id: 'MP',
	},
	{
		title: 'Ohio',
		id: 'OH',
	},
	{
		title: 'Oklahoma',
		id: 'OK',
	},
	{
		title: 'Oregon',
		id: 'OR',
	},
	{
		title: 'Palau',
		id: 'PW',
	},
	{
		title: 'Pennsylvania',
		id: 'PA',
	},
	{
		title: 'Puerto Rico',
		id: 'PR',
	},
	{
		title: 'Rhode Island',
		id: 'RI',
	},
	{
		title: 'South Carolina',
		id: 'SC',
	},
	{
		title: 'South Dakota',
		id: 'SD',
	},
	{
		title: 'Tennessee',
		id: 'TN',
	},
	{
		title: 'Texas',
		id: 'TX',
	},
	{
		title: 'Utah',
		id: 'UT',
	},
	{
		title: 'Vermont',
		id: 'VT',
	},
	{
		title: 'Virgin Islands',
		id: 'VI',
	},
	{
		title: 'Virginia',
		id: 'VA',
	},
	{
		title: 'Washington',
		id: 'WA',
	},
	{
		title: 'West Virginia',
		id: 'WV',
	},
	{
		title: 'Wisconsin',
		id: 'WI',
	},
	{
		title: 'Wyoming',
		id: 'WY',
	},
];
