import React, { Component } from "react";
import ContentEditable from "react-contenteditable";
import Button from "../../UI/Forms/Button";
// import Textbox from "../../UI/Forms/Textbox";
import IconButton from "@material-ui/core/IconButton";
import StrikethroughSIcon from "@material-ui/icons/StrikethroughS";
import appSettings from "../../appSettings";
// import sanitizeHtml from "sanitize-html";
// import "./styles.css";
import { isMobile } from "react-device-detect";

class EditorEdit extends Component {
  constructor(props) {
    super(props);
    // const startHtml = props.data;
    this.state = {
      id: props.data.id,
      html: props.data.html,
      newHtml: props.data.html,
      editable: true,
      strikeClicked: false,
      txtStrikeReason: "",
    };
  }
  handleChange = async (evt) => {
    const editDate = new Date().toDateString();
    let htmlChange = evt.target.value;

    htmlChange = await htmlChange.replace(
      "<strike>",
      "<span style='color:#999999; text-decoration: line-through;'>"
    );

    htmlChange = htmlChange.replace(
      "</strike>",
      "</span><span style='font-size:.7rem; color: #000000;'><sup style='background:#fffdaf;'> [Edited by " +
        this.props.data.editName +
        " on " +
        editDate +
        "]<!--strike reason--></sup></span>"
    );

    // console.log(htmlChange);

    this.setState({ newHtml: htmlChange, strikeClicked: true });
  };
  textboxToggle = () => {
    if (this.state.strikeClicked) {
      return (
        // <Textbox
        //   placeholder="Enter reason for strike"
        //   onChange={(returnText) => {
        //     console.log("return text", returnText);
        //   }}
        // />
        <input
          autoFocus
          name="txtStrikeReason"
          placeholder="Reason for strike"
          onChange={(event) => {
            event.preventDefault();
            let txtEntry = event.target.value;
            this.setState({
              txtStrikeReason: txtEntry,
            });
          }}
        />
      );
    } else {
      return (
        <>
          {" "}
          <IconButton
            key="strikethrough"
            title="strike-through"
            aria-label="strike-through"
            component="span"
            disabled={this.state.strikeClicked}
            onClick={(evt) => {
              evt.preventDefault(); // Avoids loosing focus from the editable area
              document.execCommand("strikethrough", false, false); // Send the command to the browser
            }}
          >
            <StrikethroughSIcon style={{ color: appSettings.themeColor }} />
          </IconButton>
        </>
      );
    }
  };

  render = () => {
    if (isMobile) {
      return (
        <div>
          The strike through feature is not yet available on mobile devices.
          Please log in with a desktop computer to use this feature.
        </div>
      );
    }
    return (
      <div>
        <p>
          Highlight the block(s) of text you wish to strike through, then click
          the <StrikethroughSIcon style={{ fontSize: 14 }} /> button.
        </p>
        {/* <Button
          buttonKey="strikethrough"
          buttonClicked={(evt) => {
            evt.preventDefault(); // Avoids loosing focus from the editable area
            // this.setState({ editable: true });
            document.execCommand("strikethrough", false, false); // Send the command to the browser
          }}
        >
          Strikethrough
        </Button> */}
        <div>
          {this.textboxToggle()}
          <Button
            buttonType="save"
            disabled={this.state.txtStrikeReason.length === 0}
            buttonClicked={(evt) => {
              let htmlChange = this.state.newHtml;
              htmlChange = htmlChange.replace(
                "<!--strike reason-->",
                "&nbsp;&nbsp;" + this.state.txtStrikeReason
              );

              this.props.saveEdit(this.state.id, htmlChange);
            }}
          >
            Save
          </Button>
          <Button
            buttonClicked={(evt) => {
              evt.preventDefault();
              this.setState({
                newHtml: this.state.html,
                editable: true,
                strikeClicked: false,
                txtStrikeReason: "",
              });
            }}
          >
            Reset
          </Button>
          <Button buttonClicked={this.props.cancelEdit}>Cancel</Button>
        </div>
        {this.state.editable ? (
          ""
        ) : (
          <div style={{ color: "red" }}>
            <strong>Can not type in edit mode. Please click Reset.</strong>
          </div>
        )}
        <ContentEditable
          style={{
            padding: 10,
            maxHeight: 500,
            border: "1px dashed #AAAAAA",
            overflow: "scroll",
          }}
          tagName="div"
          html={this.state.newHtml} // innerHTML of the editable div
          disabled={!this.state.editable} // use true to disable edition
          onChange={this.handleChange} // handle innerHTML change
          onKeyDown={() => {
            this.setState({ editable: false });
          }}
        />
        {/* <pre>HTML: {JSON.stringify(this.state, 0, 3)}</pre> */}
      </div>
    );
  };
}
export default EditorEdit;
