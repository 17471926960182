import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getDocID } from "../../global/globalFunctions";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import Snackbar from "../../UI/Alerts/Snackbar";
import ButtonSpinner from "../../UI/Spinner/ButtonSpinner";
import appBase from "../../Auth/base";
import axios from "axios";

const useStyles = makeStyles(() => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formField: {
    height: "30px",
    border: "0.5px solid rgba(0, 0, 0, 0.3)",
    marginBottom: "15px",
  },
  submitField: {
    width: "100px",
    padding: "2px 10px",
    border: "none",
    borderRadius: "5px",
    marginTop: "15px",
    background: "#b72b2e",
    textTransform: "uppercase",
    color: "#fff",
    fontWeight: 500,
    lineHeight: 1.75,
    fontSize: "0.8125rem",
    fontFamily: '"Roboto", sans-serif',
  },
  orderInfoWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: "15px",
    marginTop: "-5px",

    "& .MuiTypography-root.MuiTypography-body2": {
      fontFamily: '"Roboto", sans-serif',
      flexBasis: "100%",

      "& span": {
        fontWeight: "500 !important",
      },
    },
  },
  spinnerWrapper: {
    margin: 0,
    display: "inline-flex",
    position: "relative",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "center",
    textTransform: "uppercase",
    fontWeight: 500,
    lineHeight: 1.75,
    minWidth: "64px",
    maxWidth: "64px",
    maxHeight: "42px",
    fontSize: "0.875em",
    padding: "6px 8px",
  },
}));

export default function PaymentDialog({ open, setOpen, items }) {
  const classes = useStyles();
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    type: "error",
  });

  useEffect(() => {
    if (!open) return;

    window.braintree.client.create(
      {
        // authorization: "sandbox_w32k7g6y_q5c7rvq9z8gky9s5",
        authorization: "production_ndks5ygj_fn5wvxg8pdqjd62d",
      },
      function (clientErr, clientInstance) {
        if (clientErr) {
          console.error(clientErr);
          return;
        }

        window.braintree.hostedFields.create(
          {
            client: clientInstance,
            id: "hosted-fields-form",
            styles: {
              input: {
                "font-size": "14px",
              },
              "input.invalid": {
                color: "red",
              },
              "input.valid": {
                color: "green",
              },
            },
            fields: {
              number: {
                selector: "#card-number",
                placeholder: "4111 1111 1111 1111",
              },
              cvv: {
                selector: "#cvv",
                placeholder: "123",
              },
              expirationDate: {
                selector: "#expiration-date",
                placeholder: "10/2022",
              },
            },
          },
          function (hostedFieldsErr, instance) {
            if (hostedFieldsErr) {
              console.error(hostedFieldsErr);
              return;
            }

            formRef.current.addEventListener(
              "submit",
              function (event) {
                event.preventDefault();
                setLoading(true);

                instance.tokenize(function (tokenizeErr, payload) {
                  if (tokenizeErr) {
                    console.error(tokenizeErr);
                    return;
                  }

                  const data = {
                    amount: items.amount,
                    nonce: payload.nonce,
                  };

                  axios
                    .post("/payment/checkout", data)
                    .then(async (res) => {
                      console.log("Checkout response: ", res);
                      const {
                        data: {
                          transactionResponse: { transaction },
                        },
                      } = res;

                      const dbPayload = {
                        ...items,
                        purchaseDT: new Date(),
                        last4: transaction.creditCard.last4,
                        transactionId: transaction.id,
                      };
                      console.log("dbPayload: ", dbPayload);

                      instance.teardown((teardownErr) => {
                        if (teardownErr) {
                          console.error(
                            "Could not tear down the Hosted Fields form!"
                          );
                        } else {
                          console.info(
                            "Hosted Fields form has been torn down!"
                          );
                        }
                      });

                      if (res.data.transactionResponse.success) {
                        const userId = await getDocID();
                        console.log("userId: ", userId);

                        const userDocRef = appBase
                          .firestore()
                          .collection("users")
                          .doc(userId);
                        // console.log("userDocRef: ", userDocRef);

                        userDocRef.get().then((user) => {
                          console.log("user", user.data());
                          const purchaseDocRef = userDocRef
                            .collection("purchase")
                            .doc();
                          console.log("purchaseDocRef: ", purchaseDocRef);

                          purchaseDocRef
                            .set({
                              ...dbPayload,
                              docId: purchaseDocRef.id,
                            })
                            .then(() => {
                              // console.log(
                              //   "Purchase Document added!",
                              //   purchaseDocRef.id
                              // );
                            })
                            .catch((err) =>
                              console.log("error adding purchase doc: ", err)
                            );
                        });

                        setSnackbar({
                          open: true,
                          message: "Payment Successful!",
                          severity: "success",
                        });
                        setOpen(false);
                      } else {
                        console.error(`Error! Check your console.`);
                        // return;
                        setLoading(false);
                        setSnackbar({
                          open: true,
                          duration: 20000,
                          message:
                            "The credit card transaction was denied. No charges have been applied. Please check your credit card information and try again.",
                          severity: "error",
                        });
                        setOpen(false);
                      }
                    })
                    .catch((err) => console.log("axios error: ", err));
                });
              },
              false
            );
          }
        );
      }
    );

    setLoading(false);
  }, [open, items, setOpen]);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Confirm Payment</DialogTitle>
        <DialogContent>
          {items && (
            <div className={classes.orderInfoWrapper}>
              <Typography variant="body2">
                Quantity:{" "}
                <span>
                  {items.quantity} Notebooks x ${items.pricePer} Each
                </span>
              </Typography>
              <Typography variant="body2">
                Total Amount: <span>${items.amount}</span>
              </Typography>
            </div>
          )}
          <form id="hosted-fields-form" ref={formRef}>
            <label htmlFor="card-number">Card Number</label>
            <div className={classes.formField} id="card-number"></div>

            <label htmlFor="cvv">CVV</label>
            <div className={classes.formField} id="cvv"></div>

            <label htmlFor="expiration-date">Expiration Date</label>
            <div className={classes.formField} id="expiration-date"></div>

            <div id="checkout-message"></div>

            {
              (!loading,
              items && (
                <Button className={classes.submitField} type="submit">
                  PAY ${items.amount}
                </Button>
              ))
            }
            <div className={classes.spinnerWrapper}>
              {loading && <ButtonSpinner />}
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        duration={snackbar.duration}
        close={() => {
          setSnackbar({
            open: false,
            message: "",
            severity: snackbar.severity,
          });
        }}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </>
  );
}
