import React, { useState, useEffect } from 'react';
import Drawer from './UI/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { useAuthContext } from './context';
import { ROUTES } from './global/constants';
import { useHistory } from 'react-router-dom';
import DrawerNotebook from './UI/DrawerNotebook';
import Footer from './UI/Footer/Footer';

const Home = (props) => {
	const useStyles = makeStyles((theme) => ({
		root: {
			display: 'flex',
		},
		toolbar: theme.mixins.toolbar,
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),

			[theme.breakpoints.down('xs')]: {
				maxWidth: '100vw',
			},
		},
	}));

	const classes = useStyles();
	const history = useHistory();
	const { onLogout } = useAuthContext();
	const [mobileOpen, setMobileOpen] = useState(false);
	const [drawerWidth, setDrawerWidth] = useState(200);

	useEffect(() => {
		if (props.drawerWidth >= 0) {
			setDrawerWidth(props.drawerWidth);
		}
	}, [props.drawerWidth]);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const handleSignOut = async () => {
		await onLogout();
		history.push(ROUTES.teachers.login);
	};

	return (
		<div className={classes.root}>
			{props.isNotebookPage ? (
				<DrawerNotebook
					handleDrawerToggle={handleDrawerToggle}
					mobileOpen={mobileOpen}
					drawerWidth={drawerWidth}
					signOut={handleSignOut}
					scoringClick={() => props.scoring}
					steps={props.steps}
					type={props.type}
					notebookID={props.notebookID}
					projectID={props.projectID}
					passProcessID={(id) => {
						props.xxx(id);
					}}
				/>
			) : (
				<Drawer
					handleDrawerToggle={handleDrawerToggle}
					mobileOpen={mobileOpen}
					drawerWidth={drawerWidth}
					signOut={handleSignOut}
				/>
			)}

			<main className={classes.content}>
				<div className={classes.toolbar} />
				{props.children}
				<Footer />
			</main>
		</div>
	);
};

export default Home;
