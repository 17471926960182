import React, { useState, useEffect } from "react";
import Textbox from "../../UI/Forms/Textbox";
import Button from "../../UI/Forms/Button";
import { Link } from "react-router-dom";
// import Combo from "../../UI/Forms/Combo";
import Dropdown from "../../UI/Forms/Dropdown";
// import { TextField } from "@material-ui/core";

const AddEditNotebook = (props) => {
  const [group, setGroup] = useState("");
  const [groupOptions, setGroupOptions] = useState([]);
  const [groupValue, setGroupValue] = useState("");
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    setGroupOptions([
      { id: "", title: "-NONE-" },
      ...props.groups,
      { id: "new", title: "-CREATE NEW GROUP-" },
    ]);
    setGroupValue(props.data.group ? props.data.group : "");
    setSpinner(false);
  }, [props.groups, props.data.group]);

  const display = () => {
    let notebooksRemaining = 1;
    if (props.data.type === "New") {
      notebooksRemaining =
        props.projectNotebookArr.purchaseQty -
        props.projectNotebookArr.notebookCt;
    }
    if (
      (notebooksRemaining === 0 &&
        props.projectNotebookArr.trialNotebookCt === 0) ||
      notebooksRemaining >= 1
    ) {
      return (
        <form autoComplete="off" onSubmit={props.handleSave}>
          {props.data.type === "New" && (
            <>
              {notebooksRemaining === 0 && (
                <>
                  <p>
                    You currently have no notebook credits in your account. To
                    try things out, you may create a trial notebook that is
                    limited to
                    <span style={{ color: "red" }}>
                      <strong> 15 entries.</strong>
                    </span>{" "}
                    After making a purchase, you may convert this trial to a
                    fully functional notebook if needed.
                  </p>
                  <p>
                    If you wish to start with a fully functional notebook,
                    please
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                      to={"/teacher/account"}
                    >
                      {` Go Here `}
                    </Link>
                    to make a notebook purchase.
                  </p>
                </>
              )}
              {notebooksRemaining >= 1 && (
                <>
                  <p>
                    You currently have{" "}
                    <strong>{notebooksRemaining} notebook credits</strong>{" "}
                    remaining in your account. Clicking 'Create Notebook' will
                    deduct one notebook purchase. This action cannot be undone.
                  </p>
                  <p>
                    A single notebook generally consists of a team of 1 to 6
                    members. You may create one or several notebooks for this
                    project, depending on class size.
                  </p>
                </>
              )}
              <div>
                <input
                  type="hidden"
                  name="hdnTrial"
                  value={
                    notebooksRemaining === 0 &&
                    props.projectNotebookArr.trialNotebookCt === 0
                  }
                />
              </div>
            </>
          )}
          <div>
            <Textbox
              id="id-title"
              name="title"
              type="text"
              width="96%"
              required={true}
              label="Team Name"
              value={props.data.title ? props.data.title : ""}
            />
          </div>
          <div>
            {group === "new" ? (
              <>
                <Textbox
                  name="txtGroup"
                  autoFocus={true}
                  label="Enter New Group Name"
                />
                <Button
                  style={{
                    color: "#333",
                    background: "#FFF",
                    border: "none",
                    fontSize: 13,
                  }}
                  buttonClicked={() => setGroup("")}
                >
                  Back
                </Button>
              </>
            ) : (
              <Dropdown
                // options={[
                //   { id: "", title: "-NONE-" },
                //   { id: "new", title: "-CREATE NEW GROUP-" },
                // ]}
                options={groupOptions}
                label="(Optional) Group notebook in..."
                width="98%"
                value={groupValue ? groupValue : ""}
                name="nmGroup"
                id="idGroup"
                required={false}
                getOptionSelected={(option, value) => {
                  // console.log(option, value);
                }}
                handleChange={(val) => {
                  // console.log(val);
                  setGroup(val);
                }}
              />
            )}
          </div>
          <div style={{ float: "right" }}>
            <Button type="submit">
              {props.data.type === "New"
                ? notebooksRemaining > 0
                  ? "Create Notebook"
                  : "Create Trial Notebook"
                : "Save Notebook Changes"}
            </Button>
            <Button buttonClicked={props.handleClose}>Cancel</Button>
          </div>
        </form>
      );
    } else {
      return (
        <div>
          <div>
            You currently have{" "}
            <strong>{notebooksRemaining} notebook credits</strong> remaining in
            your account. Please click the 'Buy Notebooks' button for purchase
            options.
          </div>
          <div style={{ float: "right" }}>
            <Link style={{ textDecoration: "none" }} to={"/teacher/account"}>
              <Button type="submit">Buy Notebooks</Button>
            </Link>
            <Button buttonClicked={props.handleClose}>Cancel</Button>
          </div>
        </div>
      );
    }
  };
  return (
    <>
      {!spinner ? display() : ""}
      {/* <div>{JSON.stringify(props.data.type, 0, 3)}</div> */}
    </>
  );
};

export default AddEditNotebook;
