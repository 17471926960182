import React, { useState, useEffect } from "react";

import Home from "../../HomeStudent";
import {
  getStepsAndProcessesProject,
  getStudent,
  getNotebookPermissions,
} from "../../global/globalFunctions";
import Spinner from "../../UI/Spinner/Spinner";
import AllEntries from "../../Pages/Notebook/AllEntries";
import Discussion from "./Discussion";
import NotebookIntro from "./NotebookIntro";

const Notebook = (props) => {
  const [projectID] = useState(props.match.params.projectid);
  const [notebookID] = useState(props.match.params.notebookid);
  const [entryID] = useState(props.match.params.entryid);
  const [spinner, setSpinner] = useState(true);
  const [steps, setSteps] = useState([]);
  const [teacherDocID] = useState(
    window.sessionStorage.getItem("studentTeacherDocID")
  );
  const [studentArray, setStudentArray] = useState([]);
  const [processSelect, setProcessSelect] = useState(null);
  const [permissionsArray, setPermissionsArray] = useState([]);

  // useEffect(() => {
  //   const getStepsData = async () => {
  //     try {
  //       const fetchProject = await getProject(teacherDocID, projectID);
  //       setProject(fetchProject);
  //       const fetchSteps = await getStepsAndProcessesProject(
  //         teacherDocID,
  //         projectID
  //       );
  //       setSteps(fetchSteps);
  //       setSpinner(false);
  //     } catch (error) {
  //       setSpinner(false);
  //       console.log("getStepsData catch error", error);
  //     }
  //   };
  //   getStepsData();
  // }, [teacherDocID, projectID]);

  useEffect(() => {
    const getStepsData = async () => {
      try {
        if (entryID) {
          setProcessSelect("showentry");
        }
        const studentArr = await getStudent(teacherDocID);
        setStudentArray(studentArr);

        const getPermissionsArr = await getNotebookPermissions(
          teacherDocID,
          notebookID
        );
        setPermissionsArray(getPermissionsArr);

        const fetchSteps = await getStepsAndProcessesProject(
          teacherDocID,
          projectID
        );
        setSteps(fetchSteps);

        setSpinner(false);
      } catch (error) {
        setSpinner(false);
        console.log("getStepsData catch error", error);
      }
    };
    getStepsData();
  }, [teacherDocID, projectID, notebookID, entryID]);

  return (
    <Home
      isNotebookPage={true}
      steps={steps}
      projectID={projectID}
      notebookID={notebookID}
      passProcessID={(id) => setProcessSelect(id)}
    >
      {spinner ? (
        <Spinner />
      ) : processSelect === "discussion" ? (
        <Discussion
          teacherDocID={teacherDocID}
          projectID={projectID}
          notebookID={notebookID}
          type="student"
          steps={steps}
        />
      ) : processSelect ? (
        <AllEntries
          notebookID={notebookID}
          steps={steps}
          teacherDocID={teacherDocID}
          passProcessID={processSelect}
          type="student"
          permissionsArray={permissionsArray}
          studentArray={studentArray}
          gotoDiscussion={() => setProcessSelect("discussion")}
          showEntry={processSelect === "showentry" && entryID ? entryID : null}
          // processDetails={processDetails}
        />
      ) : (
        <NotebookIntro
          teacherDocID={teacherDocID}
          projectID={projectID}
          steps={steps}
          notebookID={notebookID}
          passProcessID={(id) => setProcessSelect(id)}
        />
      )}
      {/* <pre>{JSON.stringify(studentArray, 0, 3)}</pre> */}
      {/* <pre>{processSelect}</pre> */}
    </Home>
  );
};

export default Notebook;
