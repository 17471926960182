import React from "react";
import Button from "../../UI/Forms/Button";
import MathWrapper from "../../HOC/MathWrapper";

const PreviewEntry = (props) => {
  return (
    <div>
      <p>
        Please review your entry carefully and ensure that it is correct. Once
        you click the submit button, this will become a{" "}
        <strong>permanent entry</strong> in the notebook.
      </p>
      <p>
        <strong>
          WARNING: You will not have the ability to edit the contents of this
          entry once submitted.
        </strong>
      </p>
      <div>
        <Button
          buttonClicked={props.cancelEdit}
          style={{ background: "#EEEEEE", color: "#444444" }}
        >
          Cancel and Go Back
        </Button>
        <Button buttonType="save" buttonClicked={props.saveEntry}>
          I agree, Submit Entry
        </Button>
      </div>
      <div
        style={{
          padding: 10,
          maxHeight: 500,
          border: "1px dashed #AAAAAA",
          overflow: "scroll",
        }}
      >
        <MathWrapper html={props.data.html} />
      </div>
    </div>
  );
};
export default PreviewEntry;
