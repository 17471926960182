import React, { useState, useEffect } from "react";
import { getEdenDocs } from "../global/globalFunctions";
import Button from "../UI/Forms/Button";
import ReactHtmlParser from "react-html-parser";
import FullscreenModal from "../UI/Modals/FullscreenModal";
import Checkbox from "../UI/Forms/Checkbox";

const LoginModal = (props) => {
  const [docs, setDocs] = useState([]);
  const [modal, setModal] = useState({ open: false, data: [] });
  const [is18, setIs18] = useState(false);
  useEffect(() => {
    const getDocs = async () => {
      try {
        const procDocs = await getEdenDocs();
        setDocs(procDocs);
      } catch (err) {
        console.log("docs error", err);
      }
    };
    getDocs();
  }, []);

  const closeModal = () => {
    setModal({ ...modal, open: false });
  };
  return (
    <>
      <FullscreenModal
        show={modal.open}
        header={modal.data.title}
        handleClose={closeModal}
      >
        <div>{ReactHtmlParser(modal.data.html)}</div>
      </FullscreenModal>
      <p>
        PLEASE CLICK AGREE AT THE BOTTOM OF THIS FORM TO SIGNIFY YOUR AGREEMENT
        TO THE FOLLOWING DOCUMENTS.
      </p>
      {docs?.map((d, i) => {
        return (
          <div key={i} style={{ margin: "5px" }}>
            <span
              style={{
                fontWeight: "bold",
                color: "blue",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => setModal({ open: true, data: d })}
            >
              {d.title}
            </span>
          </div>
        );
      })}
      <div>
        <Checkbox
          required={true}
          label="I am 18 years of age or older."
          name="chkOK"
          onChange={(obj) => {
            setIs18(obj.chkOK);
          }}
        />
      </div>
      <div style={{ marginBottom: 50, marginTop: 15 }}>
        <Button buttonClicked={props.EULAAgree} disabled={!is18}>
          I have read and Agree to All Above, Register My Account
        </Button>
      </div>
      {/* <pre>{JSON.stringify(docs, 0, 3)}</pre>
      <pre>{JSON.stringify(modal, 0, 3)}</pre> */}
    </>
  );
};

export default LoginModal;
