import React, { useState, useEffect } from 'react';
import {
	getProject,
	getStudentNotebook,
	getProcessTitles,
	getNotebookEntry,
	getStudentDiscussion,
} from '../../global/globalFunctions';
import Help from '../../UI/Modals/Help';
import Header from '../../UI/Header/Header';
import Spinner from '../../UI/Spinner/Spinner';
// import EntryModal from '../../UI/Modals/FullscreenModal';
import EntryModal from './EntryModal';
// import ReactHtmlParser from 'react-html-parser';
import appBase from '../../Auth/base';
import Paper from '@material-ui/core/Paper';
// import nbStyles from './Notebook.module.css';
// import { Link } from "react-router-dom";
// import { useHistory } from "react-router-dom";

const Notebook = (props) => {
	const [project, setProject] = useState([]);
	const [notebook, setNotebook] = useState([]);
	const [spinner, setSpinner] = useState(true);
	const [discussion, setDiscussion] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [entry, setEntry] = useState([]);
	const [entryDiscussion, setEntryDiscussion] = useState([]);
	//   const [steps, setSteps] = useState([]);
	//   const [studentArray, setStudentArray] = useState([]);
	// const history = useHistory();

	useEffect(() => {
		const procProj = async () => {
			try {
				// if (props.type === "student") {
				//   const studentArr = await getStudent(props.teacherDocID);
				//   setStudentArray(studentArr);
				// }

				const procProject = await getProject(props.teacherDocID, props.projectID);
				setProject(procProject);

				const procGetNotebook = await getStudentNotebook(props.teacherDocID, props.notebookID);
				setNotebook(procGetNotebook);
				// const fetchSteps = await getStepsAndProcessesProject(
				//   props.teacherDocID,
				//   props.match.params.projectid
				// );
				// setSteps(fetchSteps);
				setSpinner(false);
			} catch (error) {
				console.log(error);
				setSpinner(false);
			}
		};
		procProj();
	}, [props.projectID, props.teacherDocID, props.notebookID, props.type]);

	useEffect(() => {
		let db = appBase
			.firestore()
			.collection('users')
			.doc(props.teacherDocID)
			.collection('notebooks')
			.doc(props.notebookID)
			.collection('discussion');
		const unsubscribe = db
			.orderBy('createdDT', 'asc')
			// .limit(2)
			.onSnapshot((snapshot) => {
				const getDiscussion = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				setDiscussion(getDiscussion);
			});
		return unsubscribe;
	}, [props.notebookID, props.teacherDocID]);

	const handleClick = async (entID) => {
		try {
			setSpinner(true);
			const nbEntry = await getNotebookEntry(props.teacherDocID, props.notebookID, entID);
			setEntry(nbEntry);
			const procEntryDiscussion = await getStudentDiscussion(props.teacherDocID, props.notebookID, entID);
			setEntryDiscussion(procEntryDiscussion);
			setSpinner(false);
			setOpenModal(true);
		} catch (err) {
			setSpinner(false);
			console.log(err);
		}
	};

	const closeEntryModal = () => {
		setOpenModal(false);
	};

	const displayDiscussion = () => {
		return (
			<>
				<EntryModal
					handleClose={closeEntryModal}
					openModal={openModal}
					entry={entry}
					entryDiscussion={entryDiscussion}
					showTeacherFeedback={true}
				/>

				<Header
					help={<Help helpId="7Q833WHLVwsogzXg16UF" />}
					title={
						<>
							<span style={{ paddingRight: 10, fontWeight: 'bold' }}>Team Discussion -</span>
							{project.title + ', ' + notebook.title}
						</>
					}
				/>
				{discussion?.map((discuss, i) => {
					const stepAndProcessTitle = getProcessTitles(props.steps, discuss.processID);
					return (
						<Paper key={i} elevation={1} style={{ margin: '15px 0', padding: 5 }}>
							<div
								style={{
									fontSize: 11,
									fontWeight: 'normal',
									fontStyle: 'italic',
								}}
							>
								<span
									onClick={() => handleClick(discuss.entryID)}
									style={{
										color: '#b72b2e',
										paddingRight: 8,
										fontWeight: 'bold',
										cursor: 'pointer',
									}}
								>
									{/* <Link
                  to={`/user/notebook/${props.projectID}/${props.notebookID}/${discuss.entryID}/`}
                > */}
									{discuss.entryNo && `ENTRY #${discuss.entryNo}, `}
									{stepAndProcessTitle[0].processTitle.toUpperCase()}
									{/* </Link> */}
								</span>
								{`${discuss.studentName} wrote on ${new Date(discuss.createdDT.seconds * 1000).toDateString()}:`}
							</div>
							<div style={{ fontSize: 12 }}>{`${discuss.comment}`}</div>
						</Paper>
					);
				})}
			</>
		);
	};

	return (
		<div className="Notebook">
			{spinner ? <Spinner /> : displayDiscussion()}
			{/* <pre>{JSON.stringify(entry, 0, 3)}</pre> */}
			{/* <pre>{JSON.stringify(project, 0, 3)}</pre> */}
			{/* <pre>{JSON.stringify(notebook, 0, 3)}</pre> */}
			{/* <pre>{JSON.stringify(discussion, 0, 3)}</pre> */}
			{/* <pre>{JSON.stringify(props.steps, 0, 3)}</pre> */}
		</div>
	);
};

export default Notebook;
