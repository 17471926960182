import * as React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

function createData(name, origPrice, realPrice) {
	return { name, origPrice, realPrice };
}

const rows = [
	createData('1-10 EDEN', '$24.99 each', 'Discounted to $12.99 each'),
	createData('11-25 EDEN', '$19.99 each', 'Discounted to $11.99 each'),
	createData('26-50 EDEN', '$14.99 each', 'Discounted to $10.99 each'),
	createData('51-100 EDEN', '$11.99 each', 'Discounted to $9.99 each'),
	createData('200+ EDEN', '', 'Call For Pricing'),
];

export default function DenseTable(props) {
	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
				<TableHead>
					<TableRow>
						<TableCell>Notebook Quantity</TableCell>
						<TableCell align="right">Price per Notebook</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row, i) => {
						let selBG = { background: '#ffffff' };
						let selCol = { color: '#777777' };
						if (i === props.selIndex) {
							selBG = { background: '#defdff' };
							selCol = { color: '#002cc9', fontWeight: 'bold' };
						}
						return (
							<TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={selBG}>
								<TableCell component="th" scope="row" style={selCol}>
									{row.name}
								</TableCell>
								<TableCell align="right" style={selCol}>
									<span style={{ paddingRight: 18 }}>
										<del>{row.origPrice}</del>
									</span>
									{row.realPrice}
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
