import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import appSettings from "../../appSettings";

const CustCheckbox = withStyles({
  root: {
    color: "#AAAAAA",
    "&$checked": {
      color: appSettings.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function CheckboxLabels(props) {
  const [state, setState] = React.useState({
    checkedG: true,
  });

  const handleChange = (event) => {
    props.onChange({ [event.target.name]: event.target.checked });
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <FormControlLabel
      control={
        <CustCheckbox
          checked={props.checked}
          onChange={handleChange}
          name={props.name}
          required={props.required}
        />
      }
      label={props.label}
    />
  );
}
