import path from 'path';
import { storage } from '../../Auth/base';
import { resizeFile, dataURIToBlob } from '../../helpers';
import { updateNotebookSize } from '../../global/globalFunctions';
class UploadAdapter {
	constructor(loader, teacherDocID, notebookID) {
		this.loader = loader;
		this.teacherDocID = teacherDocID;
		this.notebookID = notebookID ? notebookID : 'admin';
	}

	upload() {
		return this.loader.file.then(
			(file) =>
				new Promise((resolve, reject) => {
					// console.log("file", file);
					const rewrFileName = this.loader.id;
					const fileExt = path.extname(file.name);
					const newFileName = rewrFileName + fileExt;
					const storageRef = storage.ref().child(`images/${this.teacherDocID}/${this.notebookID}`);

					// console.log('Original Image size in MB: ', file.size / 1024 / 1024, ' MB');

					resizeFile(file)
						.then((res) => {
							const newFile = dataURIToBlob(res);
							// console.log('Compressed File size in MB: ', newFile.size * 0.000001);
							const newFileSize = newFile.size * 0.000001;
							if (this.notebookID) {
								updateNotebookSize(this.teacherDocID, this.notebookID, newFileSize);
							}
							const uploadTask = storageRef.child(newFileName).put(newFile);

							uploadTask.on(
								'state_changed',
								function (snapshot) {
									// const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
									// console.log('Upload is ' + progress + '% done');
									switch (snapshot.state) {
										case 'paused':
											// console.log('Upload is paused');
											break;
										case 'running':
											// console.log('Upload is running');
											break;
										default:
											return;
									}
								},
								function (error) {
									// eslint-disable-next-line default-case
									switch (error.code) {
										case 'storage/unauthorized':
											reject("User doesn't have permission to access the object");
											break;

										case 'storage/canceled':
											reject('User canceled the upload');
											break;

										case 'storage/unknown':
											reject('Unknown error occurred, inspect error.serverResponse');
											break;
									}
								},
								function () {
									uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
										// console.log('File size is', newFileSize);
										resolve({
											default: downloadURL,
										});
									});
								}
							);
						})
						.catch((e) => console.log('resizeFile.e', e));
				})
		);
	}
}

export default UploadAdapter;
