export const accountSettings = {
	appName: 'Engineering Design Electronic Notebook (EDEN) Version 1.01',
	// themeColor: "#b72b2e",
	themeColor: '#004AAD',
	// secondaryColor: "#3076ad",
	themeColor2: '#CCCCCC',
	secondaryColor: '#42cef5',

	appbarColor: '#ffffff',
	// appbarBG: "#b72b2e",
	// themeColor: "#0078e8",
	// themeColor2: "#ff2400",
	// appbarColor: "#FFFFFF",
	// appbarBG: "#0078e8",
	appbarBG: '#004AAD',
	edpColors: ['red', 'blue', 'green'],
};

export default accountSettings;
