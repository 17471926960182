import React from 'react';
import ReactPlayer from 'react-player';

// gs://eden-73426.appspot.com/assets/EDEN_Overview.mp4

const Video = (props) => {
	return (
		<div>
			<ReactPlayer controls={props.controls} width={props.width} url={props.url} />
		</div>
	);
};

export default Video;
