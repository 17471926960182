import React, { useState, useEffect } from "react";
import Header from "../../UI/Header/Header";
import Spinner from "../../UI/Spinner/Spinner";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { getFeedback } from "../adminFunctions";

const Feedback = (props) => {
  const [spinner, setSpinner] = useState(true);
  const [feedbackArray, setFeedbackArray] = useState([]);

  useEffect(() => {
    const initFeedback = async () => {
      try {
        const response = await getFeedback();

        setFeedbackArray(response);
        setSpinner(false);
      } catch (error) {
        setSpinner(false);
        console.log("initFeedback catch error", error);
      }
    };
    initFeedback();
  }, []);

  const returnVal = (
    <>
      <Header
        title={
          <>
            <span
              style={{
                color: "maroon",
                fontWeight: "500",
                cursor: "pointer",
              }}
              onClick={() => props.acctClicked()}
            >
              Admin
            </span>
            <PlayArrowIcon
              style={{
                fontSize: 12,
                margin: "0px 7px",
                color: "#888",
              }}
            />
            <span>Feedback</span>
          </>
        }
      />
      {feedbackArray.map((f, i) => {
        return (
          <div key={i}>
            <div>
              <strong>User: &nbsp;</strong>
              {f.userID}
            </div>
            <div>
              <strong>Date: &nbsp;</strong>
              {new Date(f.createdDT * 1000).toDateString()}{" "}
              {new Date(f.createdDT * 1000).toLocaleTimeString()}
            </div>
            <div>
              <strong>Feedback: &nbsp;</strong>
              {f.feedback}
            </div>
            <hr />
          </div>
        );
      })}
      {/* <pre>{JSON.stringify(feedbackArray, 0, 3)}</pre> */}
    </>
  );

  return <>{spinner ? <Spinner /> : returnVal}</>;
};

export default Feedback;
