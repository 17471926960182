import React from "react";
import HomeStudent from "../../HomeStudent";
import DisplayFAQ from "../../Pages/Help/DisplayFAQ";

const HelpFAQ = () => {
  return (
    // <HomeStudent>
    //   <div>Home</div>
    // </HomeStudent>

    <HomeStudent>
      <DisplayFAQ type="2" />
    </HomeStudent>
  );
};

export default HelpFAQ;
