import React, { useState, useEffect } from 'react';
import Textbox from '../../UI/Forms/Textbox';
import Button from '../../UI/Forms/Button';
import Dropdown from '../../UI/Forms/Dropdown';
import Switch from '../../UI/Forms/Switch';
import ClassicEditor from '../../CKEditor';

const AddEditHelp = (props) => {
	const [helpData, setHelpData] = useState({
		id: props.data ? props.data[0].id : '',
		title: props.data ? props.data[0].title : '',
		summary: props.data ? props.data[0].summary : '',
		category: props.data ? props.data[0].category : '',
		subcategory: props.data ? props.data[0].subcategory : '',
		seq: props.data ? props.data[0].seq : null,
		html: props.data ? props.data[0].html : '',
		isFAQ: props.data ? props.data[0].isFAQ : false,
		videoUrl: props.data ? props.data[0].videoUrl : '',
		type: props.type,
	});

	useEffect(() => {}, []);

	const textChange = (name, val) => {
		setHelpData({ ...helpData, [name]: val });
	};

	return (
		<>
			{/* <Dropdown /> */}
			<form
				autoComplete="off"
				onSubmit={(event) => {
					event.preventDefault();
					props.handleSave(helpData);
				}}
			>
				{helpData.id && (
					<div style={{ marginBottom: 15 }}>
						Permalink:{' '}
						<a href={`${window.location.origin}/p/${helpData.id}`} target="_blank" rel="noopener noreferrer">
							{`${window.location.origin}/p/${helpData.id}`}
						</a>
					</div>
				)}
				<div>
					<Textbox
						id="id-title"
						name="title"
						type="text"
						width="96%"
						required={true}
						label="Help File Title"
						value={helpData.title}
						textChange={(name, val) => textChange(name, val)}
					/>
				</div>
				<div>
					<Textbox
						id="id-summary"
						name="summary"
						type="text"
						width="96%"
						required={true}
						label="Summary"
						value={helpData.summary}
						textChange={(name, val) => textChange(name, val)}
					/>
				</div>
				<div>
					<Dropdown
						options={[{ id: '', title: '' }, ...props.options]}
						label="Type"
						width="98%"
						value={helpData.category}
						name="category"
						id="idCategory"
						required={true}
						getOptionSelected={(option, value) => {
							// console.log(option, value);
						}}
						handleChange={(val) => {
							textChange('category', val);
						}}
					/>
				</div>
				<div>
					<Dropdown
						options={[{ id: '', title: '' }, ...props.catOptions]}
						label="Category"
						width="98%"
						value={helpData.subcategory}
						name="subcategory"
						id="idSubCategory"
						required={true}
						getOptionSelected={(option, value) => {
							// console.log(option, value);
						}}
						handleChange={(val) => {
							textChange('subcategory', val);
						}}
					/>
				</div>
				<div>
					<Textbox
						id="id-seq"
						name="seq"
						type="number"
						width="10%"
						required={true}
						label="Sequence"
						value={helpData.seq}
						textChange={(name, val) => textChange(name, val)}
					/>
				</div>
				<div style={{ marginTop: 15 }}>
					<Switch
						checked={helpData.isFAQ}
						onChange={(event) => {
							// p.isActive = event.target.checked;
							const FAQchange = event.target.checked;
							setHelpData({
								...helpData,
								isFAQ: FAQchange,
							});
						}}
						id="id-isfaq"
						name="isfaq"
						label={'Add to Frequently Asked Questions'}
					/>
				</div>
				<div>
					<Textbox
						id="id-videoUrl"
						name="videoUrl"
						type="text"
						width="96%"
						required={false}
						label="Video URL (optional)"
						value={helpData.videoUrl}
						textChange={(name, val) => textChange(name, val)}
					/>
				</div>
				<div style={{ marginTop: 15 }}>
					<ClassicEditor
						data={helpData.html}
						onChange={(html) => textChange('html', html)}
						placeholder={'Enter Help File Info'}
						teacherDocID="admin"
					/>
				</div>
				<Button
					type="submit"
					id="btn-save"
					name="btn-save"
					// buttonClicked={props.handleClose}
				>
					Save
				</Button>
			</form>
			{/* <pre>{JSON.stringify(helpData, 0, 3)}</pre> */}
		</>
	);
};

export default AddEditHelp;
