import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Logo from '../img/nav-logo.png';
import appSettings from '../appSettings';
import { Link } from 'react-router-dom';
// import { ROUTES } from "../global/constants";
import { useAppContext } from '../context';

const useStyles = makeStyles((theme) => ({
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: (props) => props.drawerWidth,
			flexShrink: 0,
		},
	},
	heading: {
		color: appSettings.themeColor,
		paddingLeft: 10,
		fontSize: 14,
		padding: 5,
		backgroundColor: '#F5F5F5',
		borderBottom: '1px solid #DDD',
		borderTop: '1px solid #DDD',
	},
	listItem: {
		padding: '5px 0 5px 0',
	},
	listItemText: {
		padding: '1px 0 1px 14px',
		fontSize: 14,
	},
	listItemIcon: {
		color: appSettings.themeColor,
	},
	drawerPaper: {
		width: (props) => props.drawerWidth,
	},
	toolbar: {
		textAlign: 'center',
		paddingTop: 17,
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
}));

const DrawerExport = (props) => {
	const { window, drawerWidth } = props;
	const { mobileDrawerOpen, toggleDrawer } = useAppContext();
	const classes = useStyles({ drawerWidth });
	const theme = useTheme();

	const drawer = (
		<div>
			<div className={classes.toolbar}>
				<img src={Logo} alt="logo" height="45" />
			</div>
			<Divider />
			{/* <List> */}
			{/* <ListItem
          button
          component={Link}
          to={ROUTES.users.projects}
          // className={classes.listItem}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <MenuBook />
          </ListItemIcon>
          <ListItemText primary="My Projects" />
        </ListItem> */}

			{/* </List> */}
			<List>
				{props.type !== 'open' ? (
					<ListItem button component={Link} to={`/teacher/project/${props.projectID}`} className={classes.listItem}>
						{/* <ListItemIcon className={classes.listItemIcon}>
            <ArrowBackIcon />
          </ListItemIcon> */}

						<div className={classes.listItemText}>
							<span style={{ color: appSettings.themeColor, fontSize: 18 }}>
								<strong>{'<'} </strong>
							</span>{' '}
							Back To Project
						</div>
					</ListItem>
				) : (
					''
				)}

				{/* <ListItem
          button
          component={Link}
          to={`/teacher/notebook/${props.notebookID}`}
          className={classes.listItem}
        >
          <div className={classes.listItemText}>Overview</div>
        </ListItem> */}
				<ListItem
					button
					// component={Link}
					// to={`/user/notebook/${props.projectID}/${props.notebookID}`}
					onClick={() => props.passProcessID('all')}
					className={classes.listItem}
				>
					<div className={classes.listItemText}>View Notebook</div>
				</ListItem>

				<ListItem button onClick={() => props.passProcessID('discussion')} className={classes.listItem}>
					<div className={classes.listItemText}>Team Discussion</div>
				</ListItem>

				{props.type !== 'open' ? (
					<ListItem button component={Link} to={`/teacher/help`} className={classes.listItem}>
						<div className={classes.listItemText}>Help/FAQ</div>
					</ListItem>
				) : (
					''
				)}

				{/* <pre>{JSON.stringify(props, 0, 3)}</pre> */}

				{props.steps.map((s, i) => {
					return (
						<div key={i}>
							<div className={classes.heading}>{s.title}</div>
							{s.process.map((p, index) => {
								return (
									<ListItem
										key={index}
										button
										// component={Link}
										// to={`/teacher/notebook/${props.projectID}/${props.notebookID}/${p.id}`}
										onClick={() => props.passProcessID(p.id)}
										// to={ROUTES.users.notebookstep.replace(":processid", p.id)}
										className={classes.listItem}
									>
										{/* <li value={p.seq} className={classes.listItemText}>
                        {p.title}
                      </li> */}
										<div className={classes.listItemText}>
											{p.seq}. {p.title}
										</div>
									</ListItem>
								);
							})}
						</div>
					);
				})}
				{/* <ListItem
          button
          className={classes.listItem}
          onClick={props.scoringClick}
        >
          <div className={classes.listItemText}>Notebook Scores</div>
        </ListItem> */}
			</List>
		</div>
	);

	const container = window !== undefined ? () => window().document.body : undefined;

	return (
		<nav className={classes.drawer} aria-label="mailbox folders">
			<Hidden smUp implementation="css">
				<Drawer
					container={container}
					variant="temporary"
					anchor={theme.direction === 'rtl' ? 'right' : 'left'}
					open={mobileDrawerOpen}
					onClick={toggleDrawer}
					onClose={toggleDrawer}
					classes={{
						paper: classes.drawerPaper,
					}}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
				>
					{drawer}
				</Drawer>
			</Hidden>
			<Hidden xsDown implementation="css">
				<Drawer
					classes={{
						paper: classes.drawerPaper,
					}}
					variant="permanent"
					open
				>
					{drawer}
				</Drawer>
			</Hidden>
		</nav>
	);
};

export default DrawerExport;
