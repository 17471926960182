import React, { useState, useEffect } from 'react';
import Home from '../../Home';
import HomeStudent from '../../HomeStudent';
import Spinner from '../../UI/Spinner/Spinner';
import appBase from '../../Auth/base';
import { getDocID } from '../../global/globalFunctions';
import Textbox from '../../UI/Forms/Textbox';
import Button from '../../UI/Forms/Button';
import Switch from '../../UI/Forms/Switch';
import Alert from '../../UI/Alerts/Alert';
import Help from '../../UI/Modals/Help';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../global/constants';
import TextField from '@material-ui/core/TextField';
import appSettings from '../../appSettings';
import axios from 'axios';
import { useAuthContext } from '../../context';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <div>{children}</div>}
		</div>
	);
}
function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const Student = (props) => {
	const { teacherId, userType } = useAuthContext();

	const [spinner, setSpinner] = useState(true);
	const [studentID] = useState(props.match.params.id);
	const [teacherDocId, setTeacherDocId] = useState(false);
	const [student, setStudent] = useState(false);
	const [errAlert, setErrAlert] = useState({
		show: false,
		msg: '',
		type: 'error',
	});
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [email, setEmail] = useState('');
	const [confirmEmail, setConfirmEmail] = useState('');

	const saveProfileHandler = (event) => {
		event.preventDefault();

		const { active, first, last, email, school, isMentor } = event.target.elements;
		// console.log("current email", user[0].emailCurrent);
		const updatedStudent = {
			active: active.checked,
			first: first.value,
			last: last.value,
			email: email.value,
			school: school.value,
			isMentor: isMentor.checked,
		};

		setStudent(updatedStudent);
		appBase
			.firestore()
			.collection('users')
			.doc(teacherDocId)
			.collection('students')
			.doc(studentID)
			.update(updatedStudent)
			.then(() => {
				setErrAlert({
					show: true,
					msg: 'Profile successfully changed!',
					type: 'success',
				});
			})
			.catch((error) => {
				// console.log("error in user profile update", error);
				setErrAlert({
					show: true,
					msg: error.message,
					type: 'error',
				});
			});
	};

	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	useEffect(() => {
		const getStudent = (response) => {
			return new Promise((resolve, reject) => {
				appBase
					.firestore()
					.collection('users')
					.doc(response)
					.collection('students')
					.doc(studentID)
					.get()
					.then((snapshot) => {
						const getStudent = {
							active: snapshot.data().active,
							first: snapshot.data().first,
							last: snapshot.data().last,
							email: snapshot.data().email,
							school: snapshot.data().school,
							isMentor: snapshot.data().isMentor ? true : false,
						};

						resolve(getStudent);
					})
					.catch((error) => {
						reject(error);
					});
			});
		};

		const getUIDandStudent = async () => {
			try {
				let procGetDocId = teacherId;
				if (userType !== 'mentor') {
					procGetDocId = await getDocID();
				}
				setTeacherDocId(procGetDocId);
				const procGetStudent = await getStudent(procGetDocId);
				setStudent(procGetStudent);
				setSpinner(false);
			} catch (error) {
				console.log('async error', error);
				setSpinner(false);
			}
		};
		getUIDandStudent();
	}, [studentID, teacherId, userType]);

	const handleResetPassword = async (e) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			setErrAlert({
				show: true,
				msg: "Passwords don't match!",
				type: 'error',
			});
			return;
		}

		try {
			const res = await axios.post('/users/change-student-password', {
				userId: studentID,
				newPassword: password,
			});
			if (res.status === 200) {
				setErrAlert({
					show: true,
					msg: res.data.message || 'Password updated successfully!',
					type: 'success',
				});
			}
		} catch (err) {
			console.log('err: ', err);
			setErrAlert({ show: true, msg: 'Password must be 6 characters or longer', type: 'error' });
		}
	};

	const handleChangeEmail = async (e) => {
		setSpinner(true);
		e.preventDefault();
		if (email !== confirmEmail) {
			setErrAlert({
				show: true,
				msg: "Emails don't match!",
				type: 'error',
			});
			return;
		}

		try {
			const res = await axios.post('/users/change-student-email', {
				studentId: studentID,
				teacherId: teacherDocId,
				newEmail: email,
			});
			if (res.status === 200) {
				setErrAlert({
					show: true,
					msg: res.data.message || 'Email updated successfully!',
					type: 'success',
				});
			}
			setSpinner(false);
		} catch (error) {
			setSpinner(false);
			setErrAlert({ show: true, msg: error.message, type: 'error' });
		}
	};

	const useStyles = makeStyles((theme) => ({
		root: {
			display: 'flex',
			width: '100%',
		},
		tabs: {
			flexGrow: 1,
			backgroundColor: theme.palette.background.paper,
			margin: 10,
		},
		toolbar: theme.mixins.toolbar,
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
		},
		form: {
			'& .MuiTextField-root': {
				margin: theme.spacing(1),
				maxWidth: props.maxWidth,
				width: '96%',
				borderColor: appSettings.themeColor,
			},
			'& .MuiInput-underline:after': {
				borderBottomColor: appSettings.themeColor,
			},
			'& label.Mui-focused': {
				color: appSettings.themeColor,
			},

			'& .MuiOutlinedInput-root': {
				'& fieldset': {
					borderColor: appSettings.themeColor,
				},
				'&:hover fieldset': {
					borderColor: appSettings.themeColor,
				},
				'&.Mui-focused fieldset': {
					borderColor: appSettings.themeColor,
				},
			},
		},
	}));
	const classes = useStyles();

	let returnVal = <Spinner />;
	if (!spinner) {
		returnVal = (
			<div className={classes.tabs}>
				<AppBar position="static" color="default">
					<Tabs value={value} onChange={handleChange} aria-label="profile tabs">
						<Tab label="Profile" {...a11yProps(0)} />
						<Tab label="Reset Password" {...a11yProps(1)} />
						{/* <Tab label="E-mail" {...a11yProps(2)} /> */}
					</Tabs>
				</AppBar>
				<Alert
					showAlert={errAlert.show}
					close={() => {
						setErrAlert({ show: false, msg: '', type: 'error' });
					}}
					type={errAlert.type}
				>
					{errAlert.msg}
				</Alert>
				<TabPanel value={value} index={0}>
					<Card style={{ padding: 20 }}>
						<form autoComplete="off" onSubmit={saveProfileHandler}>
							<div>
								<Textbox
									id="id-first"
									name="first"
									type="text"
									width="96%"
									required={true}
									label="First Name"
									value={student.first}
								/>
							</div>
							<div>
								<Textbox
									id="id-last"
									name="last"
									type="text"
									width="96%"
									required={true}
									label="Last Name"
									value={student.last}
								/>
							</div>
							<div>
								<Textbox
									id="id-school"
									name="school"
									type="text"
									width="96%"
									required={true}
									label="School"
									value={student.school}
								/>
							</div>
							<div>
								<Textbox
									id="id-email"
									name="email"
									type="email"
									width="96%"
									label="E-mail Address"
									value={student.email}
									disabled={true}
								/>
							</div>
							<div style={{ marginBottom: 15 }}>
								<Switch
									checked={student.active}
									onChange={(event) => {
										setStudent({
											...student,
											[event.target.name]: event.target.checked,
										});
									}}
									id="id-active"
									name="active"
									type="text"
									width="96%"
									required={true}
									label="Team Member Active"
								/>
							</div>
							<div style={{ marginBottom: 15 }}>
								<Switch
									checked={student.isMentor}
									onChange={(event) => {
										setStudent({
											...student,
											[event.target.name]: event.target.checked,
										});
									}}
									id="id-isMentor"
									name="isMentor"
									type="text"
									width="96%"
									required={true}
									label={
										<>
											<Help helpId="Es368cZiw1WhxjjGz0nr" preText="Make Mentor" />
										</>
									}
								/>
							</div>
							<div>
								<Button type="submit" buttonType="save">
									Save
								</Button>
								<Link to={userType === 'mentor' ? ROUTES.users.members : ROUTES.teachers.members}>
									<Button>Return to Team Members</Button>
								</Link>
							</div>
						</form>
					</Card>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<Card style={{ padding: 20 }}>
						<form className={classes.form} onSubmit={handleResetPassword}>
							<div>
								<TextField
									type="password"
									name="password"
									label="New Password"
									onChange={(e) => setPassword(e.target.value)}
									required
								/>
							</div>
							<div>
								<TextField
									type="password"
									name="confirm-password"
									label="Repeat Password"
									onChange={(e) => setConfirmPassword(e.target.value)}
									required
								/>
							</div>
							<div>
								<Button type="submit">Change Password</Button>
							</div>
						</form>
					</Card>
				</TabPanel>
				<TabPanel value={value} index={2}>
					<Card style={{ padding: 20 }}>
						<form className={classes.form} onSubmit={handleChangeEmail}>
							<div>
								<TextField
									type="email"
									name="email"
									label="E-mail Address"
									onChange={(e) => setEmail(e.target.value)}
									defaultValue={student.email}
									required
								/>
							</div>
							<div>
								<TextField
									type="email"
									name="confirm-email"
									label="Repeat E-mail Address"
									onChange={(e) => setConfirmEmail(e.target.value)}
									defaultValue={student.email}
									required
								/>
							</div>
							<div>
								<Button type="submit">Change E-mail</Button>
							</div>
						</form>
					</Card>
				</TabPanel>
			</div>
		);
	}
	if (userType === 'mentor') {
		return <HomeStudent>{returnVal}</HomeStudent>;
	} else {
		return <Home>{returnVal}</Home>;
	}
};

export default Student;
