import React, { useState, useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  // PDFViewer,
  PDFDownloadLink,
  //   ReactPDF,
} from "@react-pdf/renderer";
import W9 from "../../assets/HeatCorpSTEM_W-9.pdf";

// Create styles
const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    // backgroundColor: "#E4E4E4",
    padding: 50,
  },
  section: {
    marginBottom: 10,
    // padding: 10,
    // flexGrow: 1,
  },
  header: {
    // fontSize: 12,
    fontSize: 18,
    marginBottom: 15,
    color: "#888888",
  },
  textHeading: {
    marginLeft: 0,
    fontSize: 10,
    marginBottom: 5,
  },
  text: {
    // fontSize: 12,
    marginLeft: 8,
    fontSize: 10,
    marginBottom: 5,
  },
  button: {
    color: "white",
    textDecoration: "none",
    backgroundColor: "#B72B2E",
    padding: "8px 10px",
    marginRight: "5px",
    borderRadius: 3,
    fontWeight: "bold",
    fontSize: 12,
  },

  table: {
    width: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    fontSize: 10,
  },
  cellDesc: {
    borderBottom: "1px solid #CCCCCC",
    padding: "2px 5px",
    width: "55%",
    textAlign: "left",
  },
  cell: {
    borderBottom: "1px solid #CCCCCC",
    padding: "2px 5px",
    width: "15%",
    textAlign: "right",
  },
});

// Create Document Component

export const Invoice = (props) => {
  // console.log(props.poData);
  const [invoiceNo, setInvoiceNo] = useState("");
  useEffect(() => {
    // const newDate = new Date();
    // const newDate = props.poData.purchaseDT.seconds;
    const newDate = new Date(props.poData.purchaseDT.seconds * 1000);
    let day = newDate.getDate();
    if (day < 10) day = "0" + day;
    let month = newDate.getMonth() + 1;
    if (month < 10) month = "0" + month;
    let year = newDate.getFullYear();
    const invNo = `${props.poData.teacherDocID.substring(0, 3).toUpperCase()}-${
      props.poData.purchaseDT.seconds
    }`;

    setInvoiceNo({
      date: newDate,
      invNo: invNo,
      day: day,
      month: month,
      year: year,
    });
  }, [props.poData.teacherDocID, props.poData.purchaseDT]);
  //   console.log(props);
  const myDocument = () => {
    return (
      <Document>
        <Page size="LETTER" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.header}>INVOICE # {invoiceNo.invNo}</Text>
            <Text style={styles.textHeading}>
              DATE OF INVOICE:{" "}
              {`${invoiceNo.month}/${invoiceNo.day}/${invoiceNo.year}`}
            </Text>
            <Text style={styles.textHeading}>
              PURCHASE ORDER NUMBER:{" "}
              {props.poData.poNumber.length > 0 ? props.poData.poNumber : "TBD"}
            </Text>
            <Text style={styles.textHeading}>
              TOTAL COST: {`${props.poData.amount.toFixed(2).toLocaleString()}`}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.textHeading}>ISSUED BY</Text>
            <Text style={styles.text}>Heat Corp</Text>
            <Text style={styles.text}>
              Mailing Address: PO Box 60902, Palm Bay, FL 32906
            </Text>
            <Text style={styles.text}>EIN/TIN: 84-3193684</Text>
            <Text style={styles.text}>Vendor ID: TBD</Text>
            <Text style={styles.text}>Sales Contact: Matt Conroy</Text>
            <Text style={styles.text}>Phone: +1 (321) 257-8263</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.textHeading}>REQUESTER</Text>
            <Text style={styles.text}>Organization: {props.poData.poOrg}</Text>
            <Text style={styles.text}>
              Address:{" "}
              {`${props.poData.poStreet1} ${props.poData.poStreet2}, ${props.poData.poCity}, ${props.poData.poState} ${props.poData.poZip}`}
            </Text>
            <Text style={styles.text}>Contact: {props.poData.poRequester}</Text>
            <Text style={styles.text}>
              Phone: {`${props.poData.poRequesterPhone}`}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={[styles.row]}>
              <Text style={styles.cellDesc}>Description</Text>
              <Text style={styles.cell}>Quantity</Text>
              <Text style={styles.cell}>Price/Qty</Text>
              <Text style={styles.cell}>Total Cost</Text>
            </View>
            <View style={[styles.row]}>
              <Text style={styles.cellDesc}>
                Heat Corp Notebook Qty Purchase
              </Text>
              <Text style={styles.cell}>{props.poData.poQuantity}</Text>
              <Text style={styles.cell}>${props.poData.pricePer}</Text>
              <Text style={styles.cell}>
                ${props.poData.amount.toFixed(2).toLocaleString()}
              </Text>
            </View>
            <View style={{ marginTop: 45 }}>
              <Text style={styles.textHeading}>
                FOR CHECK ISSUANCE, PLEASE REMIT PAYMENT TO:
              </Text>
              <Text style={styles.text}>Heat Corp</Text>
              <Text style={styles.text}>PO Box 60902</Text>
              <Text style={styles.text}>Palm Bay, FL, 32906</Text>
            </View>
            {/* <View style={{ marginTop: 15 }}>
              <Text style={styles.textHeading}>
                FOR WIRE TRANSFERS, PLEASE REMIT PAYMENT TO:
              </Text>
              <Text style={styles.text}>Heat Corp STEM</Text>
              <Text style={styles.text}>Bank Name: ****</Text>
              <Text style={styles.text}>Account Number: ****</Text>
              <Text style={styles.text}>Routing Number: ****</Text>
            </View> */}
          </View>
        </Page>
      </Document>
    );
  };
  //   ReactPDF.renderToStream(<MyDocument />);
  return (
    <>
      {/* <PDFViewer style={{ width: "100%", height: 150 }}>
        {myDocument()}
      </PDFViewer> */}
      <div>
        <PDFDownloadLink
          document={myDocument()}
          fileName={`Heat_corp_STEM_Invoice_${invoiceNo}.pdf`}
          style={styles.button}
        >
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "DOWNLOAD INVOICE"
          }
        </PDFDownloadLink>
        <a
          style={styles.button}
          href={W9}
          target="_blank"
          rel="noopener noreferrer"
        >
          DOWNLOAD FORM W-9
        </a>
      </div>
    </>
  );
};

export default Invoice;
