import React, { useState, useEffect } from 'react';
import Home from '../../Home';
import Video from '../../UI/Video/Video';
import { getHelpFile } from '../../global/globalFunctions';
import Container from '@material-ui/core/Container';
import ReactHtmlParser from 'react-html-parser';

const Permalink = (props) => {
	// const [params] = useState({ id: props.match.params.id });
	const [file, setFile] = useState([]);

	useEffect(() => {
		const getFile = async () => {
			try {
				const proc = await getHelpFile(props.match.params.id);
				setFile(proc);
			} catch (err) {
				console.log(err);
			}
		};
		getFile();
	}, [props.match.params.id]);

	const display = () => {
		return (
			<Container maxWidth="lg">
				<div>
					<h3>{file.title}</h3>
					{ReactHtmlParser(file.html)}
				</div>
				{file.videoUrl && (
					<div style={{ minWidth: 300, maxWidth: 500 }}>
						<Video controls={true} width="100%" url={file.videoUrl} />
					</div>
				)}
			</Container>
		);
	};
	return (
		<>
			<Home drawerWidth={0}>{display()}</Home>
			{/* <pre>{JSON.stringify(file, 0, 3)}</pre> */}
		</>
	);
};

export default Permalink;
