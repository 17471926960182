//TO DO: MUST INSERT STEPS AND PROCESSES FROM ADMIN steps INTO each new created PROJECT
import React, { useState, useEffect } from 'react';
import Home from '../../Home';
import HomeStudent from '../../HomeStudent';
import appBase from '../../Auth/base';
import {
	getDocID,
	getTeacherProfile,
	// toTitleCase,
	getProjectsLevel1,
	getProjectsLevel2,
	addNewProject,
	getStepsAndProcesses,
	addStepsAndProcesses,
	getAndSetProjectScoring,
	projectNumberIsUnique,
	seqToLetter,
} from '../../global/globalFunctions';
import Button from '../../UI/Forms/Button';
import Modal from '../../UI/Modals/RegularModal';
import Spinner from '../../UI/Spinner/Spinner';
import Textbox from '../../UI/Forms/Textbox';
import Alert from '../../UI/Alerts/Alert';
import Header from '../../UI/Header/Header';
import Help from '../../UI/Modals/Help';
import AddEditProjects from './AddEditProjects';
import ProjectsTable from './ProjectsTable';
import { useAuthContext } from '../../context';

const Projects = () => {
	//USE STATE
	const { teacherId, userType } = useAuthContext();
	const [spinner, setSpinner] = useState(true);
	const [projectList, setProjectList] = useState([]);
	const [projectListDisplay, setProjectListDisplay] = useState([]);
	const [modalNew, setModalNew] = useState({
		show: false,
	});
	const closeModalNew = () => {
		setModalNew({ show: false });
	};
	const [teacherDocID, setTeacherDocID] = useState(false);
	const [projectNumber, setProjectNumber] = useState(null);
	const [projectDropdown, setProjectDropdown] = useState([]);
	const [projectDropdown2, setProjectDropdown2] = useState([]);
	const [showAlert, setShowAlert] = useState(false);

	const [errAlert, setErrAlert] = useState({
		show: false,
		msg: '',
		type: 'error',
	});

	function typeTitleByID(array, id) {
		return array.find((element) => {
			return element.id === id;
		});
	}

	//MOUNT COMPONENT
	useEffect(() => {
		const getProjects = (response) => {
			// console.log("seq", seqToLetter(18277));
			return new Promise((resolve, reject) => {
				const query = appBase.firestore().collection('users').doc(response);

				query
					.collection('projects')
					.orderBy('createdDT', 'desc')
					.get()
					.then((snapshot) => {
						const getProjects = snapshot.docs.map((doc) => {
							return {
								...doc.data(),

								id: doc.id,
								createdDT: doc.data().createdDT.seconds,
								// notebookCt: nbCt(response, doc.id),
							};
						});
						resolve(getProjects);
					})
					.catch((error) => {
						reject(error);
					});
			});
		};

		const getProjectsWithTypeTitle = (projects, type1, type2, projNumber) => {
			return new Promise((resolve) => {
				let members = projects.map((project) => {
					const typeDisplay1 = typeTitleByID(type1, project.type1).title;
					const typeDisplay2 = project.type2 ? ' - ' + typeTitleByID(type2, project.type2).title : '';
					const typeDisplay = typeDisplay1 + typeDisplay2;
					return {
						id: project.id,
						title: project.title,
						type: typeDisplay,
						createdDT: project.createdDT,
						projectNumber: projNumber + project.letter,
						tableType: 'teacher',
					};
				});
				// resolve(members);

				resolve(members);
			});
		};

		// RUN PROMISES
		const getUIDandProjects = async () => {
			try {
				let procGetDocId = teacherId;
				if (userType !== 'mentor') {
					procGetDocId = await getDocID();
				}
				setTeacherDocID(procGetDocId);
				const teacherProfile = await getTeacherProfile(procGetDocId);
				setProjectNumber(teacherProfile.number);
				const processedResponse = await getProjects(procGetDocId);
				// console.log(processedResponse);
				const processedLevel1 = await getProjectsLevel1();
				setProjectDropdown(processedLevel1);
				const processedLevel2 = await getProjectsLevel2(processedLevel1);
				setProjectDropdown2(processedLevel2);
				// console.log(processedLevel2);
				const typeTitle = await getProjectsWithTypeTitle(
					processedResponse,
					processedLevel1,
					processedLevel2,
					teacherProfile.number
				);
				setProjectList(typeTitle);
				setProjectListDisplay(typeTitle);
				setSpinner(false);
			} catch (error) {
				setSpinner(false);
				console.log('getUIDandProjects catch error', error);
			}
		};
		getUIDandProjects();
		// console.log("mounted");
		return () => {
			// console.log("unmounted");
		};
	}, [teacherId, userType]);

	const saveProfileHandler = (event) => {
		event.preventDefault();

		const saveProfileForm = async () => {
			try {
				const { number } = event.target.elements;
				const projNumber = parseInt(number.value);
				let errorMsg = null;
				if (!projNumber || projNumber < 100 || projNumber > 99999) {
					errorMsg = 'Please enter a unique School/Organization Number between 100 and 99999.';
				}
				const numIsUnique = await projectNumberIsUnique(teacherDocID, number.value);
				// console.log(IDofNumber);
				if (!numIsUnique) {
					errorMsg = 'School/Organization Number ' + projNumber + ' is already taken. Please try another number.';
				}
				if (!errorMsg) {
					const updatedUser = {
						number: number.value,
					};

					setProjectNumber(number.value);
					appBase
						.firestore()
						.collection('users')
						.doc(teacherDocID)
						.update(updatedUser)
						.then(() => {
							// console.log("success!");
							// setErrAlert({
							//   show: true,
							//   msg: "School/Organization Number Successfully Added!",
							//   type: "success",
							// });
						})
						.catch((error) => {
							console.log('error in user profile update', error);
							setErrAlert({
								show: true,
								msg: error.message,
								type: 'error',
							});
						});
				} else {
					// console.log("number taken");
					setErrAlert({
						show: true,
						msg: errorMsg,
						type: 'error',
					});
				}
			} catch (error) {
				console.log('ERR', error);
			}
		};
		saveProfileForm();
	};

	const searchOnChange = (val) => {
		const lval = val.toLowerCase();
		let newArr = [];
		if (!lval || lval.length === 0) {
			setProjectListDisplay(projectList);
			setShowAlert(false);
		} else {
			newArr = projectList.filter(
				(ac) => ac.title.toLowerCase().includes(lval) || ac.type.toLowerCase().includes(lval)
			);
			setProjectListDisplay(newArr);
			setShowAlert(newArr.length === 0);
		}
	};

	//RETURN COMPONENT
	let returnVal = <Spinner />;
	if (!spinner) {
		if (projectNumber || !projectNumber) {
			returnVal = (
				<>
					<Alert
						showAlert={errAlert.show}
						close={() => {
							setErrAlert({ show: false, msg: '', type: 'error' });
						}}
						type={errAlert.type}
					>
						{errAlert.msg}
					</Alert>
					<div>
						<Modal show={modalNew.show} header="New Project" handleClose={closeModalNew}>
							<AddEditProjects
								handleClose={closeModalNew}
								projectDD1={projectDropdown}
								projectDD2={projectDropdown2}
								handleSave={(event) => {
									closeModalNew();
									event.preventDefault();
									const addProject = async () => {
										try {
											const { title, projType, projType2 } = event.target.elements;
											const titleVal = title.value;
											const projTypeVal = projType.value; //step ID
											let projType2Val = null; //process ID
											if (projType2) {
												projType2Val = projType2.value;
											}
											const projectLetter = seqToLetter(projectList.length);

											const getNewProjID = await addNewProject(
												teacherDocID,
												titleVal,
												projTypeVal,
												projType2Val,
												projectLetter
											);

											const typeDisplay1 = typeTitleByID(projectDropdown, projTypeVal).title;

											const typeDisplay2 = projType2Val
												? ' - ' + typeTitleByID(projectDropdown2, projType2Val).title
												: '';

											const typeDisplay = typeDisplay1 + typeDisplay2;
											const dateAdd = new Date().getTime();

											const addedProject = {
												id: getNewProjID,
												title: titleVal,
												type: typeDisplay,
												createdDT: Math.round(dateAdd / 1000),
												projectNumber: projectNumber + projectLetter,
												tableType: 'teacher',
											};

											setProjectList([addedProject, ...projectList]);
											setProjectListDisplay([addedProject, ...projectListDisplay]);

											getAndSetProjectScoring(teacherDocID, getNewProjID, projTypeVal);

											const stepsProcesses = await getStepsAndProcesses();

											addStepsAndProcesses(teacherDocID, addedProject.id, stepsProcesses);
										} catch (error) {
											console.log('error adding project', error);
										}
									};
									addProject();
								}}
							/>
						</Modal>
						<Header
							title={<span style={{ fontWeight: 500 }}>Projects</span>}
							// help={<Help helpId="42uGWGMgtVnGkwmeKQ46" />}
							help={
								<Help
									helpId=""
									preText=""
									isMulti={true}
									multiTitle="Using EDEN to manage the engineering design process as an instructional methodology to manage STEM project teams"
									multiArgs={{ category: ['1', '2'], subcategory: '6' }}
								/>
							}
							libraryAddTitle="Add New Project"
							libraryAddClick={() => setModalNew({ show: true })}
							search={true}
							searchPH="Search in Projects..."
							handleSearchChange={(searchVal) => {
								searchOnChange(searchVal);
							}}
						/>
						<Alert
							showAlert={showAlert}
							close={() => {
								setShowAlert(false);
							}}
							type="Success"
						>
							<span>Search contains no results.</span>
							{/* <span onClick={() => searchOnChange("")}>Reset</span> */}
						</Alert>
						{/* <div>
            <Button
              buttonType="add"
              buttonClicked={() => setModalNew({ show: true })}
            >
              New Project
            </Button>
          </div> */}
						<ProjectsTable dataArr={projectListDisplay} userType={userType} />
						{/* <div>{projectNumber}</div> */}
						{/* <pre>projectList: {JSON.stringify(projectListDisplay, 0, 3)}</pre> */}
						{/* <pre>projectDropdown1: {JSON.stringify(projectDropdown, 0, 3)}</pre> */}
						{/* <pre>projectDropdown2: {JSON.stringify(projectDropdown2, 0, 3)}</pre> */}
					</div>
				</>
			);
		} else {
			returnVal = (
				<>
					<p>
						<strong>
							Your school / organization is required to have a unique number to identify itself in competitions.
						</strong>
					</p>
					<p>
						Please enter a number between <strong>100-99999</strong> that you would like to use as your school
						identifier.
					</p>
					<p>Note that if the number you enter is already taken, you will be asked to choose another.</p>
					<p>You can always change this number in your Profile if needed.</p>
					<form autoComplete="off" onSubmit={saveProfileHandler}>
						<div>
							<Textbox
								id="id-number"
								name="number"
								// type="number"
								custom="number"
								width="96%"
								required={true}
								label="Enter a Unique School/ Organization Number"
								value=""
								maxlength={5}
								numberRange={{
									low: 100,
									high: 99999,
								}}
								InputProps={{
									maxLength: 5,
									min: 100,
									max: 99999,
								}}
								helperText="Please enter a number between 100 and 99999"
							/>
						</div>
						<div>
							<Button type="submit" buttonType="save">
								Save
							</Button>
						</div>
					</form>
				</>
			);
		}
	}
	if (userType === 'mentor') {
		return <HomeStudent>{returnVal}</HomeStudent>;
	} else {
		return <Home>{returnVal}</Home>;
	}
};

export default Projects;
