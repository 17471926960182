import React, { useState, useEffect } from "react";
import Home from "../../HomeStudent";
import Spinner from "../../UI/Spinner/Spinner";
import ProjectsTable from "../../Pages/Projects/ProjectsTable";
import {
  getProjects,
  getProjectsWithTypeTitle,
  getProjectsLevel1,
  getProjectsLevel2,
} from "../../global/globalFunctions";
import { getStudentNotebooks, filterStudentProjects } from "../../helpers";
import { useAuthContext, useAppContext } from "../../context";

const Projects = () => {
  const { setCurrentUser } = useAppContext();
  const { teacherId, setTeacherId, user } = useAuthContext();
  const [spinner, setSpinner] = useState(true);
  const [projectList, setProjectList] = useState([]);

  useEffect(() => {
    // RUN PROMISES
    const getUIDandProjects = async () => {
      if (!teacherId || !user.uid) {
        return;
      }
      try {
        const processedNotebooks = await getStudentNotebooks(
          teacherId,
          user.uid
        );
        // console.log("processedNotebooks: ", processedNotebooks);
        const processedProjects = await getProjects(teacherId);
        // console.log("processedProjects: ", processedProjects);
        const processedStudentProjects = await filterStudentProjects(
          processedNotebooks,
          processedProjects
        );
        // console.log("filtered projects", processedStudentProjects);
        const processedLevel1 = await getProjectsLevel1();
        const processedLevel2 = await getProjectsLevel2(processedLevel1);
        const typeTitle = await getProjectsWithTypeTitle(
          processedStudentProjects,
          processedLevel1,
          processedLevel2
        );
        // console.log("typeTitle: ", typeTitle);
        setProjectList(typeTitle);
        setSpinner(false);
      } catch (error) {
        console.log("getUIDandProjects catch error", error);
        setSpinner(false);
      }
    };
    getUIDandProjects();
  }, [teacherId, setCurrentUser, setTeacherId, user.uid]);

  let returnVal = <Spinner />;
  if (!spinner) {
    returnVal = (
      <div>
        <ProjectsTable dataArr={projectList} />
        {/* <pre>{JSON.stringify(projectList, 0, 3)}</pre> */}
      </div>
    );
  }
  return <Home>{returnVal}</Home>;
  // return <>{returnVal}</>;
};

export default Projects;
