import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getNotebookURL } from "../../global/globalFunctions";
import { updateEntryNos } from "../adminFunctions";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Textbox from "../../UI/Forms/Textbox";
import Button from "../../UI/Forms/Button";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(data) {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {data.row.title}
        </TableCell>
        <TableCell align="right">
          {new Date(data.row.createdDT.seconds * 1000).toDateString()}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Notebook</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>URL</TableCell>
                    {/* <TableCell align="right">C</TableCell>
                    <TableCell align="right">D</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.row.notebooks.map((nbrow, i) => {
                    // console.log(nbrow);
                    const nbURL = getNotebookURL(
                      data.row.teacherDocID,
                      nbrow.id
                    );
                    const nbLink = (
                      <a href={nbURL} target="_blank" rel="noopener noreferrer">
                        {nbURL}
                      </a>
                    );
                    return (
                      <TableRow key={i}>
                        <TableCell component="th" scope="row">
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              updateEntryNos(data.row.teacherDocID, nbrow.id);
                            }}
                          >
                            {nbrow.title}
                          </div>
                        </TableCell>
                        <TableCell>
                          {new Date(
                            nbrow.createdDT.seconds * 1000
                          ).toDateString()}
                        </TableCell>
                        <TableCell>{nbLink}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable(props) {
  const [nbQty, setNbQty] = useState("");
  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <div>
          Organization: <strong>{props.overview.org}</strong>
        </div>
        <div>
          Org Number: <strong>{props.overview.number}</strong>
        </div>
        <div>
          Name:{" "}
          <strong>{props.overview.first + " " + props.overview.last}</strong>
        </div>
        <div>
          Phone: <strong>{props.overview.phone}</strong>
        </div>
        <div>
          District/State/Country:{" "}
          <strong>
            {props.overview.district +
              ", " +
              props.overview.state +
              ", " +
              props.overview.country}
          </strong>
        </div>
        <div>
          # Students: <strong>{props.overview.studentCt}</strong>
        </div>
        <div>
          # Projects: <strong>{props.overview.projectCt}</strong>
        </div>
        <div>
          # Notebooks Purchased: <strong>{props.overview.purchaseQty}</strong>
        </div>
        <div>
          # Notebooks Used: <strong>{props.overview.notebookCt}</strong>
        </div>
        <Textbox
          type="number"
          textChange={(nm, txt) => setNbQty(txt)}
          placeholder="Add Notebooks"
        />
        <Button
          buttonClicked={() => {
            props.addNotebooks(nbQty);
          }}
        >
          Add
        </Button>
      </div>

      {/* <pre>{JSON.stringify(props.overview, 0, 3)}</pre> */}
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Project</TableCell>
              <TableCell align="right">Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row, i) => (
              <Row key={i} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <pre>{JSON.stringify(props, 0, 3)}</pre> */}
      <div style={{ marginTop: 100 }}>&nbsp;</div>
    </>
  );
}
