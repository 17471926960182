import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
// import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function SimplePopover(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      {props.tooltip ? (
        <Tooltip title={props.tooltip}>
          <span onClick={handleClick} style={{ cursor: "pointer" }}>
            {props.clickObject}
          </span>
        </Tooltip>
      ) : (
        <span onClick={handleClick} style={{ cursor: "pointer" }}>
          {props.clickObject}
        </span>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography className={classes.typography}>
          {props.label}{" "}
          {/* <Button
            aria-describedby={id}
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            Cancel
          </Button> */}
          <Tooltip title={[props.tooltipRemove]}>
            <IconButton
              aria-label="remove"
              style={{ padding: 6 }}
              onClick={() => {
                props.handleDelete();
                handleClose();
              }}
            >
              <CheckCircleIcon style={{ color: "#00b3a3" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel and Return">
            <IconButton
              aria-label="cancel"
              style={{ padding: 6 }}
              onClick={handleClose}
            >
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </Typography>
      </Popover>
    </div>
  );
}
