import React, { useState, useEffect } from "react";
import Table from "../../UI/Tables/Table";
import Header from "../../UI/Header/Header";
import Alert from "../../UI/Alerts/Alert";
import Spinner from "../../UI/Spinner/Spinner";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import {
  getAccountsDetails,
  getPurchases,
  updatePurchase,
} from "../adminFunctions";
import FullScreenModal from "../../UI/Modals/FullscreenModal";
import ShowPurchase from "./ShowPurchase";

const Accounts = (props) => {
  const [showAlert, setShowAlert] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [modalNew, setModalNew] = useState({
    show: false,
    data: [],
  });
  const [purchaseArray, setPurchaseArray] = useState([]);
  const [purchaseFiltered, setPurchaseFiltered] = useState([]);

  useEffect(() => {
    const initPurchases = async () => {
      try {
        const acct = await getAccountsDetails();
        // setAcctData(acct);

        const prchs = await getPurchases();

        const modPrchs = prchs.map((p) => {
          const filteredAcct = acct.filter((a) => a.id === p.userId);
          return {
            ...filteredAcct[0],
            ...p,
            fullName:
              filteredAcct[0] &&
              `${filteredAcct[0].last}, ${filteredAcct[0].first}`,
          };
        });

        setPurchaseArray(modPrchs);
        setPurchaseFiltered(modPrchs);
        setSpinner(false);
      } catch (error) {
        setSpinner(false);
        console.log("initPurchases catch error", error);
      }
    };
    initPurchases();
  }, []);

  const openPurchase = (id) => {
    const filteredHelp = purchaseArray.filter((p) => p.id === id);
    setModalNew({ show: true, type: "edit", data: filteredHelp });
  };

  const searchOnChange = (val) => {
    const lval = val.toLowerCase();
    let newArr = [];
    if (!lval || lval.length === 0) {
      setPurchaseFiltered(purchaseArray);
      setShowAlert(false);
    } else {
      newArr = purchaseArray.filter(
        (ac) =>
          ac.org.toLowerCase().includes(lval) ||
          ac.fullName.toLowerCase().includes(lval)
      );
      setPurchaseFiltered(newArr);
      setShowAlert(newArr.length === 0);
    }
  };

  const headerData = () => {
    let headers = [
      {
        id: "type",
        numeric: false,
        disablePadding: false,
        label: "Type",
        isClickable: true,
      },
      {
        id: "purchaseDT",
        numeric: false,
        disablePadding: false,
        label: "Purchase Date",
        isDate: true,
      },
      {
        id: "org",
        numeric: false,
        disablePadding: false,
        label: "School/Org",
      },
      {
        id: "fullName",
        numeric: false,
        disablePadding: false,
        label: "Name",
      },
      {
        id: "quantity",
        numeric: false,
        disablePadding: false,
        label: "Quantity",
      },
      {
        id: "amount",
        numeric: false,
        disablePadding: false,
        label: "Total",
      },
      {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        formatArg: "Not Approved",
        format: { color: "red", background: "#FFDFE4", padding: "0 5px" },
      },
      // {
      //   id: "id",
      //   numeric: false,
      //   disablePadding: false,
      //   label: "ID",
      // },
    ];
    return headers;
  };

  const closeModalNew = () => {
    setModalNew({ ...modalNew, show: false });
  };

  const returnVal = (
    <>
      <FullScreenModal
        show={modalNew.show}
        header="Purchase Details"
        handleClose={closeModalNew}
      >
        <ShowPurchase
          data={modalNew.data}
          submitForm={(teacherDocId, purchaseID, formVals) => {
            updatePurchase(teacherDocId, purchaseID, formVals);
            closeModalNew();
          }}
        />
      </FullScreenModal>
      <Header
        title={
          <>
            <span
              style={{
                color: "maroon",
                fontWeight: "500",
                cursor: "pointer",
              }}
              onClick={() => props.acctClicked()}
            >
              Admin
            </span>
            <PlayArrowIcon
              style={{
                fontSize: 12,
                margin: "0px 7px",
                color: "#888",
              }}
            />
            <span>Purchases</span>
          </>
        }
        search={true}
        searchPH="Search school or name..."
        handleSearchChange={(searchVal) => {
          searchOnChange(searchVal);
        }}
      />
      <Alert
        showAlert={showAlert}
        close={() => {
          setShowAlert(false);
        }}
        type="Success"
      >
        <span>Search contains no results.</span>
        {/* <span onClick={() => searchOnChange("")}>Reset</span> */}
      </Alert>
      <Table
        dataArr={purchaseFiltered}
        headerData={headerData()}
        handleClick={(id) => openPurchase(id)}
        fontSize={12}
        defaultSort={{ orderBy: "purchaseDT", order: "desc" }}
      />
      {/* <pre>{JSON.stringify(acctData, 0, 3)}</pre> */}
      {/* <pre>{JSON.stringify(purchaseArray, 0, 3)}</pre> */}
    </>
  );

  return <>{spinner ? <Spinner /> : returnVal}</>;
};

export default Accounts;
