import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CancelIcon from "@material-ui/icons/Close";

const ThisAlert = (props) => {
  let alertColor = "#7c0910"; //default to error colors
  let alertBG = "#f6dfe1";
  if (props.type === "success") {
    alertColor = "#2754a5";
    alertBG = "#dfe7f6";
  } else if (props.type === "warning") {
    alertColor = "#a36b1c";
    alertBG = "#fbecd3";
  }
  return (
    <div>
      <div>
        <Collapse in={props.showAlert}>
          <div
            style={{
              // border: "1px solid #CCC",
              borderRadius: 3,
              margin: 10,
              backgroundColor: alertBG,
              padding: 5,
            }}
          >
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={props.close}
            >
              <CancelIcon style={{ color: alertColor }} />
            </IconButton>
            <span
              style={{ paddingLeft: 6, fontWeight: 500, color: alertColor }}
            >
              {props.children}
            </span>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default ThisAlert;
