import React from "react";
import Home from "../../Home";
// import Table from "../../UI/Tables/Table";
import DisplayFAQ from "./DisplayFAQ";

const Accounts = () => {
  return (
    <Home>
      <DisplayFAQ type="1" />
    </Home>
  );
};

export default Accounts;
