import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
// import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
// import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
// import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HelpIcon from "@material-ui/icons/Help";
// import CancelIcon from "@material-ui/icons/Cancel";
import HelpModal from "../../UI/Modals/FullscreenModal";
import { getHelpFile, getHelpFileArray } from "../../global/globalFunctions";
import ReactHtmlParser from "react-html-parser";
import "./Modals.css";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
    fontSize: 13,
  },
}));

const Help = (props) => {
  const [helpfile, setHelpfile] = useState([]);
  const [helpfileArray, setHelpfileArray] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    const fetchHelpFile = async () => {
      try {
        // console.log(proc);
        let proc = [];
        // let helpArr = [];
        if (props.isMulti) {
          proc = await getHelpFileArray(props.multiArgs);
          // await props.helpIdArr.forEach((id) => {
          //   proc = getHelpFile(id);
          //   helpArr.push(proc);
          // });
          setHelpfileArray(proc);
        } else {
          proc = await getHelpFile(props.helpId);
          setHelpfile(proc);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchHelpFile();
  }, [props.helpId, props.isMulti, props.multiArgs]);
  const closeHelpModal = () => {
    setOpenModal(false);
  };

  const handleFileClick = (file) => {
    setHelpfile(file);
    setOpenModal(true);
  };
  return (
    <>
      <HelpModal
        header={helpfile && helpfile.title}
        show={openModal}
        handleClose={closeHelpModal}
      >
        <div>
          {helpfile && (
            <div>
              <div style={{ fontWeight: "bold" }}>{helpfile.title}</div>
              {ReactHtmlParser(helpfile.html)}
            </div>
          )}
        </div>
      </HelpModal>
      <HelpPopover
        clickObject={
          <>
            <span>{props.preText}</span>
            <IconButton
              aria-label="help"
              style={{ padding: 6 }}
              //   onClick={}
            >
              <HelpIcon style={{ color: "#00b3a3", fontSize: 20 }} />
            </IconButton>
          </>
        }
        tooltip={"Click to view help on this subject."}
        helpfile={helpfile}
        preText={props.pretext}
        isMulti={props.isMulti}
        multiTitle={props.multiTitle}
        helpFileArray={helpfileArray}
        openModal={() => setOpenModal(true)}
        handleHelpfileClick={(file) => handleFileClick(file)}
      />
      {/* <pre>{JSON.stringify(helpfile, 0, 3)}</pre> */}
    </>
  );
};

const HelpPopover = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const multiHelpFile = () => {
    return props.helpFileArray.map((h, i) => (
      <div
        key={i}
        className="help__helplink"
        onClick={() => props.handleHelpfileClick(h)}
      >
        {h.title}
      </div>
    ));
  };

  /* On Development this component is throwing an error because
  * some Help files are missing in the Dev Firestore database */
  // return null;
  return (
    <>
      {props.tooltip ? (
        <Tooltip title={props.tooltip}>
          <span onClick={handleClick} style={{ cursor: "pointer" }}>
            {props.clickObject}
          </span>
        </Tooltip>
      ) : (
        <span onClick={handleClick} style={{ cursor: "pointer" }}>
          {props.clickObject}
        </span>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { maxWidth: 500 },
        }}
      >
        <div className={classes.typography}>
          <div>
            <strong>
              {props.isMulti ? props.multiTitle : props.helpfile.title}
            </strong>
          </div>
          <div>{props.isMulti ? multiHelpFile() : props.helpfile.summary}</div>

          {/* <Button
            aria-describedby={id}
            variant="contained"
            color="primary"
            onClick={handleClose}
            >
            Cancel
        </Button> */}
          {/* <Tooltip title={[props.tooltipRemove]}>
            <IconButton
              aria-label="remove"
              style={{ padding: 6 }}
              onClick={() => {
                props.handleDelete();
                handleClose();
              }}
            >
              <CheckCircleIcon style={{ color: "#00b3a3" }} />
            </IconButton>
          </Tooltip> */}
          <div style={{ textAlign: "right" }}>
            {!props.isMulti && (
              <span
                style={{
                  fontWeight: "bold",
                  cursor: "pointer",
                  // color: "darkblue",
                  padding: "0 10px",
                }}
                onClick={() => {
                  props.openModal();
                  handleClose();
                }}
              >
                MORE
              </span>
            )}
            <span
              style={{
                fontWeight: "bold",
                cursor: "pointer",
                color: "darkred",
                padding: "0 5px",
              }}
              onClick={handleClose}
            >
              CLOSE
            </span>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default Help;
