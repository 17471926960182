import React from 'react';
import styles from './Project.module.css';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../global/constants';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import Tooltip from '@material-ui/core/Tooltip';
import SettingsIcon from '@material-ui/icons/Settings';
import Popover from '../../UI/Modals/Popover';

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
	const {
		classes,
		// onSelectAllClick,
		order,
		orderBy,
		// numSelected,
		// rowCount,
		onRequestSort,
	} = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell>
					<SettingsIcon style={{ fontSize: 20 }} />
				</TableCell>
				{props.headerData.map((headCell) => {
					return (
						<TableCell
							key={headCell.id}
							align={headCell.numeric ? 'right' : 'left'}
							padding={headCell.disablePadding ? 'none' : 'normal'}
							sortDirection={orderBy === headCell.id ? order : false}
						>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : 'asc'}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<span className={classes.visuallyHidden}>
										{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
									</span>
								) : null}
							</TableSortLabel>
						</TableCell>
					);
				})}
				{/* <TableCell align="right">Compete</TableCell> */}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	//   numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	//   onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',

		[theme.breakpoints.down('xs')]: {
			maxWidth: '100vw',
		},
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 300,

		[theme.breakpoints.down('xs')]: {
			minWidth: 'unset',
			maxWidth: '100vw',
		},
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
}));

const NotebooksTable = (props) => {
	const classes = useStyles();
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('title');
	//   const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	//   const [dense, setDense] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(props.dataArr.length >= 25 ? 25 : 10);

	// React.useEffect(() => {
	//   handleRequestSort("", "createdDT");
	//   // console.log("table mounted");
	// }, []);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'desc';
		setOrder(isAsc ? 'asc' : 'desc');
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// const emptyRows =
	//   rowsPerPage -
	//   Math.min(rowsPerPage, props.dataArr.length - page * rowsPerPage);

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				{/* <EnhancedTableToolbar numSelected={selected.length} /> */}
				<TableContainer>
					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						// size={dense ? "small" : "small"}
						size="small"
						aria-label="enhanced table"
					>
						{/* <colgroup>
              <col style={{ width: "1%" }} />
            </colgroup> */}
						<EnhancedTableHead
							classes={classes}
							headerData={props.headerData}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={props.dataArr.length}
						/>
						<TableBody>
							{stableSort(props.dataArr, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									//   const isItemSelected = isSelected(row.title);
									// const labelId = `enhanced-table-checkbox-${index}`;

									let routeLink = '';
									if (props.userType === 'mentor') {
										routeLink = ROUTES.users.mentornotebook.replace(':notebookid', row.id);
									} else {
										routeLink = ROUTES.teachers.notebook.replace(':notebookid', row.id);
									}
									// routeLink = routeLink.replace(":projectid", row.id);
									return (
										<TableRow
											hover
											tabIndex={-1}
											key={index}
											style={row.id === props.submittedNotebookID ? { background: '#ebf9f9' } : {}}
										>
											<TableCell padding="checkbox">
												<div>
													<Tooltip title={'View Notebook Entries for ' + row.title}>
														<Link className={styles.Link} to={routeLink}>
															Notebook
														</Link>
													</Tooltip>
													<span style={{ paddingLeft: 4, paddingRight: 4 }}>|</span>
													<Tooltip title={'View and Edit Team, General Settings for ' + row.title}>
														<span className={styles.Link} onClick={() => props.notebookClick(row.id)}>
															Options
														</span>
													</Tooltip>
												</div>
											</TableCell>
											{props.hasGroups ? (
												<TableCell component="th" scope="row" padding="none">
													{row.groupTitle}
												</TableCell>
											) : null}
											<TableCell component="th" scope="row" padding="none">
												{row.title}
											</TableCell>
											<TableCell component="th" scope="row" padding="none">
												{row.fileSize.toFixed(1)}MB
											</TableCell>
											<TableCell component="th" scope="row" padding="none">
												{row.isTrial ? (
													<span style={{ color: 'red' }}>
														<strong>TRIAL (limited)</strong>
													</span>
												) : (
													'Normal'
												)}
											</TableCell>
											<TableCell align="left">
												{/* {row.createdDT} */}
												{new Date(row.createdDT * 1000).toDateString()}
											</TableCell>
											{row.scores?.map((s, i) => (
												<TableCell key={i} align="right">
													{s.score}
												</TableCell>
											))}

											{/* {props.scoring?.map((s, i) => {
                        const result = row.scores.filter(
                          (score) => score.id === s.id
                        );
                        return (
                          <TableCell key={i} align="right">
                            {result[0].score}
                          </TableCell>
                        );
                      })} */}
											<TableCell align="right">
												{row.scores[0] ? (
													<>
														{row.id === props.submittedNotebookID ? (
															<>
																<Popover
																	clickObject={<EmojiEventsIcon style={{ color: '#00b3a3' }} />}
																	tooltip={'Remove ' + row.title + ' from Competition'}
																	label={'Confirm remove ' + row.title + ' from competition'}
																	tooltipRemove={'Confirm Remove'}
																	handleDelete={() => {
																		props.competition(row.id, 'remove');
																	}}
																/>
															</>
														) : props.submittedNotebookID ? ( //add icon here if we wish to have an action for unsubmitted notebooks
															<></>
														) : (
															<Tooltip title={'Send ' + row.title + ' to Competition'}>
																<span className={styles.Link} onClick={() => props.competition(row.id, 'add')}>
																	<EmojiEventsIcon style={{ color: '#BBBBBB' }} />
																</span>
															</Tooltip>
														)}
													</>
												) : (
													''
												)}
											</TableCell>

											{/* <TableCell align="right">{row.id}</TableCell> */}
										</TableRow>
									);
								})}
							{/* {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 50]}
					component="div"
					count={props.dataArr.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</div>
	);
};
export default NotebooksTable;
