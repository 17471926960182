import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider, AppContextProvider } from "./context";
import CssBaseline from "@material-ui/core/CssBaseline";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";

axios.defaults.baseURL =
  process.env.REACT_APP_ENV === "development"
    ? process.env.REACT_APP_API_DEV
    : process.env.REACT_APP_API_PROD;

ReactDOM.render(
  <React.Fragment>
    <AuthContextProvider>
      <AppContextProvider>
        <BrowserRouter>
          <CssBaseline />
          <App />
        </BrowserRouter>
      </AppContextProvider>
    </AuthContextProvider>
  </React.Fragment>,
  document.getElementById("root")
);

serviceWorker.unregister();
