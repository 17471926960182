import React, { useState, useEffect, useRef } from 'react';
import {
	getProject,
	getStudentNotebook,
	// getNotebookToc,
	getNotebookFeedback,
	getProcessTitles,
	getTeacherProfile,
	getProjectTitles,
	getProjectScoring,
	addNotebookEntry,
	getStudentDiscussion,
	getEntryCount,
} from '../../global/globalFunctions';
import Spinner from '../../UI/Spinner/Spinner';
import Header from '../../UI/Header/Header';
import FullscreenModal from '../../UI/Modals/FullscreenModal';
import RegularModal from '../../UI/Modals/RegularModal';
import Help from '../../UI/Modals/Help';
import Snackbar from '../../UI/Alerts/Snackbar';
import Table from '../../UI/Tables/Table';
// import Textbox from "../../UI/Forms/Textbox";
import Button from '../../UI/Forms/Button';
// import Carousel from "./Carousel";
import appBase from '../../Auth/base';
import MathWrapper from '../../HOC/MathWrapper';
import appSettings from '../../appSettings';
import nbStyles from './Notebook.module.css';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ReactHtmlParser from 'react-html-parser';
import EditIcon from '@material-ui/icons/Edit';
import AddCommentIcon from '@material-ui/icons/AddComment';
import OpenComments from './OpenComments';
import HelpIcon from '@material-ui/icons/Help';
import Grid from '@material-ui/core/Grid';
import ChatIcon from '@material-ui/icons/Chat';
import { makeStyles } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import EntryModal from '../../PagesStudent/Notebook/EntryModal';
import EditorEdit from '../../PagesStudent/Notebook/NotebookModuleEdit';
import Scoring from '../../PagesStudent/Notebook/Scoring';
import PreviewEntry from '../../PagesStudent/Notebook/PreviewEntry';
import TocModal from '../../PagesStudent/Notebook/TocModal';
import ClassicEditor from '../../CKEditor';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		paddingLeft: 10,
		paddingRight: 10,
		paddingTop: 1,
		paddingBottom: 1,
		marginBottom: 25,
	},
	heading: {
		fontSize: theme.typography.pxToRem(12),
		fontWeight: theme.typography.fontWeightBold,
		color: appSettings.themeColor,
		marginBottom: 0,
	},
	headerText: {
		// color: appSettings.themeColor,
		color: '#000000',
		fontSize: 22,
		marginBottom: 15,
	},
	subHeaderText: {
		// color: appSettings.themeColor,
		color: '#555555',
		fontSize: 16,
		marginBottom: 25,
	},
	instructions: {
		marginTop: 20,
		marginLeft: 10,
		position: 'relative',
		background: '#FFFFFF',
		border: '1px solid #AAA',
		borderRadius: 3,
		padding: '10px 20px',
		color: '#777',
	},
	instructionsIcon: {
		// position: "absolute",
		// top: -10,
		// left: -10,
	},
	strikethrough: {
		fontSize: 11,
		color: 'blue',
		marginRight: 10,
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	instrRoot: {
		flexGrow: 1,
		overflow: 'hidden',
		// padding: theme.spacing(0, 3),
	},
	paper: {
		// maxWidth: 400,
		marginBottom: 20,
		padding: theme.spacing(2),
	},
	nbInfo: {
		flexGrow: 1,
		overflow: 'hidden',
		background: '#ebece5',
		padding: theme.spacing(1, 3),
		fontSize: 12,
		marginBottom: 20,
	},
	nbTitle: {
		fontWeight: 500,
		paddingLeft: 5,
	},
}));

const Notebook = (props) => {
	const classes = useStyles();
	const [spinner, setSpinner] = useState(true);
	const [notebook, setNotebook] = useState([]);
	const [entryCount, setEntryCount] = useState(0);
	const [entryModal, setEntryModal] = useState([]);
	const [entryModalOpen, setEntryModalOpen] = useState(false);
	const [notebookEntries, setNotebookEntries] = useState([]);
	const [entryDiscussion, setEntryDiscussion] = useState([]);
	const [notebookToc, setNotebookToc] = useState([]);
	// const [isScrollView] = useState(1);
	const [teacherDocID] = useState(props.teacherDocID);
	const [teacherProfile, setTeacherProfile] = useState(null);
	const [processDetails, setProcessDetails] = useState([]);
	// const [project, setProject] = useState(null);
	const [projectTitle, setProjectTitle] = useState(null);
	const [editModal, setEditModal] = useState({ show: false, entry: [] });
	const [ckData, setCkData] = useState('');
	const [tocCat, setTocCat] = useState({ show: false });
	const [tocFormat, setTocFormat] = useState(0);
	const [previewModal, setPreviewModal] = useState({ show: false, entry: [] });
	const [scoring, setScoring] = useState([]);
	const [studentDiscussion, setStudentDiscussion] = useState([]);
	const [notebookFeedback, setNotebookFeedback] = useState([]);
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: '',
		type: 'success',
	});
	const [openComments, setOpenComments] = useState({
		show: false,
		teacherDocID: null,
		notebookID: null,
	});
	const scrollToBottomRef = useRef();
	// const scrollToEntryRef = useRef([]);
	// scrollToEntryRef.current = [];

	// useEffect(() => {
	//   const getUIDandNotebook = async () => {
	//     try {
	//       setSpinner(true);
	//       const processedResponse = await getStudentNotebook(
	//         props.teacherDocID,
	//         props.notebookID
	//       );
	//       setNotebook(processedResponse);

	//       props.steps.forEach((s) => {
	//         s.process.forEach((p) => {
	//           if (p.id === props.passProcessID) {
	//             const perm = props.permissionsArray.filter(
	//               (data) => data.id === s.id
	//             );
	//             setProcessDetails({
	//               stepID: s.id,
	//               stepTitle: s.title,
	//               isActive: perm[0].isActive,
	//               isScoring: p.isScoring,
	//               title: p.title,
	//               desc: p.desc,
	//               html: p.html,
	//               placeholder: p.placeholder,
	//             });
	//             // setCkData(p.html);
	//           }
	//         });
	//       });
	//       setSpinner(false);
	//     } catch (error) {
	//       setSpinner(false);
	//       console.log("getUIDandNotebook error", error);
	//     }
	//   };
	//   getUIDandNotebook();
	// }, [props.notebookID, props.teacherDocID]);

	useEffect(() => {
		const runProcessDetails = () => {
			let returnArr = [];
			props.steps.forEach((s) => {
				s.process.forEach((p) => {
					if (p.id === props.passProcessID) {
						const perm = props.permissionsArray.filter((data) => data.id === s.id);
						returnArr = {
							stepID: s.id,
							stepTitle: s.title,
							isActive: perm[0].isActive,
							isScoring: p.isScoring,
							title: p.title,
							desc: p.desc,
							html: p.html,
							placeholder: p.placeholder,
						};
						// setCkData(p.html);
					}
				});
			});
			return returnArr;
		};

		const getUIDandNotebook = async () => {
			try {
				setSpinner(true);
				const procGetNotebook = await getStudentNotebook(props.teacherDocID, props.notebookID);
				setNotebook(procGetNotebook);

				let entryCt = 0;
				if (procGetNotebook.isTrial && props.type === 'student') {
					entryCt = await getEntryCount(props.teacherDocID, props.notebookID);
					setEntryCount(entryCt);
				}

				// const procGetToc = await getNotebookToc(
				//   props.teacherDocID,
				//   props.notebookID
				// );
				// setNotebookToc(procGetToc);

				const procGetFeedback = await getNotebookFeedback(props.teacherDocID, props.notebookID);
				setNotebookFeedback(procGetFeedback);

				const procGetStudentDiscussion = await getStudentDiscussion(props.teacherDocID, props.notebookID, null);
				setStudentDiscussion(procGetStudentDiscussion);

				const fetchProject = await getProject(teacherDocID, procGetNotebook.projectID);
				// setProject(fetchProject);

				if (props.type === 'student') {
					const procProcessDetails = await runProcessDetails();
					setProcessDetails(procProcessDetails);

					const projectScoring = await getProjectScoring(teacherDocID, procGetNotebook.projectID);
					setScoring(projectScoring);
				}

				const procTeacherProfile = await getTeacherProfile(teacherDocID);
				setTeacherProfile(procTeacherProfile);

				const procProjectTitle = await getProjectTitles(fetchProject.type1, fetchProject.type2);
				setProjectTitle(procProjectTitle);
				setCkData(processDetails.html);
				// setTocCat("");
				setSpinner(false);

				// let db = appBase
				//   .firestore()
				//   .collection("users")
				//   .doc(props.teacherDocID)
				//   .collection("notebooks")
				//   .doc(props.notebookID)
				//   .collection("entries");

				// if (props.passProcessID && props.passProcessID !== "all") {
				//   db = db.where("processID", "==", props.passProcessID);
				// }
				// const unsubscribe = db
				//   .orderBy("createdDT", "asc")
				//   // .limit(2)
				//   .onSnapshot((snapshot) => {
				//     const getProjects = snapshot.docs.map((doc) => ({
				//       id: doc.id,
				//       html: doc.data().html,
				//       createdDT: doc.data().createdDT.seconds,
				//       stepID: doc.data().stepID,
				//       processID: doc.data().processID,
				//       studentID: doc.data().studentID,
				//       studentName: doc.data().studentName,
				//       isScoring: doc.data().isScoring,
				//       scores: doc.data().scores,
				//       teacherFeedback: doc.data().teacherFeedback,
				//     }));
				//     setNotebookEntries(getProjects);
				//     setSpinner(false);
				//   });
				// return unsubscribe;
			} catch (error) {
				console.log('getUIDandNotebook error', error);
				setSpinner(false);
			}
		};
		getUIDandNotebook();
	}, [
		props.notebookID,
		props.teacherDocID,
		props.passProcessID,
		props.permissionsArray,
		props.steps,
		props.type,
		teacherDocID,
		props.showEntry,
		processDetails.html,
	]);

	useEffect(() => {
		let db = appBase
			.firestore()
			.collection('users')
			.doc(props.teacherDocID)
			.collection('notebooks')
			.doc(props.notebookID)
			.collection('entries');

		if (props.passProcessID && props.passProcessID !== 'all' && !props.showEntry) {
			db = db.where('processID', '==', props.passProcessID);
		}
		let unsubscribe = '';
		if (props.showEntry) {
			unsubscribe = db
				.doc(props.showEntry)
				.get()
				.then((snapshot) => {
					setNotebookEntries([{ id: snapshot.id, ...snapshot.data() }]);
				});
		} else {
			unsubscribe = db
				.orderBy('createdDT', 'asc')
				// .limit(2)
				.onSnapshot((snapshot) => {
					const getProjects = snapshot.docs.map((doc) => {
						let displCat = 'N/A';
						return {
							id: doc.id,
							...doc.data(),
							createdDT: doc.data().createdDT.seconds,
							displTtl: doc.data().tocTtl ? doc.data().tocTtl : 'N/A',
							displCat: displCat,
						};
					});
					setNotebookEntries(getProjects);
				});
		}
		return unsubscribe;
	}, [props.passProcessID, props.notebookID, props.teacherDocID, props.showEntry]);

	useEffect(() => {
		let db = appBase
			.firestore()
			.collection('users')
			.doc(props.teacherDocID)
			.collection('notebooks')
			.doc(props.notebookID)
			.collection('toc');

		let unsubscribe = '';

		unsubscribe = db.onSnapshot((snapshot) => {
			const getToc = snapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));
			setNotebookToc(getToc);
		});
		return unsubscribe;
	}, [props.teacherDocID, props.notebookID]);

	const addFeedback = (e, entryID, processID, entryNo) => {
		e.preventDefault();
		const txtComment = e.target.elements.txtCommentFeedback.value.trim();
		const feedbackDate = new Date().toDateString();
		const feedbackVal = '[' + feedbackDate + '] ' + txtComment;
		if (!txtComment) {
			return;
		}
		e.target.elements.txtCommentFeedback.value = ''; //reset field

		if (props.type === 'teacher') {
			appBase
				.firestore()
				.collection('users')
				.doc(teacherDocID)
				.collection('notebooks')
				.doc(props.notebookID)
				.collection('entries')
				.doc(entryID)
				.update({
					teacherFeedback: firebase.firestore.FieldValue.arrayUnion(feedbackVal),
				})
				.then(() => {
					// console.log(response);
				})
				.catch((error) => {
					console.log(error);
				});
		}
		if (props.type === 'student') {
			const newComment = {
				comment: txtComment,
				entryID: entryID,
				entryNo: entryNo,
				processID: processID,
				createdDT: new Date(),
				studentID: props.studentArray.id,
				studentName: props.studentArray.first + ' ' + props.studentArray.last.substr(0, 1),
			};
			appBase
				.firestore()
				.collection('users')
				.doc(props.teacherDocID)
				.collection('notebooks')
				.doc(props.notebookID)
				.collection('discussion')
				.add(newComment)
				.then(() => {
					// console.log(response);
					const modComment = {
						...newComment,
						createdDT: { seconds: new Date().getTime() / 1000 }, //must convert this to follow the same format when setting state
					};
					setStudentDiscussion([...studentDiscussion, modComment]);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	const feedbackForm = (entryID, processID, entryNo) => {
		if (props.type === 'open') {
			return false;
		}
		return (
			<form autoComplete="off" onSubmit={(event) => addFeedback(event, entryID, processID, entryNo)}>
				<input
					className={nbStyles.txtFeedback}
					name="txtCommentFeedback"
					placeholder={props.type === 'teacher' ? 'Provide Feedback' : 'Add Comment'}
				/>
				<IconButton
					// onClick={() => addFeedback(e.id)}
					title={props.type === 'teacher' ? 'Provide Feedback' : 'Add Comment'}
					type="submit"
				>
					<ChatIcon style={{ color: appSettings.themeColor, fontSize: 16 }} />{' '}
					{/* <span style={{ fontSize: 10, paddingLeft: 5 }}>Feedback</span> */}
				</IconButton>
			</form>
		);
	};

	const editEntry = (e) => {
		setEditModal({ show: true, entry: e });
	};

	const submitEntrytoDatabase = async () => {
		closePreviewModal();
		try {
			const response = await addNotebookEntry(
				teacherDocID,
				props.notebookID,
				ckData,
				props.passProcessID,
				props.studentArray,
				tocCat
			);
			if (response) {
				setCkData(processDetails.html);
				// setTocCat("");
				setEntryCount(entryCount + 1);
				setSnackbar({
					open: true,
					message: 'Notebook Entry Added Successfully',
					severity: 'success',
				});
			}
		} catch (error) {
			console.log('ERROR ADDING NOTEBOOK ENTRY', error);
		}
	};

	const closePreviewModal = () => {
		setPreviewModal({ show: false, entry: [] });
	};
	const saveEdits = (id, html) => {
		appBase
			.firestore()
			.collection('users/' + teacherDocID + '/notebooks')
			.doc(props.notebookID)
			.collection('entries')
			.doc(id)
			.update({ html: html })
			.then((response) => {})
			.catch((error) => {
				console.log('Entry Edit Error', error);
			});
		closeEditModal();
	};

	const scrollToBottom = () => {
		if (scrollToBottomRef.current) {
			scrollToBottomRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
				inline: 'nearest',
			});
			// setNewIsClicked(true);
		}
	};

	// const addToRefs = (el) => {
	//   // console.log("atr", el);
	//   if (el && !scrollToEntryRef.current.includes(el)) {
	//     scrollToEntryRef.current.push(el);
	//   }
	//   // console.log(scrollToEntryRef.current);
	// };
	// const scrollToEntry = (entryNo) => {
	//   // console.log(entryNo);
	//   if (scrollToEntryRef.current[entryNo]) {
	//     scrollToEntryRef.current[entryNo].scrollIntoView({
	//       behavior: "smooth",
	//       block: "end",
	//       inline: "nearest",
	//     });
	//   }
	// };

	const closeEditModal = () => {
		setEditModal({ show: false, entry: [] });
	};

	const closeTocModal = () => {
		setTocCat({ show: false });
		// setNotebookToc({ ...notebookToc, id: "1111", title: "Test Title" });
	};

	const addOpenComment = () => {
		setOpenComments({
			show: true,
			teacherDocID: props.teacherDocID,
			notebookID: props.notebookID,
		});
	};
	const closeOpenComment = () => {
		setOpenComments({ show: false, teacherDocID: null, notebookID: null });
	};

	const headerData = () => {
		let headers = [
			{
				id: 'entryNo',
				// numeric: true,
				label: 'Entry #',
				isClickable: true,
			},
			{
				id: 'displCat',
				numeric: false,
				label: 'Category',
			},
			{
				id: 'displTtl',
				numeric: false,
				label: 'Title',
			},
			{
				id: 'studentName',
				numeric: false,
				label: 'Team Member',
			},
			{
				id: 'createdDT',
				numeric: false,
				label: 'Date',
				isDate: true,
			},
		];
		return headers;
	};
	const entryModalClose = () => {
		setEntryModalOpen(false);
	};
	const handleEntryModal = async (nbEntry) => {
		console.log(nbEntry);
		try {
			setSpinner(true);
			// const nbEntry = await getNotebookEntry(props.teacherDocID, props.notebookID, entID);
			setEntryModal(nbEntry);
			const disussFiltered = studentDiscussion.filter((sd) => sd.entryID === nbEntry.id);
			// const procEntryDiscussion = await getStudentDiscussion(props.teacherDocID, props.notebookID, entID);
			setEntryDiscussion(disussFiltered);
			setSpinner(false);
			setEntryModalOpen(true);
		} catch (err) {
			setSpinner(false);
			console.log(err);
		}
	};
	const displayToc = () => {
		if (props.passProcessID && props.passProcessID !== 'all') {
			return;
		}
		if (tocFormat === 0) {
			//if TOC grouping
			const tocGrouped = notebookToc?.map((toc, i) => {
				let nbFiltered = notebookEntries
					.filter((n) => n.tocId === toc.id)
					.map((n, i) => {
						return (
							<div
								key={i}
								style={{
									display: 'inline-block',
									background: '#FFFFFF',

									color: 'black',
									border: '1px solid #AAA',
									width: '25px',
									marginLeft: 3,
									textAlign: 'center',
									borderRadius: 3,
									cursor: 'pointer',
								}}
								onClick={() => {
									handleEntryModal(n);
								}}
							>
								{n.entryNo}
							</div>
						);
					});
				return (
					<div key={i} style={{ marginBottom: 5 }}>
						<span style={{ padding: 5 }}>
							<strong>{toc.title}</strong>
						</span>
						<span>Entry # {nbFiltered}</span>
					</div>
				);
			});
			return tocGrouped;
		} else {
			//TOC is individual entries
			let tocIndArr = [];
			notebookEntries.forEach((e, i) => {
				let displCat = '';

				if (e.tocId) {
					const filteredToc = notebookToc.filter((t) => t.id === e.tocId);
					displCat = filteredToc[0].title;
				}
				if (e.tocTtl) {
					tocIndArr.push({
						id: e.id,
						entryNo: e.entryNo,
						studentName: e.studentName,
						createdDT: e.createdDT,
						displCat: displCat,
						displTtl: e.tocTtl ? e.tocTtl : '',
						html: e.html,
						teacherFeedback: e.teacherFeedback,
					});
				}
			});

			return (
				<Table
					dataArr={tocIndArr}
					headerData={headerData()}
					fontSize={11}
					args={{ showAll: true }}
					handleClick={(n) => handleEntryModal(n)}
					returnAllRowData={true}
				/>
			);
		}
	};
	const pageTop = () => {
		return (
			<>
				{props.type === 'open' && props.isCommentable ? (
					<>
						<Snackbar
							open={snackbar.open}
							close={() => {
								setSnackbar({
									open: false,
									message: '',
									severity: snackbar.severity,
								});
							}}
							message={snackbar.message}
							severity={snackbar.severity}
						/>
						<OpenComments
							info={openComments}
							handleClose={closeOpenComment}
							handleSubmit={() => {
								closeOpenComment();
								setSnackbar({
									open: true,
									message: 'Thank you! Your feedback has been received.',
									severity: 'success',
								});
							}}
						/>
						<div className={nbStyles.sticky}>
							<Button btnClass="sticky" buttonType="secondary" buttonClicked={addOpenComment}>
								<AddCommentIcon style={{ fontSize: 20, padding: 3 }} />{' '}
								<span style={{ fontSize: 11 }}>Judges' Notes</span>
							</Button>
						</div>
					</>
				) : (
					''
				)}
				<Paper className={classes.nbInfo}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<div>
								School: <span className={classes.nbTitle}>{teacherProfile.org}</span>
							</div>
							<div>
								District: <span className={classes.nbTitle}>{teacherProfile.district}</span>
							</div>
							<div>
								State, Country:{' '}
								<span className={classes.nbTitle}>
									{teacherProfile.state}, {teacherProfile.country}
								</span>
							</div>
							<div>
								Program Manager/Teacher:{' '}
								<span className={classes.nbTitle}>
									{teacherProfile.first} {teacherProfile.last}
								</span>
							</div>
						</Grid>
						<Grid item xs={12} md={6}>
							<div>
								STEM Project-Challenge:
								<span className={classes.nbTitle}>{projectTitle}</span>
							</div>
							<div>
								Team Number:
								<span className={classes.nbTitle}>{teacherProfile.number}</span>
							</div>
							<div>
								Team Name:
								<span className={classes.nbTitle}>{notebook.title}</span>
							</div>
							<div>
								Project Team Members: <span className={classes.nbTitle}>{teacherProfile.district}</span>
							</div>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						{props.passProcessID && props.passProcessID !== 'all' ? (
							''
						) : (
							<>
								<EntryModal
									handleClose={entryModalClose}
									openModal={entryModalOpen}
									entry={entryModal}
									entryDiscussion={entryDiscussion}
									showTeacherFeedback={props.type !== 'open'}
								/>
								<div style={{ margin: '15px 0' }}>
									<span style={{ marginRight: 10, fontSize: 14 }}>
										<strong>Table of Contents</strong>
									</span>
									<span style={{ marginRight: 5 }}>View:</span>
									<span
										style={{
											cursor: 'pointer',
											color: tocFormat === 0 ? 'blue' : '#000',
										}}
										onClick={() => setTocFormat(0)}
									>
										Grouped
									</span>{' '}
									|{' '}
									<span
										style={{
											cursor: 'pointer',
											color: tocFormat === 1 ? 'blue' : '#000',
										}}
										onClick={() => setTocFormat(1)}
									>
										By Entry
									</span>
								</div>
							</>
						)}
						{displayToc()}
					</Grid>
				</Paper>
				{props.type === 'teacher' &&
					notebookFeedback.map((v, i) => (
						<div className={nbStyles.openFeedback} key={i}>
							<div className={nbStyles.openFeedbackHeading}>Judges' Notes:</div>[
							{new Date(v.createdDT.seconds * 1000).toDateString()}
							{'] '}
							{/* {new Date(v.createdDT.seconds * 1000).toLocaleTimeString()} */}
							{v.feedback}
						</div>
					))}
				{processDetails.desc && props.type === 'student' ? (
					<div className={classes.instrRoot}>
						<Paper className={classes.paper}>
							<Grid container wrap="nowrap" spacing={2}>
								<Grid item>
									<HelpIcon
										style={{
											fontSize: 36,
											color: '#AAA',
										}}
									/>
								</Grid>
								<Grid item xs>
									<div>{ReactHtmlParser(processDetails.desc)}</div>
								</Grid>
							</Grid>
						</Paper>
						<Snackbar
							open={snackbar.open}
							close={() => {
								setSnackbar({
									open: false,
									message: '',
									severity: snackbar.severity,
								});
							}}
							message={snackbar.message}
							severity={snackbar.severity}
						/>
					</div>
				) : (
					''
				)}
			</>
		);
	};
	const scrollView = () => {
		// console.log(notebookEntries.length);
		if (notebookEntries.length === 0) {
			return <h2 style={{}}>There are no entries for this step.</h2>;
		}
		return notebookEntries.map((e, i) => {
			const stepAndProcessTitle = getProcessTitles(props.steps, e.processID);
			return (
				<div
					key={i}
					className={nbStyles.entryContainer}
					// ref={scrollToEntryRef[e.entryNo]}
					// ref={addToRefs}
				>
					<Paper elevation={1} style={{ marginBottom: 25 }}>
						<div className={nbStyles.notebookHeading} style={{ color: appSettings.themeColor }}>
							{props.type === 'student' && processDetails.isActive ? (
								<IconButton aria-label="edit" style={{ padding: 6 }} onClick={() => editEntry(e)}>
									<EditIcon style={{ fontSize: 18 }} />
								</IconButton>
							) : (
								''
							)}
							<span
								style={{
									color: '#555555',
									paddingRight: 12,
									fontWeight: 'normal',
								}}
							>
								{stepAndProcessTitle[0].processTitle.toUpperCase()}
							</span>
							{/* {e.id + " "} */}
							Entry #{e.entryNo} by {e.studentName} on {new Date(e.createdDT * 1000).toDateString()}{' '}
							{new Date(e.createdDT * 1000).toLocaleTimeString()}
							{props.type === 'student' && processDetails.isActive ? (
								<IconButton
									aria-label="edit"
									onClick={() =>
										setTocCat({
											show: true,
											entryId: e.id,
											tocId: e.tocId,
											tocTtl: e.tocTtl,
										})
									}
								>
									<span
										style={{
											color: '#555555',
											fontSize: 10,
											paddingLeft: 10,
											paddingRight: 10,
											fontWeight: 'bold',
										}}
									>
										EDIT TABLE OF CONTENTS
									</span>
								</IconButton>
							) : (
								''
							)}
						</div>
						<div className={nbStyles.htmlContainer}>
							{feedbackForm(e.id, e.processID, e.entryNo)}
							{studentDiscussion
								.filter((sd) => sd.entryID === e.id)
								.sort((a, b) => b.createdDT.seconds - a.createdDT.seconds)
								.map((discuss, index) => {
									//question mark before .map creates "if exists" argument
									return (
										<div className={nbStyles.studentComment} key={index}>
											<div className={nbStyles.feedbackHeading}>
												{`${discuss.studentName} wrote on ${new Date(
													discuss.createdDT.seconds * 1000
												).toDateString()}:`}
											</div>
											{discuss.comment}
										</div>
									);
								})}
							{props.type !== 'open' &&
								e.teacherFeedback?.map((feedback, index) => {
									//question mark before .map creates "if exists" argument
									return (
										<div className={nbStyles.teacherFeedback} key={index}>
											<div className={nbStyles.feedbackHeading}>Teacher Feedback:</div>
											{feedback}
										</div>
									);
								})}
							<MathWrapper html={e.html} />
						</div>
					</Paper>
				</div>
			);
		});
	};
	const studentOptions = () => {
		return (
			<>
				<RegularModal header="Table of Contents" show={tocCat.show} handleClose={closeTocModal}>
					<TocModal
						teacherID={props.teacherDocID}
						notebookID={props.notebookID}
						entryId={tocCat.entryId}
						tocId={tocCat.tocId}
						tocTtl={tocCat.tocTtl}
						tocArr={notebookToc}
						// updateTocArr={(id, txt) => {
						//   console.log("uta", id, txt);
						//   setNotebookToc({ ...notebookToc, id: id, title: txt });
						//   closeTocModal();
						// }}
						handleClose={closeTocModal}
						ddOptions={[{ id: 'new', title: '- ADD NEW CATEGORY -' }, ...notebookToc]}
					/>
					{/* <EditorEdit
            data={{
              ...editModal.entry,
              editName:
                props.studentArray.first +
                " " +
                props.studentArray.last.substr(0, 1),
            }}
            cancelEdit={closeEditModal}
            saveEdit={(id, html) => saveEdits(id, html)}
          /> */}
				</RegularModal>
				<FullscreenModal header="Edit Entry" show={editModal.show} handleClose={closeEditModal}>
					<EditorEdit
						data={{
							...editModal.entry,
							editName: props.studentArray.first + ' ' + props.studentArray.last.substr(0, 1),
						}}
						cancelEdit={closeEditModal}
						saveEdit={(id, html) => saveEdits(id, html)}
					/>
				</FullscreenModal>
				<FullscreenModal header="Preview Entry" show={previewModal.show} handleClose={closePreviewModal}>
					<PreviewEntry data={{ html: ckData }} cancelEdit={closePreviewModal} saveEntry={submitEntrytoDatabase} />
				</FullscreenModal>
				<div className={nbStyles.sticky}>
					<Button buttonType="add" buttonClicked={scrollToBottom} btnClass="sticky">
						New Entry
					</Button>
				</div>
			</>
		);
	};

	const addEditor = () => {
		return (
			<>
				{props.type === 'student' && processDetails.isActive && props.passProcessID ? (
					<div style={{ marginTop: 50 }}>
						{!processDetails.isScoring ? (
							<>
								<div className={classes.subHeaderText}>
									<Help
										helpId=""
										multiArgs={{ category: ['2', '3'], subcategory: '3' }}
										preText={`New Entry in ${processDetails.title}`}
										isMulti={true}
										multiTitle="Technical How-To Questions"
									/>
								</div>
								{/* <div className={newIsClicked ? nbStyles.ckWrapper : null}> */}
								{notebook.isTrial && entryCount >= 15 ? (
									<div style={{ fontWeight: 'bold', color: 'red' }}>
										This trial notebook has exceeded its 15 total entries permitted. Please contact your teacher or
										project manager.
									</div>
								) : (
									<>
										<div>
											<ClassicEditor
												data={ckData}
												onChange={setCkData}
												placeholder={processDetails.placeholder}
												teacherDocID={teacherDocID}
												notebookID={props.notebookID}
											/>
										</div>
										<Button
											buttonType="save"
											buttonClicked={() => {
												if (ckData === processDetails.html || ckData.length === 0) {
													return false;
												}
												setPreviewModal({ show: true, entry: [] });
											}}
										>
											Preview New Entry
										</Button>
									</>
								)}
							</>
						) : processDetails.isScoring ? (
							<>
								<div className={classes.subHeaderText}>New Scoring Entry in {processDetails.title}</div>
								<Scoring
									scoringArr={scoring}
									teacherDocID={teacherDocID}
									sendScores={async (scores, notes) => {
										try {
											const db = appBase
												.firestore()
												.collection('users')
												.doc(teacherDocID)
												.collection('notebooks')
												.doc(props.notebookID);

											if (scores === false) {
												// console.log("validation failed");
											} else {
												// console.log(scores);
												let scoringHTML = "<div style='padding-bottom:15px;'><p><strong>Scoring Entry: </strong></p>";
												// only most recent scores get stored in scores collection. All scores are stored in entries collection however
												scores.forEach((item) => {
													db.collection('scores')
														.doc(item.id)
														.set(item)
														.then(() => {})
														.catch((error) => {
															console.log('ERROR CREATING NOTEBOOK SCORE', error);
														});
													scoringHTML +=
														'<div>' + item.title + ': <strong>' + item.score + ' ' + item.unit + '</strong> </div>';
												});
												scoringHTML += '</div>';

												if (notes.length) scoringHTML += notes;

												const response = await addNotebookEntry(
													teacherDocID,
													props.notebookID,
													scoringHTML,
													props.passProcessID,
													props.studentArray,
													tocCat
												);
												if (response) {
													setCkData(processDetails.html);
													// setTocCat("");
													setEntryCount(entryCount + 1);
													setSnackbar({
														open: true,
														message: 'Notebook Entry Added Successfully',
														severity: 'success',
													});
												}
											}
										} catch (error) {
											console.log('ERROR ADDING NOTEBOOK ENTRY', error);
										}
									}}
									//   sendScores={(scores, notes) => {
									//     const db = appBase
									//       .firestore()
									//       .collection("users")
									//       .doc(teacherDocID)
									//       .collection("notebooks")
									//       .doc(props.notebookID);

									//     if (scores === false) {
									//       // console.log("validation failed");
									//     } else {
									//       // console.log(scores);
									//       let scoringHTML =
									//         "<div style='padding-bottom:15px;'><p><strong>Scoring Entry: </strong></p>";
									//       // only most recent scores get stored in scores collection. All scores are stored in entries collection however
									//       scores.forEach((item) => {
									//         db.collection("scores")
									//           .doc(item.id)
									//           .set(item)
									//           .then(() => {})
									//           .catch((error) => {
									//             console.log("ERROR CREATING NOTEBOOK SCORE", error);
									//           });
									//         scoringHTML +=
									//           "<div>" +
									//           item.title +
									//           ": <strong>" +
									//           item.score +
									//           " " +
									//           item.unit +
									//           "</strong> </div>";
									//       });
									//       scoringHTML += "</div>";

									//       if (notes.length) scoringHTML += notes;

									//       db.collection("entries")
									//         .add({
									//           html: scoringHTML,
									//           createdDT: new Date(),
									//           processID: props.passProcessID,
									//           studentID: props.studentArray.id,
									//           studentName:
									//             props.studentArray.first +
									//             " " +
									//             props.studentArray.last.substr(0, 1),
									//         })
									//         .then(() => {
									//           setSnackbar({
									//             open: true,
									//             message: "Notebook Scores Added Successfully",
									//             severity: "success",
									//           });
									//         })
									//         .catch((error) => {
									//           console.log(error);
									//         });
									//     }
									//   }
									// }
								/>
							</>
						) : (
							<div style={{ padding: 7, color: 'red' }}>
								<strong>Note: </strong>You are currently not able to add or edit Notebook entries in the{' '}
								<strong>{processDetails.stepTitle}</strong> process. Contact the program manager or teacher to gain
								access.
							</div>
						)}
						<div style={{ height: 70 }} ref={scrollToBottomRef}>
							&nbsp;
						</div>
					</div>
				) : (
					''
				)}
			</>
		);
	};
	const handle = useFullScreenHandle();

	if (spinner) {
		return <Spinner />;
	}
	return (
		<>
			{/* <div className={nbStyles.headerText}>
        {notebook.title} {processDetails.title}
      </div> */}
			<div style={{ marginBottom: 10 }}>
				{/* <pre>{JSON.stringify(studentDiscussion, 0, 3)}</pre> */}
				<Header
					title={notebook.title}
					fullscreenTitle="Enter Full Screen Mode"
					fullscreenClick={handle.enter}
					studentDiscussion="Team Notebook Discussion"
					studentDiscussionClick={props.gotoDiscussion}
				/>
				{/*           <span
            className={nbStyles.toggleButton}
            style={
              isScrollView === 0
                ? { backgroundColor: appSettings.themeColor, color: "white" }
                : null
            }
            onClick={() => {
              setIsScrollView(0);
            }}
          >
            Reader View
          </span>
          <span
            className={nbStyles.toggleButton}
            style={
              isScrollView === 1
                ? { backgroundColor: appSettings.themeColor, color: "white" }
                : null
            }
            onClick={() => {
              setIsScrollView(1);
            }}
          >
            Scroll View
          </span>
          <span
            className={nbStyles.toggleButton}
            style={
              isScrollView === 2
                ? { backgroundColor: appSettings.themeColor, color: "white" }
                : null
            }
            onClick={() => {
              setIsScrollView(2);
            }}
          >
            Scores
          </span> */}
			</div>
			{props.type === 'student' &&
				props.passProcessID &&
				props.passProcessID !== 'all' &&
				!props.showEntry &&
				studentOptions()}

			{pageTop()}
			{/* {displayToc()} */}
			<FullScreen handle={handle} style={{ background: 'white' }}>
				<div className={nbStyles.nbContainer}>
					{/* {isScrollView === 0 ? (
            <Carousel entries={notebookEntries} />
          ) : isScrollView === 1 ? (
            scrollView()
          ) : (
            ""
          )} */}

					{scrollView()}
				</div>
			</FullScreen>
			{addEditor()}
			{/* <pre>{JSON.stringify(scrollToEntryRef, 0, 3)}</pre> */}
			{/* <pre>{JSON.stringify(notebookToc, 0, 3)}</pre> */}
			{/* <pre>{JSON.stringify(notebookEntries[0], 0, 3)}</pre> */}
		</>
	);
};

export default Notebook;
