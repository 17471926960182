import React, { useState, useEffect } from "react";
import { createContext } from "./";
import { verifyTeacher } from "../global/globalFunctions";

const [Provider, useAppContext] = createContext({ name: "AppContext" });

const AppContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({
    userType: "",
    teacherDocId: "",
    teacherVerified: false,
  });
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  useEffect(() => {
    if (currentUser.teacherDocId) {
      return window.sessionStorage.setItem(
        "studentTeacherDocID",
        currentUser.teacherDocId
      );
    }
  }, [currentUser.teacherDocId]);

  useEffect(() => {
    if (currentUser.teacherDocId) {
      const verify = async () => {
        const verified = await verifyTeacher(currentUser.teacherDocId);
        if (verified) {
          setCurrentUser((pre) => ({ ...pre, teacherVerified: true }));
        } else {
          setCurrentUser((pre) => ({ ...pre, teacherVerified: false }));
        }
      };
      verify();
    }
  }, [currentUser.teacherDocId]);

  const toggleDrawer = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  return (
    <Provider
      value={{
        currentUser,
        setCurrentUser,
        mobileDrawerOpen,
        toggleDrawer,
      }}
    >
      {children}
    </Provider>
  );
};

export { AppContextProvider, useAppContext };
