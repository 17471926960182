// import React from "react";
import appBase, { db } from "../Auth/base";

// export function getAccount(teacherDocID) {
//   return new Promise((resolve, reject) => {
//     const db = appBase.firestore().collection("users");
//     db.doc(teacherDocID)
//       .get()
//       .then((snapshot) => {
//         const acctData = {
//           ...snapshot.data(),
//           number: snapshot.data().number ? snapshot.data().number : "",
//         };
//         resolve(acctData);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

export const getAccount = (teacherDocID) => {
  return new Promise((resolve, reject) => {
    const db = appBase.firestore().collection("users").doc(teacherDocID);

    db.collection("projects")
      .get()
      .then((snapshot) => {
        let ResourcePromises = snapshot.docs.map(async (doc) => {
          let promises = [];
          const projectID = doc.id;
          let documentData = {
            teacherDocID: teacherDocID,
            ...doc.data(),
          };
          promises.push(
            db
              .collection("notebooks")
              .where("projectID", "==", projectID)
              .get()
              .then((snapshot) => {
                documentData["notebooks"] = snapshot.docs.map((doc) => {
                  const nbData = {
                    id: doc.id,
                    ...doc.data(),
                  };
                  return nbData;
                });
              })
          );
          await Promise.all(promises);
          return documentData;
        });

        Promise.all(ResourcePromises).then(function (allResources) {
          resolve(allResources);
        });
      })
      .catch((err) => {
        reject("Error getting account details", err);
      });
  });
};

export const getPurchases = () => {
  return new Promise((resolve, reject) => {
    const db = appBase.firestore().collection("purchase");
    db.get()
      .then((snapshot) => {
        const promiseArr = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          purchaseDT: doc.data().purchaseDT && doc.data().purchaseDT.seconds,
          type: doc.data().last4
            ? "Credit Card"
            : doc.data().transactionId === "new-account"
            ? "New Account"
            : doc.data().transactionId === "admin-add"
            ? "Admin Added"
            : "Purchase Order",
          status: doc.data().poApproved === false ? "Not Approved" : "Approved",
          quantity:
            doc.data().quantity === 0
              ? doc.data().poQuantity
              : doc.data().quantity,
        }));
        resolve(promiseArr);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAccountsDetails = () => {
  return new Promise((resolve, reject) => {
    const db = appBase.firestore().collection("users");

    db.get()
      .then((snapshot) => {
        // const getGroupTitle = (id) => {
        //   if (id === null) {
        //     return null;
        //   }
        //   const ttl = groupArr.filter((g) => g.id === id);
        //   return ttl[0].title;
        // };
        let ResourcePromises = snapshot.docs.map(async (doc) => {
          let promises = [];
          const currentUID = doc.data().uid;
          let documentData = {
            id: doc.id,
            ...doc.data(),
            number: doc.data().number ? doc.data().number : "",
          };
          promises.push(
            db
              .doc(currentUID)
              .collection("projects")
              .get()
              .then((snapshot) => {
                documentData["projectCt"] = snapshot.docs.length;
              })
          );
          const dbNotebooks = db.doc(currentUID).collection("notebooks");
          // let entryCt = 0;
          promises.push(
            dbNotebooks.get().then((snapshot) => {
              const notebookArr = snapshot.docs;
              documentData["notebookCt"] = notebookArr.length;

              // notebookArr.forEach((doc) => {
              //   dbNotebooks
              //     .doc(doc.id)
              //     .collection("entries")
              //     .get()
              //     .then((ess) => {
              //       entryCt += ess.docs.length;
              //     });
              // });
              // documentData["entryCt"] = entryCt;

              //gets max date of notebook createdDT. can't get it to to work
              // const md = arr.reduce((a, b) => {
              //   return new Date(a.createdDT) > new Date(b.createdDT) ? a : b;
              // });
              // documentData["md"] = md;
            })
          );
          promises.push(
            db
              .doc(currentUID)
              .collection("students")
              .get()
              .then((snapshot) => {
                documentData["studentCt"] = snapshot.docs.length;
              })
          );
          promises.push(
            db
              .doc(currentUID)
              .collection("purchase")
              .get()
              .then((snapshot) => {
                let tot = 0;
                snapshot.docs.forEach((doc) => {
                  if (doc.data().quantity) {
                    tot += doc.data().quantity;
                  }
                });
                documentData["purchaseQty"] = tot;
              })
          );

          await Promise.all(promises);
          return documentData;
        });

        Promise.all(ResourcePromises).then(function (allResources) {
          resolve(allResources);
        });
      })
      .catch((err) => {
        reject("Error getting account details", err);
      });
  });
};

export function getHelpFiles() {
  return new Promise((resolve, reject) => {
    db.collection("help-files")
      .get()
      .then((snapshot) => {
        const helpData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          createdDT: doc.data().createdDT.seconds,
          // createdDT: new Date(
          //   doc.data().createdDT.seconds * 1000
          // ).toDateString(),
        }));
        resolve(helpData);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getFeedback() {
  return new Promise((resolve, reject) => {
    db.collection("feedback")
      .orderBy("createdDT", "desc")
      .get()
      .then((snapshot) => {
        const feedbackData = snapshot.docs.map((doc) => doc.data());
        resolve(feedbackData);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const addNewHelpFile = (helpData) => {
  return new Promise((resolve, reject) => {
    appBase
      .firestore()
      .collection("help-files")
      .add(helpData)
      .then((response) => {
        resolve(response.id);
      })
      .catch((error) => {
        reject("ERROR ADDING", error);
      });
  });
};

export const updateHelpFile = (id, helpData) => {
  return new Promise((resolve, reject) => {
    appBase
      .firestore()
      .collection("help-files")
      .doc(id)
      .update(helpData)
      .then((response) => {
        resolve(true);
      })
      .catch((error) => {
        reject("ERROR UPDATING", error);
      });
  });
};

export const updatePurchase = (teacherDocId, purchaseID, formVals) => {
  return new Promise((resolve, reject) => {
    appBase
      .firestore()
      .collection("purchase")
      .doc(purchaseID)
      .update(formVals)
      .then(() => {
        appBase
          .firestore()
          .collection("users")
          .doc(teacherDocId)
          .collection("purchase")
          .doc(purchaseID)
          .update(formVals)
          .then(() => {
            resolve(true);
          })
          .catch((err) => reject(err));
      })
      .catch((err) => {
        reject("ERROR UPDATING", err);
      });
  });
};

export const adminAddNotebooks = (teacherDocID, nbQty) => {
  return new Promise((resolve, reject) => {
    appBase
      .firestore()
      .collection("users")
      .doc(teacherDocID)
      .collection("purchase")
      .add({
        amount: 0,
        pricePer: 0,
        quantity: nbQty,
        purchaseDT: new Date(),
        transactionId: "admin-add",
      })
      .then((response) => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateEntryNos = (teacherDocID, notebookID) => {
  const prefix = appBase
    .firestore()
    .collection("users")
    .doc(teacherDocID)
    .collection("notebooks")
    .doc(notebookID);

  prefix
    .collection("entries")
    .orderBy("createdDT", "asc")
    .get()
    .then((snapshot) => {
      let entryCt = null;
      if (snapshot.docs.length > 0) {
        entryCt = snapshot.docs.length;
      }
      snapshot.docs.forEach((doc, i) => {
        prefix
          .collection("entries")
          .doc(doc.id)
          .update({ entryNo: i + 1 })
          .then(() => {
            console.log(doc.id + " " + i);
          })
          .catch((error) => {
            console.log(error);
          });
      });
      if (entryCt) {
        prefix.update({ entryNo: entryCt }).then(() => {
          console.log("entry ct:", entryCt);
        });
      }
    })
    .catch((error) => console.log("ERROR ADDING SCORES TO COMPETITION", error));
};
