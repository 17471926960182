import React, { useState, useEffect } from "react";
import Textbox from "../../UI/Forms/Textbox";
import Button from "../../UI/Forms/Button";
import Dropdown from "../../UI/Forms/Dropdown";

const AddEditProject = (props) => {
  const [dd2, setDd2] = useState([]);
  const [project] = useState(props.project ? props.project : "");

  // const handleChange = (selectValue) => {
  //   const dddata = props.projectDD2.filter(
  //     (data) => data.typeid === selectValue
  //   );
  //   console.log(props.projectDD2);
  //   setDd2(dddata);
  // };

  useEffect(() => {
    if (props.project) {
      const dddata = props.projectDD2.filter(
        (data) => data.typeid === props.project.type1
      );
      setDd2(dddata);
    }
  }, [props.project, props.projectDD2]);

  const dropdown2Form = () => {
    if (dd2.length > 0) {
      return (
        <div>
          <Dropdown
            id="id-projType2"
            name="projType2"
            label="Sub Type"
            width="96%"
            value={project.type2 ? project.type2 : ""}
            required={true}
            handleChange={() => true}
            options={dd2}
          />
        </div>
      );
    }
  };
  return (
    <>
      <div>Create a new project by entering the details below.</div>
      <form autoComplete="off" onSubmit={props.handleSave}>
        <div>
          <Dropdown
            id="id-projType"
            name="projType"
            type="text"
            label="Project Type"
            width="96%"
            value={props.project ? props.project.type1 : ""}
            required={true}
            // handleChange={handleChange.bind(this)}
            handleChange={(val) => {
              const dddata = props.projectDD2.filter((dd) => dd.typeid === val);
              setDd2(dddata);
            }}
            options={props.projectDD1}
          />
        </div>
        {dropdown2Form()}
        <div>
          <Textbox
            id="id-title"
            name="title"
            type="text"
            width="96%"
            value={project.title}
            required={true}
            label="Project Name"
            multiline={true}
            InputProps={{ maxLength: 10 }}
          />
        </div>
        <div style={{ float: "right" }}>
          <Button type="submit" buttonType="save">
            Save
          </Button>
          <Button buttonClicked={props.handleClose}>Cancel</Button>
          {props.notebookCt === 0 && (
            <Button buttonClicked={props.handleDelete}>Delete Project</Button>
          )}
        </div>
      </form>
    </>
  );
};

export default AddEditProject;
