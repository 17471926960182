import React, { useState, useEffect } from "react";
import Table from "../../UI/Tables/Table";
import Header from "../../UI/Header/Header";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Alert from "../../UI/Alerts/Alert";
import FullScreenModal from "../../UI/Modals/FullscreenModal";
import Spinner from "../../UI/Spinner/Spinner";
import {
  getAccount,
  getAccountsDetails,
  adminAddNotebooks,
} from "../adminFunctions";
import styles from "./Accounts.module.css";
import ShowAccount from "./ShowAccount";
import { CSVLink } from "react-csv";

const csvHeaders = [
  { label: "Email", key: "email" },
  { label: "First", key: "first" },
  { label: "Last", key: "last" },
  { label: "Org", key: "org" },
  { label: "Org #", key: "number" },
  { label: "Phone", key: "phone" },
  { label: "District", key: "district" },
  { label: "Street", key: "street" },
  { label: "Street2", key: "street2" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "Zip", key: "zip" },
  { label: "Country", key: "country" },
  { label: "# Projs", key: "projectCt" },
  { label: "# Stdnts", key: "studentCt" },
  { label: "# Ntbks", key: "notebookCt" },
  { label: "# Ntbks Purch", key: "purchaseQty" },
  // { label: "", key: "" },
  // { label: "", key: "" },
];

const Accounts = (props) => {
  const [acctData, setAcctData] = useState([]);
  const [acctDataDisplay, setAcctDataDisplay] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [modal, setModal] = useState({
    show: false,
    id: null,
    overview: [],
    data: [],
  });

  useEffect(() => {
    // console.log("accounts useEffect");
    const getData = async () => {
      try {
        const procGetAccounts = await getAccountsDetails();
        setAcctData(procGetAccounts);
        setAcctDataDisplay(procGetAccounts);
        setSpinner(false);
      } catch (error) {
        setSpinner(false);
        console.log("getData ERROR", error);
      }
    };
    getData();
  }, []);

  const closeModal = () => {
    setModal({ show: false, id: null, overview: [], data: [] });
  };

  const openModal = async (id) => {
    try {
      const data = await getAccount(id);
      const getAcctOverview = (aid) => {
        if (aid === null) {
          return null;
        }
        const ttl = acctData.filter((g) => g.id === aid);
        return ttl[0];
      };
      const aov = getAcctOverview(id);
      setModal({ show: true, id: id, overview: aov, data: data });
    } catch (err) {
      console.log("ERR GETTING ACCOUNT", err);
    }
  };

  const admAddNotebooks = async (nbQty) => {
    const nbQtyInt = parseInt(nbQty);
    if (nbQtyInt === 0) {
      return;
    }
    try {
      const res = await adminAddNotebooks(modal.id, nbQtyInt);
      if (res) {
        //update state here
      }
      setModal({ show: false, id: null, overview: [], data: [] });
    } catch (err) {
      console.log(err);
      setModal({ show: false, id: null, overview: [], data: [] });
    }
  };

  const searchOnChange = (val) => {
    const lval = val.toLowerCase();
    let newArr = [];
    if (!lval || lval.length === 0) {
      setAcctDataDisplay(acctData);
      setShowAlert(false);
    } else {
      newArr = acctData.filter(
        (ac) =>
          ac.last.toLowerCase().includes(lval) ||
          ac.first.toLowerCase().includes(lval) ||
          ac.org.toLowerCase().includes(lval) ||
          ac.email.toLowerCase().includes(lval) ||
          ac.number.toLowerCase().includes(lval) ||
          ac.phone.toLowerCase().includes(lval)
      );
      setAcctDataDisplay(newArr);
      setShowAlert(newArr.length === 0);
    }
  };

  const headerData = () => {
    let headers = [
      // {
      //   id: "uid",
      //   numeric: false,
      //   disablePadding: false,
      //   label: "ID",
      // },
      {
        id: "org",
        numeric: false,
        disablePadding: false,
        label: "Org",
        isClickable: true,
      },
      {
        id: "number",
        numeric: false,
        disablePadding: false,
        label: "Org #",
      },
      {
        id: "last",
        numeric: false,
        disablePadding: false,
        label: "Last",
      },
      {
        id: "first",
        numeric: false,
        disablePadding: false,
        label: "First",
      },
      {
        id: "email",
        numeric: false,
        disablePadding: false,
        label: "Email",
      },
      {
        id: "phone",
        numeric: false,
        disablePadding: false,
        label: "Phone",
      },
      {
        id: "studentCt",
        numeric: false,
        disablePadding: false,
        label: "# Stdnts",
      },
      {
        id: "projectCt",
        numeric: false,
        disablePadding: false,
        label: "# Projs",
      },
      {
        id: "notebookCt",
        numeric: false,
        disablePadding: false,
        label: "# Nbks",
      },
      {
        id: "purchaseQty",
        numeric: false,
        disablePadding: false,
        label: "# Nbks Purch",
      },
    ];
    return headers;
  };

  const returnVal = (
    <>
      <FullScreenModal
        show={modal.show}
        header="Account Details"
        handleClose={closeModal}
      >
        <ShowAccount
          overview={modal.overview}
          data={modal.data}
          addNotebooks={(nbQty) => {
            admAddNotebooks(nbQty);
          }}
        />
      </FullScreenModal>
      <Header
        title={
          <>
            <span
              className={styles.breadcrumb}
              onClick={() => props.acctClicked()}
            >
              Admin
            </span>
            <PlayArrowIcon
              style={{
                fontSize: 12,
                margin: "0px 7px",
                color: "#888",
              }}
            />
            <span>Accounts</span>
          </>
        }
        search={true}
        searchPH="Search for anything..."
        handleSearchChange={(searchVal) => {
          searchOnChange(searchVal);
        }}
      />
      <Alert
        showAlert={showAlert}
        close={() => {
          setShowAlert(false);
        }}
        type="Success"
      >
        <span>Search contains no results.</span>
        {/* <span onClick={() => searchOnChange("")}>Reset</span> */}
      </Alert>
      <CSVLink
        data={acctDataDisplay}
        headers={csvHeaders}
        filename="EDEN-Account-Export.csv"
      >
        Download to CSV
      </CSVLink>
      <Table
        dataArr={acctDataDisplay}
        headerData={headerData()}
        fontSize={11}
        handleClick={(id) => openModal(id)}
      />
      {/* <pre>{JSON.stringify(acctDataDisplay, 0, 3)}</pre> */}
    </>
  );

  return <>{spinner ? <Spinner /> : returnVal}</>;
};

export default Accounts;
