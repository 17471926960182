import React, { useState, useEffect } from "react";
import styles from "./Purchases.module.css";
import Textbox from "../../UI/Forms/Textbox";
import Switch from "../../UI/Forms/Switch";
import Button from "../../UI/Forms/Button";

const ShowPurchase = (props) => {
  const [formVals, setFormVals] = useState({
    poApproved: props.data[0].poApproved,
    poNumber: props.data[0].poNumber,
    quantity: props.data[0].quantity,
  });

  useEffect(() => {}, []);
  const displayPage = () => {
    const newDate = new Date(props.data[0].purchaseDT * 1000);
    let day = newDate.getDate();
    if (day < 10) day = "0" + day;
    let month = newDate.getMonth() + 1;
    if (month < 10) month = "0" + month;
    let year = newDate.getFullYear();
    // const invNo = `${props.data[0].userId
    //   .substring(0, 3)
    //   .toUpperCase()}-${year}${month}${day}`;

    const invNo = `${props.data[0].userId.substring(0, 3).toUpperCase()}-${
      props.data[0].purchaseDT
    }`;
    return (
      <>
        {props.data[0].poQuantity && (
          <>
            <div className={styles.pending}>
              {/* <div> */}
              {!props.data[0].poApproved ? (
                <span>
                  This Purchase Order has not been approved. Check to approve PO
                  and add the requested number of notebooks to this account.
                </span>
              ) : (
                <span style={{ color: "green" }}>
                  This Purchase Order has been approved. Notebook credits have
                  been added to this account.
                </span>
              )}
            </div>
            <div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  props.submitForm(
                    props.data[0].userId,
                    props.data[0].id,
                    formVals
                  );
                }}
              >
                <Switch
                  label="Approve PO"
                  //   checked={props.data[0].poApproved}
                  checked={formVals.poApproved}
                  name="poApproved"
                  onChange={(e) => {
                    setFormVals({
                      ...formVals,
                      poApproved: e.target.checked,
                      quantity: e.target.checked ? props.data[0].poQuantity : 0,
                    });
                  }}
                />
                <Textbox
                  name="poNumber"
                  label="PO Number"
                  value={formVals.poNumber}
                  textChange={(name, val) => {
                    setFormVals({ ...formVals, [name]: val });
                  }}
                />
                <Button type="submit">Save</Button>
              </form>
            </div>
            <div className={styles.purchaseContainer}>
              <table className={styles.tblPurchase}>
                <tbody>
                  <tr>
                    <td>Approval Status:</td>
                    <td>{props.data[0].poApproved ? "Approved" : "Pending"}</td>
                  </tr>
                  <tr>
                    <td>Invoice #</td>
                    <td>{invNo}</td>
                  </tr>
                  <tr>
                    <td>Invoice Date</td>
                    <td>{`${month}/${day}/${year}`}</td>
                  </tr>
                  <tr>
                    <td>Purchase Order #:</td>
                    <td>
                      {props.data[0].poNumber.length > 0
                        ? props.data[0].poNumber
                        : "TBD"}
                    </td>
                  </tr>
                  <tr>
                    <td>School/Organization:</td>
                    <td>{props.data[0].poOrg}</td>
                  </tr>
                  <tr>
                    <td>Contact:</td>
                    <td>{props.data[0].poRequester}</td>
                  </tr>
                  <tr>
                    <td>Contact Phone:</td>
                    <td>{props.data[0].phone}</td>
                  </tr>
                  <tr>
                    <td>Contact Email:</td>
                    <td>{props.data[0].email}</td>
                  </tr>
                  <tr>
                    <td>Contact Street Address:</td>
                    <td>{`${props.data[0].poStreet1} ${props.data[0].poStreet2}`}</td>
                  </tr>
                  <tr>
                    <td>Contact City, State, Zip:</td>
                    <td>{`${props.data[0].poCity}, ${props.data[0].poState} ${props.data[0].poZip}`}</td>
                  </tr>
                  <tr>
                    <td>Notebook Quantity Requested:</td>
                    <td>{props.data[0].poQuantity}</td>
                  </tr>
                  <tr>
                    <td>Unit Price:</td>
                    <td>${props.data[0].pricePer}</td>
                  </tr>
                  <tr>
                    <td>Total Amount:</td>
                    <td>${props.data[0].amount}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
        {/* <pre>{JSON.stringify(formVals, 0, 3)}</pre> */}
      </>
    );
  };

  return (
    <div>
      {displayPage()}
      {/* <pre>{JSON.stringify(props, 0, 3)}</pre> */}
    </div>
  );
};

export default ShowPurchase;
