import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAuthContext, useAppContext } from './context';
import { useHistory } from 'react-router-dom';
import { ROUTES } from './global/constants';
import DrawerStudent from './UI/DrawerStudent';
import DrawerStudentNotebook from './UI/DrawerStudentNotebook';
// import Drawer from './UI/Drawer';
// import DrawerNotebook from './UI/DrawerNotebook';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),

		[theme.breakpoints.down('xs')]: {
			maxWidth: '100vw',
		},
	},
}));

function HomeStudent(props) {
	const classes = useStyles();
	const history = useHistory();
	const { setCurrentUser } = useAppContext();
	const { onLogout, teacherId, setTeacherId, userType } = useAuthContext();
	const [drawerWidth] = useState(200);
	const [mobileOpen, setMobileOpen] = useState(false);

	useEffect(() => {
		if (teacherId) return;

		const teacherDocId = window.sessionStorage.getItem('studentTeacherDocID');
		if (teacherDocId) {
			setCurrentUser((pre) => ({ ...pre, teacherDocID: teacherDocId }));
			setTeacherId(teacherDocId);
		}
	}, [teacherId, setCurrentUser, setTeacherId]);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const handleSignOut = async () => {
		await onLogout();
		history.push(ROUTES.users.login.replace(':teacherDocId', teacherId));
	};
	// console.log('UT', userType);
	return (
		<div className={classes.root}>
			{props.isNotebookPage ? (
				<DrawerStudentNotebook
					handleDrawerToggle={handleDrawerToggle}
					mobileOpen={mobileOpen}
					drawerWidth={drawerWidth}
					signOut={handleSignOut}
					steps={props.steps}
					notebookID={props.notebookID}
					projectID={props.projectID}
					passProcessID={(id) => props.passProcessID(id)}
				/>
			) : (
				<DrawerStudent
					handleDrawerToggle={handleDrawerToggle}
					mobileOpen={mobileOpen}
					drawerWidth={drawerWidth}
					signOut={handleSignOut}
					userType={userType}
				/>
			)}
			<main className={classes.content}>
				<div className={classes.toolbar} />
				{props.children}
			</main>
		</div>
	);
}

export default HomeStudent;
