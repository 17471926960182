import React, { useState, useEffect, useCallback } from "react";
import {
  getNotebookURL,
  getNotebookPermissions,
  getNotebookPurchases,
  saveNotebookPermissions,
  getStudentNotebook,
  saveNotebookEdits,
  upgradeNb,
} from "../../global/globalFunctions";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import appBase from "../../Auth/base";
import appSettings from "../../appSettings";
import Button from "../../UI/Forms/Button";
import Switch from "../../UI/Forms/Switch";
import Popover from "../../UI/Modals/Popover";
import Snackbar from "../../UI/Alerts/Snackbar";
import Header from "../../UI/Header/Header";
import Help from "../../UI/Modals/Help";
import Modal from "../../UI/Modals/RegularModal";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
// import { ROUTES } from "../../global/constants";
import nbStyles from "./Project.module.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "#FFF",
      margin: theme.spacing(1),
      width: (props) => props.width,
      borderColor: appSettings.themeColor,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: appSettings.themeColor,
    },
    "& label.Mui-focused": {
      color: appSettings.themeColor,
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: appSettings.themeColor,
      },
      "&:hover fieldset": {
        borderColor: appSettings.themeColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: appSettings.themeColor,
      },
    },
  },
  listRoot: {
    width: "100%",
    maxWidth: 560,
    backgroundColor: theme.palette.background.paper,
  },
}));

const ShowNotebook = (props) => {
  const { studentList } = props;
  const classes = useStyles({ width: props.width });

  const [notebookID] = useState(props.notebookID);
  const [selectedValue, setSelectedValue] = useState(null);
  const [notebookPermissions, setNotebookPermissions] = useState([]);
  const [notebookPurchases, setNotebookPurchases] = useState([]);
  const [notebookInfo, setNotebookInfo] = useState({ isViewable: false });
  const [filteredStudents, setFilteredStudents] = useState(studentList);
  const [studentNotebook, setStudentNotebook] = useState(props.studentNotebook);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    type: "success",
  });
  const [upModal, setUpModal] = useState({ show: false, id: null });

  useEffect(() => {
    const getNBPermissions = async () => {
      try {
        const permissionsArray = await getNotebookPermissions(
          props.teacherDocID,
          notebookID
        );
        setNotebookPermissions(permissionsArray);

        const purchasesArray = await getNotebookPurchases(props.teacherDocID);
        setNotebookPurchases(purchasesArray);

        const notebookObject = await getStudentNotebook(
          props.teacherDocID,
          notebookID
        );
        setNotebookInfo(notebookObject);
      } catch (error) {
        console.log("getNBPermissions catch error", error);
      }
    };
    getNBPermissions();
  }, [props.teacherDocID, notebookID]);

  useEffect(() => {
    if (studentList) {
      const arr = [];
      studentList.forEach((key) => {
        const result = studentNotebook.find(
          ({ studentID }) => studentID === key.id
        );
        if (key.active) {
          //only display active students in selections
          if (!result) {
            arr.push(key);
          } else {
            arr.push({ ...key, disabled: true });
          }
        }
      });
      setFilteredStudents(arr);
    }
  }, [studentList, props.studentNotebook]); //eslint-disable-line

  const selectedStudents = useCallback(
    () =>
      studentNotebook
        .filter((data) => data.notebookID === notebookID)
        .map((key, index) => {
          const result = props.studentList.find(
            ({ id }) => id === key.studentID
          );
          // return <div key={index}>{result.last + ", " + result.first}</div>;
          return (
            // <div key={index} className={nbStyles.teamMembers}>
            <div key={index}>
              <ListItem button>
                <Popover
                  clickObject={
                    result &&
                    `${result.last && result.last}, "${
                      result.first && result.first
                    } (${result.email && result.email})`
                  }
                  label={`Remove ${result.first && result.first} ${
                    result.last && result.last
                  }?`}
                  tooltipRemove={`Remove ${result.first && result.first} ${
                    result.last && result.last
                  } from this team.`}
                  handleDelete={() => {
                    appBase
                      .firestore()
                      .collection(
                        "users/" + props.teacherDocID + "/student-notebook"
                      )
                      .doc(key.id)
                      .delete()
                      .then(() => {
                        // console.log("deleted success");
                        setSnackbar({
                          open: true,
                          message:
                            result.first +
                            " " +
                            result.last +
                            " successfully removed from this team.",
                          severity: "success",
                        });
                      })
                      .catch((error) => {
                        console.log(
                          "ERROR DELETING STUDENT FROM NOTEBOOK",
                          error
                        );
                      });
                    setStudentNotebook(
                      studentNotebook.filter((data) => data.id !== key.id)
                    );
                    // console.log("delete", id);
                    // const newArr = studentNotebook.filter((item) => item.id !== id);
                    // console.log(newArr);
                  }}
                />
                {/* </div> */}
              </ListItem>
              <Divider />
            </div>
          );
        }),
    [studentNotebook, notebookID, props.studentList, props.teacherDocID]
  );

  const openURLLink = () => {
    if (!notebookInfo.isViewable) {
      return null;
    }
    // let pathname = ROUTES.teachers.openurl.replace(
    //   ":teacherid",
    //   props.teacherDocID
    // );
    // pathname = pathname.replace(":notebookid", props.notebookID);
    // const fullURL = `${window.location.origin}${pathname}`;
    const fullURL = getNotebookURL(props.teacherDocID, props.notebookID);
    return (
      <div style={{ marginBottom: 30 }}>
        <div style={{ marginTop: 5, marginLeft: 42 }}>
          <a
            className={nbStyles.copyLink}
            href={fullURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Open Notebook Link
          </a>
          <span
            className={nbStyles.copyLink}
            onClick={() => {
              navigator.clipboard.writeText(fullURL);
            }}
          >
            Copy Link to Clipboard
          </span>
        </div>
        <div>
          <Switch
            checked={notebookInfo.isCommentable}
            onChange={(event) => {
              // p.isActive = event.target.checked;
              const isCommentable = event.target.checked;
              saveNotebookEdits(props.teacherDocID, notebookID, {
                isCommentable: isCommentable,
              });
              setNotebookInfo({
                ...notebookInfo,
                isCommentable: isCommentable,
              });
            }}
            id="id-commentable"
            name="isCommentable"
            label={"Enable Judges' Notes on Shared Notebook"}
          />
        </div>
      </div>
    );
  };

  const closeUpModal = () => {
    setUpModal({ show: false });
  };

  const upgradeNotebook = () => {
    upgradeNb(props.teacherDocID, notebookID);
    setNotebookInfo({
      ...notebookInfo,
      isTrial: false,
    });
    closeUpModal();
  };

  return (
    <>
      <Header
        title={<span style={{ fontWeight: 500 }}>{notebookInfo.title}</span>}
        search={false}
        editTitle={"Edit Notebook"}
        editClick={() =>
          props.editNotebook({
            id: notebookID,
            title: notebookInfo.title,
            group: notebookInfo.group ? notebookInfo.group : null,
          })
        }
      />
      {notebookInfo.isTrial && (
        <>
          <Modal
            show={upModal.show}
            header={"Upgrade Notebook"}
            handleClose={closeUpModal}
          >
            <div>
              You have
              <strong>
                {` ${notebookPurchases.remainingQty} EDEN Notebook Credit${
                  notebookPurchases.remainingQty !== 1 ? "s" : ""
                } `}
              </strong>
              in your account. Please click below to deduct one credit from your
              account and convert this to a fully functional notebook. Please
              note, this action cannot be undone.
            </div>
            <div>
              <Button buttonClicked={upgradeNotebook}>Upgrade Notebook</Button>
              <Button
                type="button"
                id="btn-done"
                name="btn-done"
                buttonType="delete"
                buttonClicked={closeUpModal}
              >
                Cancel
              </Button>
            </div>
          </Modal>
          <div
            style={{
              background: "#ffefea",
              padding: 8,
              color: "darkred",
              border: "1px solid darkred",
              fontWeight: "bold",
            }}
          >
            {`This is a Trial Notebook with a limit of 15 entries. `}
            {notebookPurchases.remainingQty <= 0 && (
              <>
                If you wish to convert this to a fully functional notebook,
                <Link
                  style={{
                    textDecoration: "none",
                    color: "blue",
                    fontWeight: "bold",
                  }}
                  to={"/teacher/account"}
                >
                  {` Go Here `}
                </Link>
                to make a purchase.
              </>
            )}
            {notebookPurchases.remainingQty > 0 && (
              <>
                <span
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setUpModal({ show: true });
                  }}
                >
                  {`Click Here `}
                </span>
                to upgrade this to a fully functional notebook.
              </>
            )}
          </div>
        </>
      )}
      <div style={{ marginBottom: 10 }}>
        <Switch
          checked={notebookInfo.isViewable}
          onChange={(event) => {
            // p.isActive = event.target.checked;
            const isViewable = event.target.checked;
            saveNotebookEdits(props.teacherDocID, notebookID, {
              isViewable: isViewable,
            });
            setNotebookInfo({
              ...notebookInfo,
              isViewable: isViewable,
            });
          }}
          id="id-viewable"
          name="isViewable"
          label={
            <>
              <Help
                helpId="3AOytgq5HUSPIiPh9Amv"
                preText="Notebook Sharing is Active"
              />
            </>
          }
        />
        {openURLLink()}
      </div>
      <div>
        <Help
          helpId="b3S4wdzf2XpxL9doCe7J"
          preText="Options for Enabling Entries"
        />
      </div>
      {/* <hr /> */}
      {notebookPermissions.map((p) => {
        return (
          <div key={p.id}>
            <Switch
              checked={p.isActive}
              onChange={(event) => {
                p.isActive = event.target.checked;
                saveNotebookPermissions(props.teacherDocID, notebookID, p.id, {
                  isActive: p.isActive,
                });
                setNotebookPermissions([...notebookPermissions]);
              }}
              id="id-active"
              name="isActive"
              label={"Enable Entries in " + p.title}
            />
          </div>
        );
      })}

      <Autocomplete
        // blurOnSelect={true}
        className={classes.root}
        id="student-select"
        key={true}
        value={selectedValue}
        options={filteredStudents}
        getOptionDisabled={(option) => option.disabled}
        disableClearable={false}
        onChange={(event, value) => {
          // console.log(value);
          appBase
            .firestore()
            .collection("users/" + props.teacherDocID + "/student-notebook")
            .add({
              notebookID: notebookID,
              projectID: props.projectID,
              studentID: value.id,
            })
            .then((response) => {
              setSnackbar({
                open: true,
                message:
                  value.first +
                  " " +
                  value.last +
                  " successfully added to this team.",
                severity: "success",
              });
              const filterDownStudents = filteredStudents.filter(
                (data) => data.id !== value.id
              );
              setFilteredStudents(filterDownStudents);
              setStudentNotebook([
                {
                  id: response.id,
                  notebookID: notebookID,
                  projectID: props.projectID,
                  studentID: value.id,
                },
                ...studentNotebook,
              ]);
              setSelectedValue(null);
            })
            .catch((error) => {
              console.log(error);
            });
        }}
        getOptionLabel={(option) =>
          option.last + ", " + option.first + " (" + option.email + ")"
        }
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label={props.label} />}
      />

      <Button
        type="button"
        id="btn-done"
        name="btn-done"
        buttonClicked={props.handleClose}
      >
        Done
      </Button>
      <p>
        <strong>Team Members: </strong>Click on Team Member Name for options.
      </p>
      <List
        component="nav"
        className={classes.listRoot}
        aria-label="mailbox folders"
      >
        {selectedStudents()}
      </List>

      <Snackbar
        open={snackbar.open}
        close={() => {
          setSnackbar({
            open: false,
            message: "",
            severity: snackbar.severity,
          });
        }}
        message={snackbar.message}
        severity={snackbar.severity}
      />
      {/* <pre>purchases: {JSON.stringify(notebookPurchases, null, 3)}</pre> */}
      {/* <pre>permissions: {JSON.stringify(notebookPermissions, null, 3)}</pre> */}
      {/* <pre>props: {JSON.stringify(props, null, 3)}</pre> */}
      {/* <pre>filteredStudents: {JSON.stringify(filteredStudents, null, 3)}</pre> */}
      {/* <pre>notebookInfo: {JSON.stringify(notebookInfo, null, 3)}</pre> */}
      {/* <pre>studentNotebook: {JSON.stringify(studentNotebook, null, 3)}</pre> */}
    </>
  );
};
export default ShowNotebook;
