import React, { useState, useEffect } from 'react';
import { helpTypes, subCategory } from '../../global/globalFunctions';
import Table from '../../UI/Tables/Table';
import Header from '../../UI/Header/Header';
import Alert from '../../UI/Alerts/Alert';
import Spinner from '../../UI/Spinner/Spinner';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import AddEditHelp from './AddEditHelp';
import { getHelpFiles, addNewHelpFile, updateHelpFile } from '../adminFunctions';
import FullScreenModal from '../../UI/Modals/FullscreenModal';

const getCatTitle = (id) => {
	if (id === null) {
		return null;
	}
	const ttl = helpTypes.filter((g) => g.id === id);
	return ttl[0].title;
};

const Accounts = (props) => {
	const [showAlert, setShowAlert] = useState(false);
	const [spinner, setSpinner] = useState(true);
	const [modalNew, setModalNew] = useState({
		show: false,
	});
	const [helpArray, setHelpArray] = useState([]);

	useEffect(() => {
		const initHelpFiles = async () => {
			try {
				const response = await getHelpFiles();
				const modHelpFiles = response.map((el) => ({
					...el,
					categoryName: getCatTitle(el.category),
				}));

				setHelpArray(modHelpFiles);
				setSpinner(false);
			} catch (error) {
				setSpinner(false);
				console.log('initHelpFiles catch error', error);
			}
		};
		initHelpFiles();
	}, []);

	const editHelp = (id) => {
		const filteredHelp = helpArray.filter((g) => g.id === id);
		setModalNew({ show: true, type: 'edit', data: filteredHelp });
	};

	const headerData = () => {
		let headers = [
			{
				id: 'title',
				numeric: false,
				disablePadding: false,
				label: 'Title',
				isClickable: true,
			},
			{
				id: 'id',
				numeric: false,
				disablePadding: false,
				label: 'ID',
			},
			{
				id: 'categoryName',
				numeric: false,
				disablePadding: false,
				label: 'Type',
			},
			{
				id: 'subcategory',
				numeric: false,
				disablePadding: false,
				label: 'Catg',
			},
			{
				id: 'seq',
				numeric: true,
				disablePadding: false,
				label: 'Seq',
				isClickable: false,
			},
			{
				id: 'isFAQ',
				numeric: false,
				disablePadding: false,
				label: 'FAQ',
				isBool: true,
			},
			{
				id: 'createdDT',
				numeric: false,
				disablePadding: false,
				label: 'Created',
				isDate: true,
			},
		];
		return headers;
	};

	const closeModalNew = () => {
		setModalNew({ show: false });
	};

	const returnVal = (
		<>
			<FullScreenModal show={modalNew.show} header="New Help File" handleClose={closeModalNew}>
				<AddEditHelp
					handleClose={closeModalNew}
					data={modalNew.data}
					type={modalNew.type}
					options={helpTypes}
					catOptions={subCategory}
					handleSave={(helpData) => {
						closeModalNew();
						const addHelpFile = async () => {
							try {
								if (helpData.type === 'new') {
									const dateAdd = new Date();

									const addedHelpFile = {
										title: helpData.title,
										summary: helpData.summary,
										category: helpData.category,
										subcategory: helpData.subcategory,
										seq: helpData.seq,
										html: helpData.html,
										isFAQ: helpData.isFAQ,
										videoUrl: helpData.videoUrl,
										createdDT: dateAdd,
									};
									// console.log(addedHelpFile);
									const newHelpFileID = await addNewHelpFile(addedHelpFile);
									// console.log("id", newHelpFileID);
									const addedHelpFileMOD = {
										id: newHelpFileID,
										...addedHelpFile,
										categoryName: getCatTitle(addedHelpFile.category),
										createdDT: Math.round(dateAdd / 1000),
									};
									setHelpArray([addedHelpFileMOD, ...helpArray]);
								}
								if (helpData.type === 'edit') {
									const updatedHelpFile = {
										title: helpData.title,
										seq: helpData.seq,
										summary: helpData.summary,
										category: helpData.category,
										subcategory: helpData.subcategory,
										html: helpData.html,
										isFAQ: helpData.isFAQ,
										videoUrl: helpData.videoUrl,
									};
									// console.log(updatedHelpFile);
									const didUpdate = await updateHelpFile(helpData.id, updatedHelpFile);
									if (didUpdate) {
										const updatedHelpArray = helpArray.map((el) =>
											el.id === helpData.id
												? {
														...el,
														...updatedHelpFile,
														categoryName: getCatTitle(helpData.category),
												  }
												: el
										);
										setHelpArray(updatedHelpArray);
									}
								}
							} catch (error) {
								console.log('error adding help file', error);
							}
						};
						addHelpFile();
					}}
				/>
			</FullScreenModal>
			<Header
				title={
					<>
						<span
							style={{
								color: 'maroon',
								fontWeight: '500',
								cursor: 'pointer',
							}}
							onClick={() => props.acctClicked()}
						>
							Admin
						</span>
						<PlayArrowIcon
							style={{
								fontSize: 12,
								margin: '0px 7px',
								color: '#888',
							}}
						/>
						<span>Help Files</span>
					</>
				}
				libraryAddTitle="Add New Help File"
				libraryAddClick={() => setModalNew({ show: true, type: 'new' })}
				search={true}
				searchPH="Search for anything..."
				handleSearchChange={(searchVal) => {
					// searchOnChange(searchVal);
				}}
			/>
			<Alert
				showAlert={showAlert}
				close={() => {
					setShowAlert(false);
				}}
				type="Success"
			>
				<span>Search contains no results.</span>
				{/* <span onClick={() => searchOnChange("")}>Reset</span> */}
			</Alert>
			<Table
				dataArr={helpArray}
				headerData={headerData()}
				handleClick={(id) => editHelp(id)}
				fontSize={12}
				defaultSort={{ orderBy: 'subcategory', order: 'asc' }}
			/>
			{/* <pre>{JSON.stringify(helpArray, 0, 3)}</pre> */}
		</>
	);

	return <>{spinner ? <Spinner /> : returnVal}</>;
};

export default Accounts;
