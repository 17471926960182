import React from 'react';
import FullscreenModal from '../../UI/Modals/FullscreenModal';
import ReactHtmlParser from 'react-html-parser';
import nbStyles from './Notebook.module.css';
// import { Link } from "react-router-dom";
// import { useHistory } from "react-router-dom";

const EntryModal = (props) => {
	return (
		<FullscreenModal header={`Entry #${props.entry.entryNo}`} show={props.openModal} handleClose={props.handleClose}>
			<div>
				{props.entryDiscussion
					.sort((a, b) => b.createdDT.seconds - a.createdDT.seconds)
					.map((discuss, index) => {
						//question mark before .map creates "if exists" argument
						return (
							<div className={nbStyles.studentComment} key={index}>
								<div className={nbStyles.feedbackHeading}>
									{`${discuss.studentName} wrote on ${new Date(discuss.createdDT.seconds * 1000).toDateString()}:`}
								</div>
								{discuss.comment}
							</div>
						);
					})}
				{props.showTeacherFeedback &&
					props.entry.teacherFeedback?.map((feedback, index) => {
						//question mark before .map creates "if exists" argument
						return (
							<div className={nbStyles.teacherFeedback} key={index}>
								<div className={nbStyles.feedbackHeading}>Teacher Feedback:</div>
								{feedback}
							</div>
						);
					})}
				{ReactHtmlParser(props.entry.html)}
			</div>
		</FullscreenModal>
	);
};

export default EntryModal;
