import React from "react";
import Textbox from "../../UI/Forms/Textbox";
import Button from "../../UI/Forms/Button";

const AddEditStudents = (props) => {
  return (
    <>
      <div>
        Team Members may choose to self-register, or you may register Team
        Members on their behalf by completing the form below.
      </div>
      <form autoComplete="off" onSubmit={props.handleSave}>
        <div>
          <Textbox
            id="id-first"
            name="first"
            type="text"
            width="96%"
            required={true}
            label="First Name"
            autoComplete="new-user-street-address"
          />
        </div>
        <div>
          <Textbox
            id="id-last"
            name="last"
            type="text"
            width="96%"
            required={true}
            label="Last Name"
            autoComplete="new-user-street-address"
          />
        </div>
        <div>
          <Textbox
            id="id-email"
            name="email"
            type="email"
            width="96%"
            required={true}
            label="E-mail Address"
            autoComplete="new-user-street-address"
          />
        </div>
        <div>
          <Textbox
            id="id-school"
            name="school"
            type="text"
            width="96%"
            required={true}
            label="Student School"
            autoComplete="new-user-street-address"
          />
        </div>
        {/* <div>
          <Textbox
            id="id-password"
            name="password"
            // name={"password-" + randomNo()}
            type="text"
            width="96%"
            required={true}
            label="Student Password"
            autoComplete="new-password"
          />
        </div> */}
        <div style={{ float: "right" }}>
          <Button type="submit" buttonType="save">
            Save
          </Button>
          <Button buttonClicked={props.handleClose}>Cancel</Button>
        </div>
      </form>
    </>
  );
};

export default AddEditStudents;
