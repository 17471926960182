import React from 'react';
import { useAuthContext } from '../../context';
import Students from '../../Pages/Students/StudentsContent';

const StudentsMentor = () => {
	const { teacherId, userType } = useAuthContext();

	return <Students teacherDocId={teacherId} userType={userType} />;
};

export default StudentsMentor;
