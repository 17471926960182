import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}

	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
	const { classes, order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{props.headerData.map((headCell) => {
					return (
						<TableCell
							key={headCell.id}
							align={headCell.numeric ? 'right' : 'left'}
							padding={headCell.disablePadding ? 'none' : 'normal'}
							sortDirection={orderBy === headCell.id ? order : false}
						>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : 'asc'}
								onClick={createSortHandler(headCell.id)}
								className={classes.tableCells}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<span className={classes.visuallyHidden}>
										{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
									</span>
								) : null}
							</TableSortLabel>
						</TableCell>
					);
				})}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const TableExport = (props) => {
	const useStyles = makeStyles((theme) => ({
		root: {
			width: '100%',

			[theme.breakpoints.down('xs')]: {
				maxWidth: '100vw',
			},
		},
		paper: {
			width: '100%',
			marginBottom: theme.spacing(2),
		},
		table: {
			minWidth: 300,

			[theme.breakpoints.down('xs')]: {
				minWidth: 'unset',
				maxWidth: '100vw',
			},
		},
		visuallyHidden: {
			border: 0,
			clip: 'rect(0 0 0 0)',
			height: 1,
			margin: -1,
			overflow: 'hidden',
			padding: 0,
			position: 'absolute',
			top: 20,
			width: 1,
		},
		tableCells: {
			fontSize: props.fontSize ? props.fontSize : 14,
		},
	}));
	const classes = useStyles();
	const [order, setOrder] = React.useState(props.defaultSort ? props.defaultSort.order : 'asc');
	const [orderBy, setOrderBy] = React.useState(props.defaultSort ? props.defaultSort.orderBy : 'title');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(
		// props.args.showAll
		//   ? props.dataArr.length
		//   : props.dataArr.length >= 25
		//   ? 25
		//   : 10

		props.dataArr.length && props.dataArr.length >= 25 ? 25 : 10
	);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'desc';
		setOrder(isAsc ? 'asc' : 'desc');
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// const emptyRows =
	//   rowsPerPage -
	//   Math.min(rowsPerPage, props.dataArr.length - page * rowsPerPage);

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<TableContainer>
					<Table className={classes.table} aria-labelledby="tableTitle" size="small" aria-label="enhanced table">
						<EnhancedTableHead
							classes={classes}
							headerData={props.headerData}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={props.dataArr.length}
						/>
						<TableBody>
							{stableSort(props.dataArr, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									return (
										<TableRow hover tabIndex={-1} key={index}>
											{props.headerData.map((headCell, hIndex) => {
												// console.log("hc", headCell);
												// console.log("row", row);
												return (
													<TableCell className={classes.tableCells} scope="row" padding="normal" key={hIndex}>
														{headCell.isDate ? (
															new Date(row[headCell.id] * 1000).toDateString()
														) : headCell.isClickable ? (
															<span
																style={{ cursor: 'pointer' }}
																onClick={() => {
																	if (props.returnAllRowData) {
																		props.handleClick(row);
																	} else {
																		props.handleClick(row.id);
																	}
																}}
															>
																{row[headCell.id]}
															</span>
														) : headCell.isBool && row[headCell.id] ? (
															<CheckCircleIcon style={{ fontSize: 18, color: '#AAAAAA' }} />
														) : headCell.format && headCell.formatArg === row[headCell.id] ? (
															<span style={headCell.format}>{row[headCell.id]}</span>
														) : (
															row[headCell.id]
														)}
													</TableCell>
												);
											})}
										</TableRow>
										// return (

										//   <TableRow hover tabIndex={-1} key={index}>
										//     <TableCell scope="row" padding="default">
										//       {row.title}
										//     </TableCell>
										//   </TableRow>
										// );
									);
								})}
							{/* {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
						</TableBody>
					</Table>
				</TableContainer>
				{/* {!props.args.showAll && ( */}
				<TablePagination
					rowsPerPageOptions={[10, 25, 50]}
					component="div"
					count={props.dataArr.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
				{/* )} */}
			</Paper>
		</div>
	);
};
export default TableExport;
