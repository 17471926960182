import React, { useState, useEffect } from "react";
import Home from "../../Home";
import Header from "../../UI/Header/Header";
import Spinner from "../../UI/Spinner/Spinner";
import { getDocID, getPurchase } from "../../global/globalFunctions";
import Invoice from "./Invoice";
import styles from "./Account.module.css";

const OpenPo = (props) => {
  const [spinner, setSpinner] = useState(true);
  const [purchase, setPurchase] = useState([]);
  useEffect(() => {
    const asPurchase = async () => {
      const teacherDocID = await getDocID();
      const procPurchase = await getPurchase(
        teacherDocID,
        props.match.params.purchaseid
      );
      setPurchase(procPurchase);
      setSpinner(false);
    };
    asPurchase();
  }, [props.match.params.purchaseid]);

  const displayPage = () => {
    const newDate = new Date(purchase.purchaseDT.seconds * 1000);
    let day = newDate.getDate();
    if (day < 10) day = "0" + day;
    let month = newDate.getMonth() + 1;
    if (month < 10) month = "0" + month;
    let year = newDate.getFullYear();
    const invNo = `${purchase.teacherDocID.substring(0, 3).toUpperCase()}-${
      purchase.purchaseDT.seconds
    }`;
    return (
      <>
        <Header
          crumbtrail="Account"
          crumbtrailTo="/teacher/account"
          title={
            <span style={{ fontWeight: 500 }}>
              {purchase.poQuantity ? "Purchase Order" : "Purchase"}
            </span>
          }
        />
        {purchase.poQuantity && (
          <>
            <div className={styles.pending}>
              {!purchase.poApproved ? (
                <span>
                  The approval of this Purchase Order is pending. Once approved,
                  you will be credited with the requested number of notebooks.
                </span>
              ) : (
                <span style={{ color: "green" }}>
                  This Purchase Order has been approved! Your notebook credits
                  have been added to your account.
                </span>
              )}
            </div>
            <p>
              Please click the buttons below to download your preliminary
              invoice and a copy of HeatCorpSTEM's form W-9 (if needed).
            </p>
            <Invoice poData={purchase} />
            <div className={styles.purchaseContainer}>
              <table className={styles.tblPurchase}>
                <tbody>
                  <tr>
                    <td>Invoice #</td>
                    <td>{invNo}</td>
                  </tr>
                  <tr>
                    <td>Invoice Date</td>
                    <td>{`${month}/${day}/${year}`}</td>
                  </tr>
                  <tr>
                    <td>School/Organization:</td>
                    <td>{purchase.poOrg}</td>
                  </tr>
                  <tr>
                    <td>Contact:</td>
                    <td>{purchase.poRequester}</td>
                  </tr>
                  <tr>
                    <td>Purchase Order #:</td>
                    <td>
                      {purchase.poNumber.length > 0 ? purchase.poNumber : "TBD"}
                    </td>
                  </tr>
                  <tr>
                    <td>Approval Status:</td>
                    <td>{purchase.poApproved ? "Approved" : "Pending"}</td>
                  </tr>
                  <tr>
                    <td>Notebook Quantity Requested:</td>
                    <td>{purchase.poQuantity}</td>
                  </tr>
                  <tr>
                    <td>Unit Price:</td>
                    <td>${purchase.pricePer}</td>
                  </tr>
                  <tr>
                    <td>Total Amount:</td>
                    <td>${purchase.amount}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
        {/* <pre>{JSON.stringify(purchase, 0, 3)}</pre> */}
      </>
    );
  };

  return <Home>{spinner ? <Spinner /> : displayPage()}</Home>;
};

export default OpenPo;
