import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpIcon from '@material-ui/icons/Help';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeOutlined from '@material-ui/icons/HomeOutlined';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import MenuBook from '@material-ui/icons/MenuBook';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Person from '@material-ui/icons/Person';
import BuildIcon from '@material-ui/icons/Build';
import Logo from '../img/nav-logo.png';
import appSettings from '../appSettings';
import { Link } from 'react-router-dom';
import { ROUTES } from '../global/constants/routes';
import { useAuthContext, useAppContext } from '../context';

const useStyles = makeStyles((theme) => ({
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: (props) => props.drawerWidth,
			flexShrink: 0,
		},
	},
	heading: {
		color: appSettings.themeColor,
		paddingLeft: 10,
		fontSize: 14,
		padding: 5,
		backgroundColor: '#F5F5F5',
		borderBottom: '1px solid #DDD',
		borderTop: '1px solid #DDD',
	},
	listItem: {
		padding: '5px 0 5px 10px',
	},
	listItemIcon: {
		color: appSettings.themeColor,
		minWidth: 40,
	},
	drawerPaper: {
		width: (props) => props.drawerWidth,
	},
	toolbar: {
		textAlign: 'center',
		paddingTop: 17,
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
}));

const DrawerExport = (props) => {
	const { window } = props;
	const { mobileDrawerOpen, toggleDrawer } = useAppContext();
	const classes = useStyles({ drawerWidth: props.drawerWidth });
	const theme = useTheme();
	const { user } = useAuthContext();

	const drawer = (
		<div>
			<div className={classes.toolbar}>
				<img src={Logo} alt="logo" height="45" />
			</div>
			<Divider />
			<List>
				<ListItem button component={Link} to={ROUTES.teachers.home} className={classes.listItem}>
					<ListItemIcon className={classes.listItemIcon}>
						<HomeOutlined />
					</ListItemIcon>
					<ListItemText primary="Home" />
				</ListItem>

				<ListItem button component={Link} to={ROUTES.teachers.members} className={classes.listItem}>
					<ListItemIcon className={classes.listItemIcon}>
						<PeopleAltIcon />
					</ListItemIcon>
					<ListItemText primary="Team Members" />
				</ListItem>

				<ListItem button component={Link} to={ROUTES.teachers.projects} className={classes.listItem}>
					<ListItemIcon className={classes.listItemIcon}>
						<MenuBook />
					</ListItemIcon>
					<ListItemText primary="Projects" />
				</ListItem>
			</List>

			<Divider />

			<List>
				<ListItem button component={Link} to={ROUTES.teachers.account} className={classes.listItem}>
					<ListItemIcon className={classes.listItemIcon}>
						<FolderSpecialIcon />
					</ListItemIcon>
					<ListItemText primary="Account" />
				</ListItem>

				<ListItem button component={Link} to={ROUTES.teachers.profile} className={classes.listItem}>
					<ListItemIcon className={classes.listItemIcon}>
						<Person />
					</ListItemIcon>
					<ListItemText primary="Profile" />
				</ListItem>

				{((user && user.uid === 'EjUhKS1konSFxJGPI2LeFhVjFOP2') ||
					(user && user.uid === 'Cr4Avj1qE1bzUxx8eVhpz9IidyG3') ||
					(user && user.uid === 'AoqBo9QzsIPjgZCkgCbkzVDMyAO2')) && (
					<ListItem button component={Link} to={ROUTES.teachers.adminhome} className={classes.listItem}>
						<ListItemIcon className={classes.listItemIcon}>
							<BuildIcon />
						</ListItemIcon>
						<ListItemText primary="Admin" />
					</ListItem>
				)}
				<ListItem button className={classes.listItem} component={Link} to={ROUTES.teachers.help}>
					<ListItemIcon className={classes.listItemIcon}>
						<HelpIcon />
					</ListItemIcon>
					<ListItemText primary="Help / FAQ" />
				</ListItem>
				<ListItem button className={classes.listItem} onClick={props.signOut} component={Link} to={ROUTES.base}>
					<ListItemIcon className={classes.listItemIcon}>
						<ExitToAppIcon />
					</ListItemIcon>
					<ListItemText primary="Sign Out" />
				</ListItem>
			</List>
			<Divider />
		</div>
	);

	const container = window !== undefined ? () => window().document.body : undefined;

	return (
		<nav className={classes.drawer} aria-label="mailbox folders">
			<Hidden smUp implementation="css">
				<Drawer
					container={container}
					variant="temporary"
					anchor={theme.direction === 'rtl' ? 'right' : 'left'}
					open={mobileDrawerOpen}
					onClick={toggleDrawer}
					onClose={toggleDrawer}
					classes={{
						paper: classes.drawerPaper,
					}}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
				>
					{drawer}
				</Drawer>
			</Hidden>
			<Hidden xsDown implementation="css">
				<Drawer
					classes={{
						paper: classes.drawerPaper,
					}}
					variant="permanent"
					open
				>
					{drawer}
				</Drawer>
			</Hidden>
		</nav>
	);
};

export default DrawerExport;
