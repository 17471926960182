import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import appSettings from "../../appSettings";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    color: appSettings.appbarColor,
    backgroundColor: appSettings.appbarBG,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const FullScreenModal = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        fullScreen
        open={props.show}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="inherit" className={classes.title}>
              {props.header}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div style={{ marginTop: "25px" }}>{props.children}</div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default FullScreenModal;
