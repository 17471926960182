import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import CommentIcon from "@material-ui/icons/Comment";
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Cancel";
import FilterListIcon from "@material-ui/icons/FilterList";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import styles from "./Header.module.css";
import appSettings from "../../appSettings";
// import Dropdown from "../Forms/Dropdown";

const Header = (props) => {
  const [searchEntry, setSearchEntry] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const searchRef = useRef();
  const clearSearch = props.clearSearch;

  useEffect(() => {
    if (clearSearch) {
      setSearchEntry("");
      searchRef.current.focus();
    }
  }, [clearSearch]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleFilterSelect = (id) => {
    props.handleFilterSelect(id);
    handleFilterClose();
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const searchOnChange = (e) => {
    const val = e.target.value;
    props.handleSearchChange(val);
    setSearchEntry(val);
  };

  const searchOnReset = () => {
    props.handleSearchChange("");
    setSearchEntry("");
    searchRef.current.focus();
  };

  return (
    <React.Fragment>
      <Paper
        component="form"
        style={{
          margin: "0 0 15px 0px",
          padding: "0px 4px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginLeft: 5,
          }}
        >
          {props.crumbtrailTo && (
            <>
              <Link
                className={styles.Link}
                style={{ fontSize: 14 }}
                to={props.crumbtrailTo}
              >
                {props.crumbtrail}
              </Link>
              <PlayArrowIcon
                style={{
                  fontSize: 12,
                  margin: "0px 7px",
                  color: "#888",
                }}
              />
            </>
          )}
          {props.title}
        </div>
        <div>{props.help}</div>
        <Divider
          orientation="vertical"
          style={{ height: 28, marginLeft: 20 }}
        />
        {props.editTitle ? (
          <Tooltip title={props.editTitle}>
            <IconButton
              color="default"
              style={{ padding: 10, margin: "0, 5px" }}
              onClick={props.editClick}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}
        {props.fullscreenTitle ? (
          <Tooltip title={props.fullscreenTitle}>
            <IconButton
              color="default"
              style={{ padding: 10, margin: "0, 5px" }}
              onClick={props.fullscreenClick}
            >
              <FullscreenIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}
        {props.studentDiscussion && (
          <Tooltip title={props.studentDiscussion}>
            <IconButton
              color="default"
              style={{ padding: 10, margin: "0, 5px" }}
              onClick={props.studentDiscussionClick}
            >
              <CommentIcon />
            </IconButton>
          </Tooltip>
        )}
        {props.libraryAddTitle ? (
          <>
            <Divider
              orientation="vertical"
              style={{ height: 28, margin: "0,10" }}
            />
            <Tooltip title={props.libraryAddTitle}>
              <IconButton
                color="default"
                style={{ padding: 10, margin: "0, 5px" }}
                onClick={props.libraryAddClick}
              >
                <LibraryAddIcon />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          ""
        )}
        {props.filterTitle ? (
          <>
            <Tooltip title={props.filterTitle}>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                style={
                  props.filteredNotebook.id ? { background: "#e5f5ff" } : {}
                }
              >
                <FilterListIcon
                  style={
                    props.filteredNotebook.id
                      ? { color: appSettings.secondaryColor }
                      : {}
                  }
                />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleFilterClose}
            >
              {props.filterOptions?.map((o, i) => {
                return (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      handleFilterSelect(o.id);
                    }}
                    style={
                      props.filteredNotebook.id === o.id
                        ? {
                            background: "#e5f5ff",
                            color: appSettings.secondaryColor,
                          }
                        : {}
                    }
                  >
                    {o.title}
                  </MenuItem>
                );
              })}
            </Menu>
          </>
        ) : (
          ""
        )}
        {props.search ? (
          <>
            <InputBase
              inputRef={searchRef}
              placeholder={props.searchPH ? props.searchPH : "Search"}
              inputProps={{ "aria-label": "search contact name" }}
              style={{ marginLeft: 100 }}
              onChange={(e) => searchOnChange(e)}
              value={searchEntry}
            />
            {searchEntry.length === 0 ? (
              <SearchIcon style={{ color: "#777777" }} />
            ) : (
              <IconButton style={{ padding: 10 }} onClick={searchOnReset}>
                <CancelIcon style={{ color: "#8a1212" }} />
              </IconButton>
            )}
          </>
        ) : (
          ""
        )}
      </Paper>
    </React.Fragment>
  );
};

export default Header;
