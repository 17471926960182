import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import appSettings from "../../appSettings";
import SaveIcon from "@material-ui/icons/Save";
import AddSharp from "@material-ui/icons/AddSharp";
import StrikethroughSIcon from "@material-ui/icons/StrikethroughS";

const ButtonExport = (props) => {
  const [buttonColor, setButtonColor] = useState(appSettings.themeColor);
  useEffect(() => {
    if (props.buttonType === "delete") {
      setButtonColor(appSettings.themeColor2);
    }
    if (props.buttonType === "secondary") {
      setButtonColor(appSettings.secondaryColor);
    }
  }, [props.buttonType]);

  const useStyles = makeStyles((theme) => ({
    root: {
      margin: theme.spacing(1),
      padding: "2px 10px",
      color:
        props.buttonType === "secondary"
          ? "#FFFFFF"
          : theme.palette.getContrastText(buttonColor),
      backgroundColor: props.disabled ? "#EEEEEE" : buttonColor,
      "&:hover": {
        backgroundColor: buttonColor,
      },
      tonalOffset: 0.2,
    },
  }));

  const buttonType = () => {
    if (props.buttonType === "save") {
      return <SaveIcon />;
    } else if (props.buttonType === "add") {
      return <AddSharp />;
    } else if (props.buttonType === "strike") {
      return <StrikethroughSIcon />;
    }
    return null;
  };

  const classes = useStyles();
  return (
    <Button
      // ref={props.ref}
      className={classes.root}
      key={props.buttonKey}
      type={props.type}
      startIcon={buttonType()}
      size="small"
      onClick={props.buttonClicked}
      disabled={props.disabled}
      style={props.style}
    >
      {props.children}
    </Button>
  );
};

export default ButtonExport;
