import React, { useState, useEffect } from "react";
// import appBase from "../../Auth/base";
import Spinner from "../../UI/Spinner/Spinner";
import Home from "../../HomeStudent";

const Profile = () => {
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    setSpinner(false);
  }, []);

  let returnVal = <Spinner />;
  if (!spinner) {
    returnVal = (
      <div>
        <p>Team Member Profile</p>
      </div>
    );
  }
  return <Home>{returnVal}</Home>;
  // return <>{returnVal}</>;
};

export default Profile;
