import React, { useState } from "react";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import appSettings from "../../appSettings";
// import InputAdornment from "@material-ui/core/InputAdornment";

const Textbox = (props) => {
  let valFromProps = "";
  let lengthFromProps = 0;
  if (props.value) {
    valFromProps = props.value;
    lengthFromProps = valFromProps.length;
  }
  const [text, setText] = useState({
    str: valFromProps,
    lng: lengthFromProps,
  });

  const [error, setError] = useState(false);

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiTextField-root": {
        // backgroundColor: "#FFF",
        margin: theme.spacing(1),
        maxWidth: props.maxWidth,
        width: props.width,
        borderColor: appSettings.themeColor,
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: appSettings.themeColor,
      },
      "& label.Mui-focused": {
        color: appSettings.themeColor,
      },

      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: appSettings.themeColor,
        },
        "&:hover fieldset": {
          borderColor: appSettings.themeColor,
        },
        "&.Mui-focused fieldset": {
          borderColor: appSettings.themeColor,
        },
      },
    },
  }));
  const textboxSize = () => {
    if (!props.size) {
      return "small";
    }
    return "medium";
  };
  const custOnChange = (event) => {
    event.preventDefault();
    let txt = event.target.value;

    if (props.custom === "phone") {
      const numrx = /^[0-9\b]+$/;
      // const firstChar = txt.slice(0, 1);
      const lastChar = txt.slice(-1);
      if (txt.length >= text.lng) {
        if (!numrx.test(lastChar) || txt.length > 14) {
          return false;
        }
        // if (txt.length === 3 || txt.length === 7) {
        //   txt = txt + "-";
        // }
        if (text.lng === 0) {
          txt = "(" + txt;
        }
        if (txt.length === 4) {
          txt = txt + ") ";
        }
        if (txt.length === 9) {
          txt = txt + "-";
        }
      } else {
        txt = "";
      }
    }
    if (props.custom === "number") {
      // const numrx = /^[0-9\b]+$/; //integer only
      // const numrx = /^\d+\.\d{0,2}$/; //decimal only
      const numrx = /^\d+(\.\d{1,2})?$/; //decimals, integer optional
      // const firstChar = txt.slice(0, 1);
      const lastChar = txt.slice(-1);
      if (txt.length >= text.lng) {
        if (!numrx.test(lastChar) || txt.length > props.maxlength) {
          return false;
        }
      } else {
        txt = "";
      }
      props.numberRange &&
      (txt < props.numberRange.low || txt > props.numberRange.high)
        ? setError(true)
        : setError(false);
    }
    props.textChange && props.textChange(event.target.name, txt);
    setText({
      ...text,
      str: txt,
      lng: txt.length,
    });
  };

  const classes = useStyles();
  return (
    <span className={classes.root}>
      <TextField
        required={props.required}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        id={props.id}
        name={props.name}
        label={props.label}
        defaultValue={props.defaultValue}
        error={error}
        value={text.str}
        helperText={props.helperText}
        variant={props.variant}
        type={props.type}
        InputProps={props.InputProps}
        InputLabelProps={props.InputLabelProps}
        autoComplete={props.autoComplete}
        multiline={props.multiline}
        size={textboxSize()}
        placeholder={props.placeholder}
        onChange={custOnChange}
        className={clsx(classes.margin, classes.textField)}
      />
    </span>
  );
};

export default Textbox;
