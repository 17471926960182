import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import appSettings from "../../appSettings";

const CustomSwitch = withStyles({
  switchBase: {
    color: "#DDDDDD",
    "&$checked": {
      color: appSettings.secondaryColor,
    },
    "&$checked + $track": {
      backgroundColor: appSettings.secondaryColor,
    },
  },
  checked: {},
  track: {},
})(Switch);

export default function CustomizedSwitches(props) {
  return (
    <FormControlLabel
      control={
        <CustomSwitch
          checked={props.checked}
          onChange={props.onChange}
          name={props.name}
        />
      }
      label={props.label}
    />
  );
}
