import React, { useState, useEffect, useCallback } from 'react';
import { createContext } from './';
import { db, auth } from '../Auth/base';
import { getTeacherDoc } from '../global/globalFunctions';

const [Provider, useAuthContext] = createContext({ name: 'AuthContext' });

const AuthContextProvider = ({ children }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [user, setUser] = useState(null);
	const [userType, setUserType] = useState('');
	const [teacherId, setTeacherId] = useState('');
	const [teacherVerified, setTeacherVerified] = useState(true);
	const [pending, setPending] = useState(true);
	const [errors, setErrors] = useState(false);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((userCreds) => {
			if (userCreds) {
				const { name, email, photoURL, emailVerified, uid, displayName } = userCreds;
				setUser({ name, displayName, email, photoURL, emailVerified, uid });
				setIsLoggedIn(true);
				setPending(false);
			} else {
				setUser(null);
				setIsLoggedIn(false);
				setPending(false);
			}
		});

		return unsubscribe;
	}, []);

	const getUserType = useCallback(async () => {
		const uid = auth.currentUser && auth.currentUser.uid;
		if (!uid) {
			console.info('%c No Uid for User Type: ', 'font-size: 16px; font-weight: bold; color: red;', uid);
			return;
		}

		try {
			const usersRef = db.collection('users').where('uid', '==', uid);

			if (!teacherId) {
				const userDoc = await usersRef.get();

				if (userDoc.docs.length) {
					// If we find a doc in users collections that means a teacher is logged in
					const newUser = userDoc.docs.map((doc) => ({
						id: doc.id,
					}));
					const teacherUid = newUser[0].id;
					setUserType('teacher');
					return teacherUid;
				} else {
					//is student
					// let isMentor = false;
					// let newUser = [];
					// const studentsRef = db.collection('users').doc(teacherId).collection('students').where('uid', '==', uid);
					// const studentDoc = await studentsRef.get();
					// console.log('ref1');
					// newUser = studentDoc.docs.map((doc) => ({
					// 	id: doc.id,
					// 	...doc.data(),
					// }));
					// if (newUser[0].isMentor) {
					// 	isMentor = true;
					// }

					// Else a student (user) is logged in
					const teacherDoc = await getTeacherDoc(uid);

					window.sessionStorage.setItem('studentTeacherDocID', teacherDoc.teacherDocId);
					setTeacherId(teacherDoc.teacherDocId);
					setUserType('student');
				}
			} else {
				const userDoc = await usersRef.get();

				if (userDoc.docs.length) {
					// If we find a doc in users collections that means a teacher is logged in
					const newUser = userDoc.docs.map((doc) => ({
						id: doc.id,
					}));
					const teacherUid = newUser[0].id;
					setUserType('teacher');
					return teacherUid;
				} else {
					const studentsRef = db.collection('users').doc(teacherId).collection('students').where('uid', '==', uid);

					const studentDoc = await studentsRef.get();

					const newUser = studentDoc.docs.map((doc) => ({
						id: doc.id,
						...doc.data(),
					}));
					const studentUid = newUser.length && newUser[0].id;
					window.sessionStorage.setItem('studentTeacherDocID', teacherId);
					if (newUser[0].isMentor) {
						setUserType('mentor');
					} else {
						setUserType('student');
					}
					return studentUid;
				}
			}
		} catch (err) {
			console.error('getUserType err: ', err);
			return err;
		}
	}, [teacherId]);

	useEffect(() => {
		if (user) {
			const exec = async () => {
				await getUserType();
			};
			exec();
		}
	}, [user, getUserType]);

	useEffect(() => {
		if (user && !userType) {
			const exec = async () => {
				await getUserType();
			};
			exec();
		}
	}, [user, userType, getUserType]);

	const login = async (creds, teacherId = '') => {
		if (teacherId) {
			setTeacherId(teacherId);
		}

		try {
			setIsLoading(true);
			const userCreds = await auth.signInWithEmailAndPassword(creds.email, creds.password);
			const { name, email, photoURL, emailVerified, uid, displayName } = userCreds.user;

			setUser({ name, displayName, email, photoURL, emailVerified, uid });

			return true;
		} catch (err) {
			console.error('Auth login error: ', err);
			setErrors(err);
			return err;
		}
	};

	const logout = async () => {
		try {
			setIsLoading(true);
			await auth.signOut();
			setIsLoggedIn(false);
			setUser(null);
			setUserType('');
		} catch (err) {
			console.error('Auth logout error: ', err);
			return err;
		} finally {
			setIsLoading(false);
		}
	};

	const signup = async (data) => {
		try {
			const userCreds = await auth.createUserWithEmailAndPassword(data.email, data.password);
			const {
				user: { name, email, photoURL, emailVerified, uid, displayName },
			} = userCreds;
			setUser({
				name,
				email,
				photoURL,
				emailVerified,
				uid,
				displayName,
				errors,
			});
			return uid;
		} catch (err) {
			console.error('Auth signup error: ', err.code, err.message);
			setErrors(err);
			return err;
		}
	};

	// console.log(`%c User: `, 'color: green; font-weight: bold;', user);
	// console.log(`%c User Type`, 'color: orange; font-weight: bold;', userType);
	// console.log(`%c Teacher ID`, 'color: orange; font-weight: bold;', teacherId);

	if (pending) {
		return <>Loading..</>;
	}
	return (
		<Provider
			value={{
				onLogout: logout,
				onLogin: login,
				onSignup: signup,
				isLoggedIn,
				isLoading,
				getUserType,
				user,
				userType,
				teacherId,
				setTeacherId,
				teacherVerified,
				setTeacherVerified,
				pending,
				errors,
			}}
		>
			{children}
		</Provider>
	);
};

export { AuthContextProvider, useAuthContext };
