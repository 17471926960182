import React, { useState, useEffect, useCallback } from "react";
import {
  withRouter,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { Container, Card, AppBar, Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useAppContext, useAuthContext } from "../../context";
import { ROUTES } from "../../global/constants";
import Alert from "../../UI/Alerts/Alert";
import Button from "../../UI/Forms/Button";
import Spinner from "../../UI/Spinner/Spinner";
import Textbox from "../../UI/Forms/Textbox";
import appBase, { db } from "../../Auth/base.js";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  tabs: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: 30,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StudentSignin = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { teacherDocId } = useParams();
  const { onLogin, onSignup, setTeacherId, teacherId, isLoggedIn } =
    useAuthContext();
  const { setCurrentUser } = useAppContext();
  const [userDocID, setUserDocID] = useState(props.match.params.teacherDocId);
  const [value, setValue] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [errAlert, setErrAlert] = useState({
    show: false,
    msg: "",
    type: "error",
  });
  const [studentDeactivated, setStudentDeactivated] = useState(false); //eslint-disable-line

  useEffect(() => {
    // If you are trying to get the link for the students
    // dont redirect to /home page
    if (location.state && location.state.teacherLink) return;

    if (location.pathname.includes("login") && isLoggedIn) {
      setTeacherId(teacherDocId);
      setCurrentUser((pre) => ({
        ...pre,
        teacherDocId,
        userType: "student",
      }));

      history.push(ROUTES.teachers.home);
    }
  }, [
    location,
    history,
    isLoggedIn,
    setCurrentUser,
    setTeacherId,
    teacherId,
    teacherDocId,
  ]);

  useEffect(() => {
    if (teacherDocId) {
      setUserDocID(teacherDocId);
      setCurrentUser((pre) => ({
        ...pre,
        teacherDocId,
        userType: "student",
      }));
    }
  }, [teacherDocId, setCurrentUser]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      setSpinner(true);

      const { email, password } = event.target.elements;
      const creds = {
        email: email.value,
        password: password.value,
      };

      try {
        const res = await onLogin(creds, userDocID);
        if (res.message) {
          setSpinner(false);
          setErrAlert({
            show: true,
            msg: res.message + " Please try again.",
            type: "error",
          });
        } else {
          history.push(ROUTES.users.home);
        }
      } catch (error) {
        setSpinner(false);
        setErrAlert({
          show: true,
          msg: error.message + " Please try again.",
          type: "error",
        });
      }
    },
    [history, onLogin, userDocID]
  );

  const handleSignUp = async (event) => {
    event.preventDefault();
    setSpinner(true);

    try {
      const { first, last, school, email, password } = event.target.elements;
      const signupData = {
        email: email.value,
        password: password.value,
      };
      const res = await onSignup(signupData);

      if (res.message) {
        setSpinner(false);
        setErrAlert({
          show: true,
          msg: res.message,
          type: "error",
        });
        return;
      } else {
        const studentEntry = {
          active: true,
          first: first.value,
          last: last.value,
          school: school.value,
          email: email.value,
          uid: res,
          teacherDocId: userDocID,
        };

        const studentDoc = db
          .collection("users")
          .doc(userDocID)
          .collection("students")
          .doc(res);
        await studentDoc.set(studentEntry);

        setSpinner(false);
        history.push(ROUTES.users.home);
      }
    } catch (err) {
      console.log("Error adding user to db: ", err);
      setSpinner(false);
      setErrAlert({
        show: true,
        msg: err.message,
        type: "error",
      });
    }
  };

  const handlePassword = (event) => {
    event.preventDefault();
    const { email } = event.target.elements;
    appBase
      .auth()
      .sendPasswordResetEmail(email.value)
      .then(() => {
        console.log("password reset request sent.");
        setErrAlert({
          show: true,
          msg: "The password request has been sent!",
          type: "success",
        });
      })
      .catch((error) => {
        console.log("error in password reset", error);
        setErrAlert({
          show: true,
          msg: error.message,
          type: "error",
        });
      });
  };

  let loadingReg = <Button type="submit">Register</Button>;
  let loadingSignin = <Button type="submit">Sign In</Button>;
  let loadingPassword = <Button type="submit">Reset Password</Button>;
  if (spinner) {
    loadingReg = <Spinner />;
    loadingSignin = <Spinner />;
    loadingPassword = <Spinner />;
  }
  if (studentDeactivated) {
    return (
      <React.Fragment>
        <div className={classes.toolbar} />
        <Container maxWidth="lg">
          <div className={classes.tabs} style={{ marginTop: 100 }}>
            This Team Member account is currently inactive.
          </div>
        </Container>
      </React.Fragment>
    );
  }
  if (!props.teacherVerified) {
    return (
      <React.Fragment>
        <div className={classes.toolbar} />
        <Container maxWidth="lg">
          <div className={classes.tabs} style={{ marginTop: 100 }}>
            The Team Member Sign In Page you are trying to reach does not exist.
            Please contact your instructor for the correct URL.
          </div>
        </Container>
      </React.Fragment>
    );
  } else {
    return (
      <>
        <div className={classes.toolbar} />
        <Container maxWidth="sm">
          <div className={classes.tabs}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="Member Sign In" {...a11yProps(0)} />
                <Tab label="Register" {...a11yProps(1)} />
                <Tab label="Lost Password" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <Alert
              showAlert={errAlert.show}
              close={() => setErrAlert({ show: false, msg: "", type: "error" })}
              type={errAlert.type}
            >
              {errAlert.msg}
            </Alert>
            <TabPanel value={value} index={0}>
              <Card style={{ padding: 20, backgroundColor: "#FFFFFF" }}>
                <form onSubmit={handleLogin}>
                  <div>
                    <Textbox
                      id="id-email"
                      name="email"
                      type="email"
                      width="96%"
                      required={true}
                      label="Email"
                    />
                  </div>
                  <div>
                    <Textbox
                      id="id-password"
                      name="password"
                      type="password"
                      width="96%"
                      required={true}
                      label="Password"
                    />
                  </div>
                  {loadingSignin}
                </form>
              </Card>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Card style={{ padding: 20, backgroundColor: "#FFFFFF" }}>
                <form autoComplete="off" onSubmit={handleSignUp}>
                  <div>
                    <Textbox
                      id="id-first"
                      name="first"
                      type="text"
                      width="96%"
                      required={true}
                      label="First Name"
                    />
                  </div>
                  <div>
                    <Textbox
                      id="id-last"
                      name="last"
                      type="text"
                      width="96%"
                      required={true}
                      label="Last Name"
                    />
                  </div>
                  <div>
                    <Textbox
                      id="id-school"
                      name="school"
                      type="text"
                      width="96%"
                      required={true}
                      label="School Name"
                    />
                  </div>
                  <div>
                    <Textbox
                      id="id-email"
                      name="email"
                      type="email"
                      width="96%"
                      required={true}
                      label="Email Address"
                    />
                  </div>
                  <div>
                    <Textbox
                      inputProps={{
                        autoComplete: "off",
                      }}
                      id="id-password"
                      name="password"
                      type="password"
                      width="96%"
                      required={true}
                      label="Password"
                    />
                  </div>
                  {loadingReg}
                </form>
              </Card>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Card style={{ padding: 20, backgroundColor: "#FFFFFF" }}>
                <form onSubmit={handlePassword}>
                  <div>
                    <Textbox
                      id="id-email"
                      name="email"
                      type="email"
                      width="96%"
                      required={true}
                      label="Email"
                    />
                  </div>
                  {loadingPassword}
                </form>
              </Card>
            </TabPanel>
          </div>
        </Container>
      </>
    );
  }
};

export default withRouter(StudentSignin);
