import React from 'react';
import { useAuthContext } from '../../context';
import HomeStudent from '../../HomeStudent';

const Homescreen = () => {
	const { user, userType } = useAuthContext();
	// console.log(user.uid);

	return (
		// <HomeStudent>
		//   <div>Home</div>
		// </HomeStudent>

		<HomeStudent>
			<div>
				<p>Team Member Homescreen</p>
				{user && (
					<pre>
						<p>Name: {user.name}</p>
						<p>Email: {user.email}</p>
						<p>uid: {user.uid}</p>
						<p>Display Name: {user.displayName}</p>
						{userType && <p>User Type: {userType}</p>}
					</pre>
				)}
			</div>
		</HomeStudent>
	);
};

export default Homescreen;
