import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../global/constants/routes";
import { makeStyles } from "@material-ui/core/styles";
import { useAuthContext, useAppContext } from "../context";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Person from "@material-ui/icons/Person";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import appSettings from "../appSettings";

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: (props) => `calc(100% - ${props.drawerWidth}px)`,
      marginLeft: (props) => props.drawerWidth,
    },
    color: appSettings.appbarColor,
    backgroundColor: appSettings.appbarBG,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const Appbar = ({
  drawerWidth,
  signOut,
  handleDrawerOpen,
  hideMenu,
  isTeacher,
}) => {
  const history = useHistory();
  const classes = useStyles({ drawerWidth });
  const { teacherId } = useAuthContext();
  const { toggleDrawer } = useAppContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleClick = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);

    if (isTeacher) {
      history.push(ROUTES.teachers.profile);
    } else {
      history.push(ROUTES.users.profile);
    }
  }, [isTeacher, history]);

  const handleLogout = useCallback(async () => {
    setOpen(false);

    if (isTeacher) {
      await signOut();
      history.push(ROUTES.teachers.login);
    } else {
      await signOut();
      history.push(ROUTES.users.login.replace(":teacherDocId", teacherId));
    }
  }, [isTeacher, history, signOut, teacherId]);

  const handleDrawer = () => {
    handleDrawerOpen();
    toggleDrawer();
  }

  return (
    <AppBar position="absolute" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawer}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="inherit"
          component="p"
          noWrap
          style={{ flexGrow: 1 }}
        >
          {appSettings.appName}
        </Typography>
        {!hideMenu && (
          <div id="app-toolbar">
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Person />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClick}>Profile</MenuItem>
              <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Appbar;
