import React, { useState, useEffect } from "react";
import Home from "../../Home";
import {
  getDocID,
  getStudentNotebook,
  getStepsAndProcessesProject,
} from "../../global/globalFunctions";
import Spinner from "../../UI/Spinner/Spinner";
import AllEntries from "./AllEntries";
import Discussion from "../../PagesStudent/Notebook/Discussion";

const Notebook = (props) => {
  const [spinner, setSpinner] = useState(true);
  const [notebookDetails, setNotebookDetails] = useState([]);
  const [steps, setSteps] = useState([]);
  const [notebookID] = useState(props.match.params.notebookid);
  const [teacherDocID, setTeacherDocID] = useState(null);
  const [processSelect, setProcessSelect] = useState(null);

  useEffect(() => {
    const getUIDandNotebook = async () => {
      try {
        const tchrDocID = await getDocID();
        setTeacherDocID(tchrDocID);
        const processedResponse = await getStudentNotebook(
          tchrDocID,
          props.match.params.notebookid
        );
        setNotebookDetails(processedResponse);

        const fetchSteps = await getStepsAndProcessesProject(
          tchrDocID,
          processedResponse.projectID
        );
        setSteps(fetchSteps);
        setSpinner(false);
      } catch (error) {
        console.log("getUIDandNotebook error", error);
        setSpinner(false);
      }
    };
    getUIDandNotebook();
  }, [props.match.params.notebookid]);

  return (
    <Home
      isNotebookPage={true}
      steps={steps}
      projectID={notebookDetails.projectID}
      notebookID={props.match.params.notebookid}
      // passProcessID={(id) => setProcessSelect(id)}
      xxx={(id) => {
        setProcessSelect(id);
      }}
    >
      {spinner ? (
        <Spinner />
      ) : processSelect === "discussion" ? (
        <Discussion
          teacherDocID={teacherDocID}
          projectID={notebookDetails.projectID}
          notebookID={props.match.params.notebookid}
          type="teacher"
          steps={steps}
        />
      ) : (
        <AllEntries
          notebookID={notebookID}
          steps={steps}
          teacherDocID={teacherDocID}
          passProcessID={processSelect}
          type="teacher"
          gotoDiscussion={() => setProcessSelect("discussion")}
          // processDetails={processDetails}
        />
      )}
    </Home>
  );
};

export default Notebook;
