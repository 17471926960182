import React from "react";
import Home from "../../Home";

const Helpfile = () => {
  return (
    <Home>
      <p>Help File</p>
    </Home>
  );
};

export default Helpfile;
