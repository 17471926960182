import React, { useState, useEffect } from 'react';
import Home from '../../Home';
import styles from './Homescreen.module.css';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PlayCircleIcon from '@material-ui/icons/PlayCircleFilled';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import HelpIcon from '@material-ui/icons/Help';
import MenuBook from '@material-ui/icons/MenuBook';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import Person from '@material-ui/icons/Person';
import { Link, Redirect } from 'react-router-dom';
import appSettings from '../../appSettings';
import { ROUTES } from '../../global/constants/routes';
import { useAuthContext } from '../../context';
import Modal from '../../UI/Modals/RegularModal';
import Textbox from '../../UI/Forms/Textbox';
import Button from '../../UI/Forms/Button';
import appBase from '../../Auth/base';

// gs://eden-73426.appspot.com/assets/EDEN_Overview.mp4

const useStyles = makeStyles((theme) => ({
	root: {},
	paper: {
		padding: theme.spacing(4),
		textAlign: 'center',
		// width: 200,
		height: 160,
	},
	icon: {
		width: 40,
		height: 40,
		color: appSettings.themeColor,
	},
}));

const Homescreen = () => {
	const classes = useStyles();

	const { user, isLoggedIn } = useAuthContext();

	const [modal, setModal] = useState(false);
	const [feedback, setFeedback] = useState('');

	useEffect(() => {
		if (!user && !isLoggedIn) {
			return <Redirect to={ROUTES.teachers.login} />;
		}
	}, [isLoggedIn, user]);

	return (
		<Home>
			<Modal show={modal} header="Feedback Form" handleClose={() => setModal(false)}>
				<>
					<p>
						Thank you for using HeatCorp's Engineering Design Electronic Notebook! We are currently in early stages of
						development and are adding new features every week.
					</p>
					<p>
						Your feedback of the application is not only welcome, but critical to future development. Any thoughts,
						critiques, or ideas for improvement you have will be given appreciative consideration.
					</p>
					<div>
						<Textbox
							label="Your Feedback"
							multiline={true}
							width="98%"
							textChange={(name, txt) => setFeedback(txt)}
							required={true}
						/>
					</div>
					<Button
						buttonClicked={() => {
							if (feedback.length > 3) {
								appBase
									.firestore()
									.collection('feedback')
									.add({
										feedback: feedback,
										userID: user.uid,
										createdDT: new Date(),
									})
									.then(() => {})
									.catch((error) => {
										console.log(error);
									});
							}
							setModal(false);
						}}
					>
						Submit Feedback
					</Button>
					<Button buttonClicked={() => setModal(false)}>Cancel</Button>
					<p>
						<strong>Thank you for helping us improve!</strong>
					</p>
				</>
			</Modal>
			<div
				style={{
					color: 'blue',
					textAlign: 'center',
					marginBottom: 25,
					cursor: 'pointer',
				}}
				onClick={() => setModal(true)}
			>
				Got 30 seconds? We'd love to hear your feedback on EDEN!
			</div>
			<div className={classes.root}>
				<Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
					<Grid item xs={12} sm={6} md={4} lg={2}>
						<a
							href="/p/Lv4TnnnIKCRiYiVKVUAK"
							target="_blank"
							rel="noopener noreferrer"
							style={{ textDecoration: 'none', fontSize: '1.1rem' }}
						>
							<Paper className={classes.paper}>
								<div>
									<PlayCircleIcon className={classes.icon} />
								</div>
								<div>Watch Video - Getting Started</div>
							</Paper>
						</a>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={2}>
						<a
							href="/p/UOre2Wa3z9qHrN0uvbaP"
							target="_blank"
							rel="noopener noreferrer"
							style={{ textDecoration: 'none', fontSize: '1.1rem' }}
						>
							<Paper className={classes.paper}>
								<div>
									<PlayCircleIcon className={classes.icon} />
								</div>
								<div>Watch Video - EDEN Overview</div>
							</Paper>
						</a>
					</Grid>
				</Grid>
				<Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
					<Grid item xs={12} sm={6} md={4} lg={2}>
						<Link to={ROUTES.teachers.members} className={styles.Link}>
							<Paper className={classes.paper}>
								<div>
									<PeopleAltIcon className={classes.icon} />
								</div>
								<div>Team Members</div>
							</Paper>
						</Link>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={2}>
						<Link to={ROUTES.teachers.projects} className={styles.Link}>
							<Paper className={classes.paper}>
								<div>
									<MenuBook className={classes.icon} />
								</div>
								<div>Projects</div>
							</Paper>
						</Link>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={2}>
						<Link to={ROUTES.teachers.account} className={styles.Link}>
							<Paper className={classes.paper}>
								<div>
									<FolderSpecialIcon className={classes.icon} />
								</div>
								<div>Account</div>
							</Paper>
						</Link>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={2}>
						<Link to={ROUTES.teachers.profile} className={styles.Link}>
							<Paper className={classes.paper}>
								<div>
									<Person className={classes.icon} />
								</div>
								<div>Profile</div>
							</Paper>
						</Link>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={2}>
						<Link to={ROUTES.teachers.help} className={styles.Link}>
							<Paper className={classes.paper}>
								<div>
									<HelpIcon className={classes.icon} />
								</div>
								<div>Help / FAQ</div>
							</Paper>
						</Link>
					</Grid>
				</Grid>
			</div>
			{/* <div>{feedback}</div> */}
		</Home>
	);
};

export default Homescreen;
