export const ROUTES = {
	base: '/',
	home: '/home',
	teachers: {
		home: '/teacher/home',
		login: '/teacher/login',
		profile: '/teacher/profile',
		account: '/teacher/account',
		openpo: '/teacher/openpo/:purchaseid',
		members: '/teacher/team-members',
		member: '/teacher/team-member/:id',
		projects: '/teacher/projects',
		project: '/teacher/project/:id',
		notebook: '/teacher/notebook/:notebookid',
		adminhome: '/teacher/adminhome/',
		// notebookstep: "/teacher/notebook/:projectid/:notebookid/:processid",
		help: '/teacher/help',
		helpfile: '/teacher/helpfile/:id',
		openurl: '/nb/:teacherid/:notebookid',
	},
	users: {
		home: '/user/home',
		login: '/user/login/:teacherDocId',
		projects: '/user/projects',
		members: '/user/team-members',
		member: '/user/team-member/:id',
		mentorprojects: '/user/mntprojects',
		mentorproject: '/user/mntproject/:id',
		mentornotebook: '/user/mntnotebook/:notebookid',
		notebook: '/user/notebook/:projectid/:notebookid',
		notebookentry: '/user/notebook/:projectid/:notebookid/:entryid',
		notebookintro: '/user/notebookintro/:projectid/:notebookid',
		discussion: '/user/discussion/:projectid/:notebookid',
		// notebookstep: "/user/notebook/:projectid/:notebookid/:processid",
		profile: '/user/profile',
		help: '/user/help',
	},
};
