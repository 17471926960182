import appBase from "../Auth/base";

export const getStudentNotebooks = (teacherDocID, studentDocID) => {
  return new Promise((resolve, reject) => {
    const db = appBase.firestore().collection("users").doc(teacherDocID);
    db.collection("student-notebook")
      .where("studentID", "==", studentDocID)
      .get()
      .then((snapshot) => {
        const theProjects = snapshot.docs.map((doc) => ({
          id: doc.id,
          projectID: doc.data().projectID,
          notebookID: doc.data().notebookID,
        }));
        resolve(theProjects);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const filterStudentProjects = (notebooks, projects) => {
  return new Promise((resolve) => {
    const newArray = [];
    //eslint-disable-next-line
    projects.map((project) => {
      //eslint-disable-next-line
      notebooks.find((element) => {
        if (project.id === element.projectID) {
          newArray.push({
            ...project,
            notebookID: element.notebookID,
            tableType: "student",
          });
        }
      });
    });
    resolve(newArray);
  });
};
