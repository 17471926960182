import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import appSettings from "../../appSettings";

const ResponsiveDialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const title = () => {
    if (props.header) {
      return (
        <DialogTitle
          id="form-dialog-title"
          style={{ padding: 10, paddingLeft: 25 }}
        >
          <div style={{ color: appSettings.themeColor, fontSize: 14 }}>
            {props.header}
          </div>
          <IconButton
            aria-label="close"
            // className={classes.closeButton}
            style={{ position: "absolute", right: 1, top: 1 }}
            onClick={props.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      );
    }
    return "";
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={props.show}
        onClose={props.modalClosed}
        aria-labelledby="responsive-dialog-title"
      >
        {title()}
        {/* <DialogContent>{props.children}</DialogContent> */}
        <DialogContent>{props.children}</DialogContent>
      </Dialog>
    </>
  );
};
export default ResponsiveDialog;
