import React from "react";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import ReactHtmlParser from "react-html-parser";

const config = {
  loader: { load: ["input/mml", "output/chtml"] },
  mml: {},
};

const MathWrapper = ({ html }) => (
  <MathJaxContext version={3} config={config}>
    <MathJax inline hideUntilTypeset="first">
      {ReactHtmlParser(html)}
    </MathJax>
  </MathJaxContext>
);

export default MathWrapper;
