import React from "react";
// import { Link } from "react-router-dom";
// import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Header from "../../UI/Header/Header";
import Help from "../../UI/Modals/Help";

export default function MenuAppBar(props) {
  const groups = [
    { title: "-RESET FILTER-", id: 0 },
    ...props.notebookGroups,
    { title: "-Show Ungrouped-", id: 1 },
  ];

  return (
    <>
      {/* {JSON.stringify(props.filteredNotebook, 0, 3)} */}
      <Header
        crumbtrail="Projects"
        crumbtrailTo="/teacher/projects"
        title={
          <>
            <span style={{ fontSize: 14, fontWeight: 500, color: "#444444" }}>
              {props.project.title}
            </span>
          </>
        }
        help={<Help helpId="WLRvMBA35achS159xIdu" preText="" />}
        editTitle="Edit Project"
        editClick={props.openModalEdit}
        libraryAddTitle="Add New Notebook"
        libraryAddClick={props.addButtonClicked}
        filterTitle={
          props.notebookGroups.length > 1 ? "Filter Notebooks by Group" : null
        }
        filterOptions={groups}
        filteredNotebook={props.filteredNotebook}
        handleFilterSelect={(id) => {
          props.handleFilterSelect(id);
        }}
      />
    </>
  );
}
