import React, { useState, useCallback } from "react";
import { useAuthContext } from "../context";
import { Redirect, useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { ROUTES } from "../global/constants/routes";
import { addNewTeacher, allStates } from "../global/globalFunctions";
import LoginModalEULA from "./LoginModalEULA";
import appBase from "../Auth/base.js";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import Textbox from "../UI/Forms/Textbox";
import Dropdown from "../UI/Forms/Dropdown";
import Button from "../UI/Forms/Button";
import Alert from "../UI/Alerts/Alert";
import Modal from "../UI/Modals/RegularModal";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Spinner from "../UI/Spinner/Spinner";
// import { db } from "../Auth/base";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  tabs: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: 30,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Login = () => {
  const { onLogin, onSignup, isLoggedIn, user } = useAuthContext();
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [regFields, setRegFields] = useState([]);
  const [modalEULA, setModalEULA] = useState({ show: false, agree: false });
  const [errAlert, setErrAlert] = useState({
    show: false,
    msg: "",
    type: "error",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      setSpinner(true);

      const { email, password } = event.target.elements;
      const creds = {
        email: email.value,
        password: password.value,
      };

      try {
        const res = await onLogin(creds);
        if (res.message) {
          setSpinner(false);
          setErrAlert({
            show: true,
            msg: res.message + " Please try again.",
            type: "error",
          });
        } else {
          history.push(ROUTES.teachers.home);
        }
      } catch (error) {
        setSpinner(false);
        setErrAlert({
          show: true,
          msg: error.message + " Please try again.",
          type: "error",
        });
      }
    },
    [history, onLogin]
  );

  const triggerEULA = (event) => {
    event.preventDefault();
    const {
      first,
      last,
      phone,
      org,
      district,
      street,
      street2,
      city,
      state,
      zip,
      country,
      email,
      password,
    } = event.target.elements;

    const teacherEntry = {
      first: first.value,
      last: last.value,
      phone: phone.value,
      org: org.value,
      district: district.value,
      street: street.value,
      street2: street2.value,
      city: city.value,
      state: state.value,
      zip: zip.value,
      country: country.value,
    };
    setRegFields({
      reg: teacherEntry,
      email: email.value,
      password: password.value,
    });
    setModalEULA({ show: true, agree: false });
  };

  const handleSignUp = async () => {
    setModalEULA({ show: false, agree: true });
    // console.log(regFields);
    setSpinner(true);

    try {
      const signupData = {
        email: regFields.email,
        password: regFields.password,
      };
      const res = await onSignup(signupData);

      if (res.message) {
        setSpinner(false);
        setErrAlert({
          show: true,
          msg: res.message,
          type: "error",
        });
        return;
      }
      const addRes = { ...regFields.reg, email: regFields.email, uid: res };
      await addNewTeacher(res, addRes);
      // console.log("added new teacher id:", newTeacherID);

      setSpinner(false);
      history.push(ROUTES.teachers.home);
    } catch (err) {
      console.error("Error adding user to db: ", err);
      setSpinner(false);
      setErrAlert({
        show: true,
        msg: err.message,
        type: "error",
      });
    }
  };

  // const handleSignUp = async (event) => {
  //   event.preventDefault();
  //   setModalEULA({ show: true, agree: false });
  //   // return;
  //   setSpinner(true);

  //   try {
  //     const {
  //       first,
  //       last,
  //       phone,
  //       org,
  //       district,
  //       state,
  //       country,
  //       email,
  //       password,
  //     } = event.target.elements;
  //     const signupData = {
  //       email: email.value,
  //       password: password.value,
  //     };
  //     const res = await onSignup(signupData);

  //     if (res.message) {
  //       setSpinner(false);
  //       setErrAlert({
  //         show: true,
  //         msg: res.message,
  //         type: "error",
  //       });
  //       return;
  //     }

  //     const teacherEntry = {
  //       first: first.value,
  //       last: last.value,
  //       phone: phone.value,
  //       org: org.value,
  //       district: district.value,
  //       state: state.value,
  //       country: country.value,
  //       uid: res,
  //     };

  //     const processAddNewTeacher = await addNewTeacher(res, teacherEntry);
  //     console.log("added new teacher", processAddNewTeacher);

  //     setSpinner(false);
  //     history.push(ROUTES.teachers.home);
  //   } catch (err) {
  //     console.error("Error adding user to db: ", err);
  //     setSpinner(false);
  //     setErrAlert({
  //       show: true,
  //       msg: err.message,
  //       type: "error",
  //     });
  //   }
  // };

  const handlePassword = (event) => {
    event.preventDefault();
    const { email } = event.target.elements;
    appBase
      .auth()
      .sendPasswordResetEmail(email.value)
      .then(() => {
        setErrAlert({
          show: true,
          msg: "The password request has been sent!",
          type: "success",
        });
      })
      .catch((error) => {
        setErrAlert({
          show: true,
          msg: error.message,
          type: "error",
        });
      });
  };

  let loadingReg = <Button type="submit">Register</Button>;
  // let loadingReg = <Button onClick={() => setModalEULA(true)}>Register</Button>;
  let loadingSignin = <Button type="submit">Sign In</Button>;
  let loadingPassword = <Button type="submit">Reset Password</Button>;
  if (spinner) {
    loadingReg = <Spinner />;
    loadingSignin = <Spinner />;
    loadingPassword = <Spinner />;
  }

  if (!!user && isLoggedIn) {
    return <Redirect to={ROUTES.teachers.home} />;
  }
  return (
    <React.Fragment>
      <div className={classes.toolbar} />
      <Container maxWidth="sm">
        <div className={classes.tabs}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Sign In" {...a11yProps(0)} />
              <Tab label="Register" {...a11yProps(1)} />
              <Tab label="Lost Password" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <Alert
            showAlert={errAlert.show}
            close={() => {
              setErrAlert({ show: false, msg: "", type: "error" });
            }}
            type={errAlert.type}
          >
            {errAlert.msg}
          </Alert>
          <Modal
            show={modalEULA.show}
            header="EDEN End User License Agreement"
            handleClose={() => setModalEULA({ show: false, agree: false })}
          >
            <LoginModalEULA
              EULAAgree={() => {
                handleSignUp();
              }}
            />
          </Modal>
          <TabPanel value={value} index={0}>
            <Card style={{ padding: 20, backgroundColor: "#FFFFFF" }}>
              <form onSubmit={handleLogin}>
                <div>
                  <Textbox
                    id="id-email"
                    name="email"
                    type="email"
                    width="96%"
                    required={true}
                    label="Email"
                  />
                </div>
                <div>
                  <Textbox
                    id="id-password"
                    name="password"
                    type="password"
                    width="96%"
                    required={true}
                    label="Password"
                  />
                </div>
                {loadingSignin}
              </form>
            </Card>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Card style={{ padding: 20, backgroundColor: "#FFFFFF" }}>
              <form autoComplete="off" onSubmit={triggerEULA}>
                <div>
                  <Textbox
                    id="id-first"
                    name="first"
                    type="text"
                    width="96%"
                    required={true}
                    label="First Name"
                  />
                </div>
                <div>
                  <Textbox
                    id="id-last"
                    name="last"
                    type="text"
                    width="96%"
                    required={true}
                    label="Last Name"
                  />
                </div>
                <div>
                  <Textbox
                    id="id-phone"
                    name="phone"
                    type="text"
                    width="96%"
                    required={true}
                    label="Phone Number"
                    custom="phone"
                  />
                </div>
                <div>
                  <Textbox
                    id="id-org"
                    name="org"
                    type="text"
                    width="96%"
                    required={true}
                    label="School or Organization"
                  />
                </div>
                <div>
                  <Textbox
                    id="id-district"
                    name="district"
                    type="text"
                    width="96%"
                    required={true}
                    label="School District"
                  />
                </div>
                <div>
                  <Textbox
                    width="60%"
                    name="street"
                    type="text"
                    required={true}
                    label="Street Address"
                  />
                  <Textbox
                    type="text"
                    name="street2"
                    label="Suite / Unit"
                    width="20%"
                  />
                </div>
                <div>
                  <Textbox name="city" required={true} label="City" />
                  <Dropdown
                    options={allStates}
                    label="State"
                    value=""
                    name="state"
                    id="idState"
                    required={true}
                    width="15%"
                  />
                  <Textbox
                    name="zip"
                    required={true}
                    label="Zip / Postal Code"
                  />
                </div>
                <div>
                  <Textbox
                    id="id-country"
                    name="country"
                    type="text"
                    width="96%"
                    required={true}
                    label="Country"
                    value=""
                  />
                </div>
                <div>
                  <Textbox
                    id="id-email"
                    name="email"
                    type="email"
                    width="96%"
                    required={true}
                    label="Email Address"
                  />
                </div>
                <div>
                  <Textbox
                    inputProps={{
                      autoComplete: "off",
                    }}
                    id="id-password"
                    name="password"
                    type="password"
                    width="96%"
                    required={true}
                    label="Password"
                  />
                </div>
                {loadingReg}
              </form>
            </Card>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Card style={{ padding: 20, backgroundColor: "#FFFFFF" }}>
              <form onSubmit={handlePassword}>
                <div>
                  <Textbox
                    id="id-email"
                    name="email"
                    type="email"
                    width="96%"
                    required={true}
                    label="Email"
                  />
                </div>
                {loadingPassword}
              </form>
            </Card>
          </TabPanel>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Login;
