import React, { useState, useEffect } from "react";
import Home from "../Home";
import {
  getTeacherProfile,
  projectNumberIsUnique,
  allStates,
} from "../global/globalFunctions";
import appBase from "../Auth/base";
import Spinner from "../UI/Spinner/Spinner";
import Textbox from "../UI/Forms/Textbox";
import Dropdown from "../UI/Forms/Dropdown";
import Button from "../UI/Forms/Button";
import Alert from "../UI/Alerts/Alert";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Profile = (props) => {
  const [user, setUser] = useState(null);
  const [spinner, setSpinner] = useState(true);
  const [errAlert, setErrAlert] = useState({
    show: false,
    msg: "",
    type: "error",
  });
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const uid = appBase.auth().currentUser.uid;
    // console.log("uid", uid);

    const getTeacher = async () => {
      try {
        const procTeacher = await getTeacherProfile(uid);
        const addData = {
          ...procTeacher,
          email: appBase.auth().currentUser.email,
          emailCurrent: appBase.auth().currentUser.email,
          number: procTeacher.number ? procTeacher.number : null,
        };
        setUser(addData);
        setSpinner(false);
      } catch (error) {
        console.log("ERR GETTING TEACHER", error);
        setErrAlert({
          show: true,
          msg: error.message,
          type: "error",
        });
        setSpinner(false);
      }
    };
    getTeacher();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const saveProfileHandler = (event) => {
    event.preventDefault();

    const saveProfileForm = async () => {
      try {
        const {
          first,
          last,
          phone,
          org,
          district,
          street,
          street2,
          city,
          state,
          zip,
          country,
          number,
        } = event.target.elements;
        const projNumber = parseInt(number.value);
        let errorMsg = null;
        if (!projNumber || projNumber < 100 || projNumber > 99999) {
          errorMsg =
            "Please enter a unique School/Organization Number between 100 and 99999.";
        }
        const numIsUnique = await projectNumberIsUnique(user.id, number.value);
        // console.log(IDofNumber);
        if (!numIsUnique) {
          errorMsg =
            "School/Organization Number " +
            projNumber +
            " is already taken. Please try another number.";
        }
        if (!errorMsg) {
          const updatedUser = {
            first: first.value,
            last: last.value,
            phone: phone.value,
            org: org.value,
            street: street.value,
            street2: street2.value,
            city: city.value,
            state: state.value,
            zip: zip.value,
            district: district.value,
            country: country.value,
            number: number.value,
          };

          setUser({
            id: user.id,
            email: user.email,
            emailCurrent: user.emailCurrent,
            ...updatedUser,
          });
          appBase
            .firestore()
            .collection("users")
            .doc(user.id)
            .update(updatedUser)
            .then((response) => {
              setErrAlert({
                show: true,
                msg: "Profile successfully changed!",
                type: "success",
              });
            })
            .catch((error) => {
              // console.log("error in user profile update", error);
              setErrAlert({
                show: true,
                msg: error.message,
                type: "error",
              });
            });
        } else {
          setErrAlert({
            show: true,
            msg: errorMsg,
            type: "error",
          });
        }
      } catch (error) {
        console.log("ERR", error);
      }
    };
    saveProfileForm();
  };

  const saveEmailHandler = (event) => {
    event.preventDefault();
    const { email, emailrepeat } = event.target.elements;
    if (email.value === user.emailCurrent) {
      setErrAlert({ show: true, msg: "E-mail is the same", type: "warning" });
      //stop function if email is unchanged
      return false;
    }
    if (email.value !== emailrepeat.value) {
      //stop function if email is unchanged
      // console.log("Emails don't match");
      setErrAlert({
        show: true,
        msg: "E-mail fields do not match",
        type: "error",
      });
      return false;
    }
    appBase
      .auth()
      .currentUser.updateEmail(email.value)
      .then(() => {
        appBase
          .firestore()
          .collection("users")
          .doc(user.id)
          .update({ email: email.value })
          .then(() => {});
        setErrAlert({
          show: true,
          msg: "You have successfully changed your e-mail!",
          type: "success",
        });
        setUser({
          ...user,
          email: email.value,
          emailCurrent: email.value,
        });
      })
      .catch((error) => {
        // console.log("error in auth update", error);
        setErrAlert({ show: true, msg: error.message, type: "error" });
      });
  };

  const savePasswordHandler = (event) => {
    event.preventDefault();
    const { password, passwordrepeat } = event.target.elements;
    if (password.value !== passwordrepeat.value) {
      // console.log("passwords don't match");
      setErrAlert({
        show: true,
        msg: "Passwords do not match",
        type: "error",
      });
      return false;
    }
    appBase
      .auth()
      .currentUser.updatePassword(password.value)
      .then((response) => {
        // console.log("successful password change");
        setErrAlert({
          show: true,
          msg: "Password successfully changed!",
          type: "success",
        });
      })
      .catch((error) => {
        // console.log("error in password update", error);
        setErrAlert({ show: true, msg: error.message, type: "error" });
      });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    tabs: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      margin: 10,
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }));
  const classes = useStyles();

  let returnVal = <Spinner />;
  if (!spinner) {
    returnVal = (
      <div className={classes.tabs}>
        <AppBar position="static" color="default">
          <Tabs value={value} onChange={handleChange} aria-label="profile tabs">
            <Tab label="Profile" {...a11yProps(0)} />
            <Tab label="E-mail" {...a11yProps(1)} />
            <Tab label="Password" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <Alert
          showAlert={errAlert.show}
          close={() => {
            setErrAlert({ show: false, msg: "", type: "error" });
          }}
          type={errAlert.type}
        >
          {errAlert.msg}
        </Alert>
        <TabPanel value={value} index={0}>
          <Card style={{ padding: 20 }}>
            <form autoComplete="off" onSubmit={saveProfileHandler}>
              <div>
                <Textbox
                  id="id-first"
                  name="first"
                  type="text"
                  width="96%"
                  required={true}
                  label="First Name"
                  value={user.first}
                />
              </div>
              <div>
                <Textbox
                  id="id-last"
                  name="last"
                  type="text"
                  width="96%"
                  required={true}
                  label="Last Name"
                  value={user.last}
                />
              </div>
              <Textbox
                id="id-phone"
                name="phone"
                type="text"
                width="96%"
                required={true}
                label="Phone Number"
                custom="phone"
                value={user.phone}
              />
              <div>
                <Textbox
                  id="id-org"
                  name="org"
                  type="text"
                  width="96%"
                  required={true}
                  label="School or Organization"
                  value={user.org}
                />
              </div>
              <div>
                <Textbox
                  id="id-district"
                  name="district"
                  type="text"
                  width="96%"
                  required={true}
                  label="School District"
                  value={user.district}
                />
              </div>
              <div>
                <Textbox
                  width="60%"
                  name="street"
                  type="text"
                  required={true}
                  label="Street Address"
                  value={user.street}
                />
                <Textbox
                  type="text"
                  name="street2"
                  label="Suite / Unit"
                  width="20%"
                  value={user.street2}
                />
              </div>
              <div>
                <Textbox
                  id="id-city"
                  name="city"
                  required={true}
                  label="City"
                  value={user.city}
                />
                <Dropdown
                  options={allStates}
                  label="State"
                  value={user.state ? user.state : ""}
                  name="state"
                  id="idState"
                  required={true}
                  width="15%"
                />
                <Textbox
                  id="id-zip"
                  name="zip"
                  required={true}
                  label="Zip / Postal Code"
                  value={user.zip}
                />
              </div>
              <div>
                {/* <Dropdown
                  options={allStates}
                  label="State"
                  value={user.state ? user.state : ""}
                  name="state"
                  id="idState"
                  required={true}
                  handleChange={(val) => {}}
                /> */}
                <Textbox
                  id="id-country"
                  name="country"
                  type="text"
                  width="96%"
                  required={true}
                  label="Country"
                  value={user.country}
                />
              </div>
              <div>
                <Textbox
                  id="id-number"
                  name="number"
                  // type="number"
                  custom="number"
                  width="96%"
                  required={true}
                  label="Unique School/Organization Number"
                  value={user.number}
                  maxlength={5}
                  numberRange={{
                    low: 100,
                    high: 99999,
                  }}
                  InputProps={{
                    maxLength: 5,
                    min: 100,
                    max: 99999,
                  }}
                />
              </div>
              <div>
                <Textbox
                  id="id-email"
                  name="email"
                  type="email"
                  width="96%"
                  label="E-mail Address"
                  value={user.email}
                  disabled={true}
                />
              </div>
              <div>
                <Button type="submit" buttonType="save">
                  Save
                </Button>
              </div>
            </form>
          </Card>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Card style={{ padding: 20 }}>
            <form onSubmit={saveEmailHandler}>
              <div>
                <Textbox
                  id="id-email"
                  name="email"
                  type="email"
                  width="96%"
                  required={true}
                  label="E-mail Address"
                  value={user.email}
                />
              </div>
              <div>
                <Textbox
                  id="id-email-repeat"
                  name="emailrepeat"
                  type="email"
                  width="96%"
                  required={true}
                  label="Repeat E-mail Address"
                  value={user.email}
                />
              </div>
              <div>
                <Button type="submit">Change E-mail</Button>
              </div>
            </form>
          </Card>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Card style={{ padding: 20 }}>
            <form onSubmit={savePasswordHandler}>
              <div>
                <Textbox
                  id="id-password"
                  name="password"
                  type="password"
                  required={true}
                  width="96%"
                  label="New Password"
                />
              </div>
              <div>
                <Textbox
                  id="id-password-repeat"
                  name="passwordrepeat"
                  type="password"
                  required={true}
                  width="96%"
                  label="Repeat Password"
                />
              </div>
              <div>
                <Button type="submit">Change Password</Button>
              </div>
            </form>
          </Card>
        </TabPanel>
      </div>
    );
  }
  return (
    <Home>
      <div></div>
      {returnVal}
      {/* <pre>{JSON.stringify(allStates, 0, 4)}</pre> */}
    </Home>
  );
};

export default Profile;
