import React, { useState } from "react";
import Textbox from "../../UI/Forms/Textbox";
import Dropdown from "../../UI/Forms/Dropdown";
import Button from "../../UI/Forms/Button";
import { allStates, addPurchaseOrder } from "../../global/globalFunctions";
import { useHistory } from "react-router-dom";

const PoDialog = (props) => {
  const [poForm, setPoForm] = useState({
    poNumber: "",
    poRequester: `${props.teacherProfile.first} ${props.teacherProfile.last}`,
    poRequesterEmail: props.teacherProfile.email,
    poRequesterPhone: props.teacherProfile.phone,
    poOrg: props.teacherProfile.org,
    poStreet1: "",
    poStreet2: "",
    poCity: "",
    poState: props.teacherProfile.state,
    poZip: "",
  });
  const history = useHistory();

  const handleTextChange = (nm, txt) => {
    setPoForm({ ...poForm, [nm]: txt });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const purchaseID = await addPurchaseOrder(
        props.teacherProfile.id,
        poForm,
        props.purchaseData
      );
      props.handleClose();
      history.push(`/teacher/openpo/${purchaseID}`);
    } catch (err) {
      console.log("[[PO ERROR]]", err);
    }
  };

  return (
    <div style={{ paddingBottom: 15 }}>
      <div>
        {`You are about to initiate a purchase order request for 
        ${props.purchaseData.quantity} EDEN Notebooks, with a total cost of  
        $${props.purchaseData.amount}`}
      </div>
      <div>
        The next page will allow you to download your invoice to initiate a
        purchase order within your organization. You may also download a copy of
        our form W-9 if needed.
      </div>
      <div>
        Please carefully review the information below and make any needed
        corrections. The Requester may be yourself, a manager, or somebody in a
        purchasing role.
      </div>
      <form name="frmPO" onSubmit={handleSubmit}>
        <div>
          <Textbox
            value={poForm.poNumber}
            name="poNumber"
            width="98%"
            required={false}
            label="Purchase Order Number (leave blank if none)"
            textChange={(nm, txt) => handleTextChange(nm, txt)}
          />
        </div>
        <div>
          <Textbox
            value={poForm.poRequester}
            name="poRequester"
            width="98%"
            required={true}
            label="Name of PO Requester"
            textChange={(nm, txt) => handleTextChange(nm, txt)}
          />
        </div>
        <div>
          <Textbox
            value={poForm.poRequesterEmail}
            name="poRequesterEmail"
            width="98%"
            required={true}
            label="Email of PO Requester"
            type="email"
            textChange={(nm, txt) => handleTextChange(nm, txt)}
          />
        </div>
        <div>
          <Textbox
            value={poForm.poRequesterPhone}
            name="poRequesterPhone"
            width="98%"
            required={true}
            label="Phone of PO Requester"
            // custom="phone"
            textChange={(nm, txt) => handleTextChange(nm, txt)}
          />
        </div>
        <div>
          <Textbox
            value={poForm.poOrg}
            name="poOrg"
            width="98%"
            required={true}
            label="School or Organization"
            textChange={(nm, txt) => handleTextChange(nm, txt)}
          />
        </div>
        <div>
          <Textbox
            value={poForm.poStreet1}
            width="60%"
            name="poStreet1"
            required={true}
            label="Street Address"
            textChange={(nm, txt) => handleTextChange(nm, txt)}
          />
          <Textbox
            value={poForm.poStreet2}
            name="poStreet2"
            label="Suite / Unit"
            textChange={(nm, txt) => handleTextChange(nm, txt)}
          />
        </div>
        <div>
          <Textbox
            value={poForm.poCity}
            name="poCity"
            required={true}
            label="City"
            textChange={(nm, txt) => handleTextChange(nm, txt)}
          />
          <Dropdown
            options={allStates}
            label="State"
            width="15%"
            value={props.teacherProfile.state ? props.teacherProfile.state : ""}
            name="poState"
            required={true}
            handleChange={(sel) => handleTextChange("poState", sel)}
          />
          <Textbox
            value={poForm.poZip}
            name="poZip"
            required={true}
            label="Zip / Postal Code"
            textChange={(nm, txt) => handleTextChange(nm, txt)}
          />
        </div>
        <div>
          <Button type="submit">Start Purchase Order</Button>
          <Button buttonType="delete" buttonClicked={props.handleClose}>
            Cancel
          </Button>
        </div>
      </form>
      {/* <pre>{JSON.stringify(poForm, 0, 3)}</pre> */}
      {/* <pre>{JSON.stringify(props, 0, 3)}</pre> */}
    </div>
  );
};

export default PoDialog;
