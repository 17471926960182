import React, { useState } from "react";
import Modal from "../../UI/Modals/RegularModal";
import Textbox from "../../UI/Forms/Textbox";
import Button from "../../UI/Forms/Button";
import appBase from "../../Auth/base";

const OpenComments = (props) => {
  const [feedback, setFeedback] = useState("");
  //   console.log(props);
  return (
    <Modal
      show={props.info.show}
      header="Judges' Notes"
      handleClose={props.handleClose}
    >
      <>
        <p>Judges, please provide your notes in the space below.</p>
        <div>
          <Textbox
            label="Judge Note"
            multiline={true}
            width="98%"
            textChange={(name, txt) => setFeedback(txt)}
            required={true}
            autoFocus={true}
          />
        </div>
        <Button
          buttonClicked={() => {
            if (feedback.length > 1) {
              appBase
                .firestore()
                .collection("users")
                .doc(props.info.teacherDocID)
                .collection("notebooks")
                .doc(props.info.notebookID)
                .collection("feedback")
                .add({
                  feedback: feedback,
                  createdDT: new Date(),
                })
                .then(() => {})
                .catch((error) => {
                  console.log(error);
                });
              props.handleSubmit();
            }
          }}
        >
          Submit Note
        </Button>
        <Button buttonClicked={props.handleClose}>Cancel</Button>
      </>
    </Modal>
  );
};

export default OpenComments;
