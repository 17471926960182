import React, { useState, useEffect } from 'react';
import Home from '../../Home';
import HomeStudent from '../../HomeStudent';
import appBase from '../../Auth/base';
import { getStudents, toTitleCase, TabPanel, a11yProps } from '../../global/globalFunctions';
import Button from '../../UI/Forms/Button';
import Modal from '../../UI/Modals/RegularModal';
import Alert from '../../UI/Alerts/Alert';
import Snackbar from '../../UI/Alerts/Snackbar';
import Spinner from '../../UI/Spinner/Spinner';
import Help from '../../UI/Modals/Help';
import AddEdiStudents from './AddEditStudents';
import StudentsTable from './StudentsTable';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { ROUTES } from '../../global/constants';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	tabs: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		margin: 10,
	},
	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	linkText: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: 8,
		marginBottom: 0,
	},
}));

const Students = (props) => {
	const classes = useStyles();
	const [teacherDocId] = useState(props.teacherDocId);
	const [spinner, setSpinner] = useState(true);
	const [activeStudentList, setActiveStudentList] = useState([]);
	const [inactiveStudentList, setInactiveStudentList] = useState([]);
	const [modalNew, setModalNew] = useState({
		show: false,
	});
	const closeModalNew = () => {
		setModalNew({ show: false });
	};
	const [errAlert, setErrAlert] = useState({
		show: false,
		msg: '',
		type: 'error',
	});
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: '',
		type: 'error',
	});
	const [value, setValue] = useState(0);

	useEffect(() => {
		const getUIDandStudents = async () => {
			try {
				const activeStudentList = await getStudents(props.teacherDocId, true);
				setActiveStudentList(activeStudentList);
				const inactiveStudentList = await getStudents(props.teacherDocId, false);
				setInactiveStudentList(inactiveStudentList);

				setSpinner(false);
			} catch (error) {
				setSpinner(false);
				console.log(error);
			}
		};
		getUIDandStudents();
		// console.log("Students mounted");
		// return () => {
		//   console.log("Students unmounted");
		// };
	}, [props.teacherDocId]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleCopyLink = () => {
		const pathname = ROUTES.users.login.replace(':teacherDocId', props.teacherDocId);
		navigator.clipboard.writeText(`${window.location.origin}${pathname}`);

		setSnackbar({
			open: true,
			message: 'Link copied successfully!',
			severity: 'success',
		});
	};

	let returnVal = <Spinner />;
	if (!spinner) {
		returnVal = (
			<div>
				<Modal
					show={modalNew.show}
					// modalClosed={this.closeModalHandler}
					header="New Team Member"
					minwidth={300}
					minheight={0}
					handleClose={closeModalNew}
					// handleClose={() => setModalNew({ show: false })}
				>
					<AddEdiStudents
						handleClose={closeModalNew}
						handleSave={(event) => {
							setSpinner(true);
							event.preventDefault();
							const {
								first,
								last,
								email,
								school,
								// password,
							} = event.target.elements;
							first.value = toTitleCase(first.value);
							last.value = toTitleCase(last.value);
							email.value = email.value.toLowerCase();
							school.value = toTitleCase(school.value);

							appBase
								.firestore()
								.collection('users/' + teacherDocId + '/students')
								.add({
									first: first.value,
									last: last.value,
									email: email.value,
									school: school.value,
									active: true,
								})
								.then((response) => {
									// console.log(response);
									setSpinner(false);
									const addedstudent = {
										id: response.id,
										first: first.value,
										last: last.value,
										email: email.value,
										school: school.value,
									};
									setActiveStudentList([addedstudent, ...activeStudentList]);
									setSpinner(false);
									setSnackbar({
										open: true,
										message: first.value + ' ' + last.value + ' Added Successfully',
										severity: 'success',
									});

									closeModalNew();
								})
								.catch((error) => {
									// console.log("error in adding users", error);
									// alert(error);
									setErrAlert({
										show: true,
										msg: error.message,
										type: 'error',
									});
									setSpinner(false);
								});
						}}
					/>
				</Modal>
				{/* <div>
          <Button
            buttonType="add"
            buttonClicked={() => setModalNew({ show: true })}
          >
            New Team Member
          </Button>
        </div> */}
				<Snackbar
					open={snackbar.open}
					close={() => {
						setSnackbar({
							open: false,
							message: '',
							severity: snackbar.severity,
						});
					}}
					message={snackbar.message}
					severity={snackbar.severity}
				/>
				<p className={classes.linkText}>
					All new Team Members must use your personal link to register
					<Help helpId="8wz6hw4eciJS8fKELlyq" />
				</p>
				<Button variant="outlined" aria-label="copy link" buttonClicked={handleCopyLink}>
					Copy Link
				</Button>
				<Snackbar
					open={snackbar.open}
					close={() => {
						setSnackbar({
							open: false,
							message: '',
							severity: snackbar.severity,
						});
					}}
					message={snackbar.message}
					severity={snackbar.severity}
				/>
				<Alert
					showAlert={errAlert.show}
					close={() => {
						setErrAlert({ show: false, msg: '', type: 'error' });
					}}
					type={errAlert.type}
				>
					{errAlert.msg}
				</Alert>
				<div className={classes.tabs}>
					<AppBar position="static" color="default">
						<Tabs value={value} onChange={handleChange} aria-label="profile tabs">
							<Tab label="Active" {...a11yProps(0)} />
							<Tab label="Inactive" {...a11yProps(1)} />
						</Tabs>
					</AppBar>
					<Alert
						showAlert={errAlert.show}
						close={() => {
							setErrAlert({ show: false, msg: '', type: 'error' });
						}}
						type={errAlert.type}
					>
						{errAlert.msg}
					</Alert>
					<TabPanel value={value} index={0}>
						<Card style={{ padding: 20 }}>
							<StudentsTable dataArr={activeStudentList} userType={props.userType} />
						</Card>
					</TabPanel>
					<TabPanel value={value} index={1}>
						<Card style={{ padding: 20 }}>
							<StudentsTable dataArr={inactiveStudentList} userType={props.userType} />
						</Card>
					</TabPanel>
				</div>
			</div>
		);
	}
	if (props.userType === 'mentor') {
		return <HomeStudent>{returnVal}</HomeStudent>;
	} else {
		return <Home>{returnVal}</Home>;
	}
};

export default Students;
