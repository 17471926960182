import React, { useState, useEffect, useCallback } from 'react';
import { Route, Switch, useHistory, useLocation, Redirect, withRouter } from 'react-router-dom';
import { useAuthContext } from './context';
import { ROUTES } from './global/constants/routes';

import Appbar from './UI/Appbar';
import PrivateRoute from './Auth/PrivateRoute';
import Fourohfour from './Pages/Fourohfour/Fourohfour';

import Homescreen from './Pages/Homescreen/Homescreen';
import Projects from './Pages/Projects/Projects';
import Project from './Pages/Project/Project';
import Permalink from './Pages/Permalink/Permalink';
import Notebook from './Pages/Notebook/Notebook';
import Account from './Pages/Account/Account';
import OpenPO from './Pages/Account/OpenPO';
import Profile from './Pages/Profile';
import Help from './Pages/Help/Help';
import Helpfile from './Pages/Help/File';
import Students from './Pages/Students/Students';
import Student from './Pages/Student/Student';
import Login from './Pages/Login';
import OpenURL from './Pages/Notebook/OpenURL';
import AdminHome from './PagesAdmin/Index';

// import HomeStudent from "./HomeStudent";
import StudentSignin from './PagesStudent/Signin/Signin';
import StudentHomescreen from './PagesStudent/Homescreen/Homescreen';
import StudentProjects from './PagesStudent/Projects/Projects';
import StudentsMentor from './PagesMentor/Students/Students';
import NotebookIntro from './PagesStudent/Notebook/NotebookIntro';
import Discussion from './PagesStudent/Notebook/Discussion';
import StudentNotebook from './PagesStudent/Notebook/Notebook';
import StudentHelp from './PagesStudent/Help/Help';
// import NotebookModule from "./Pages/Notebook/NotebookModule";
// import NotebookModuleStudent from "./PagesStudent/Notebook/NotebookModule";
import StudentProfile from './PagesStudent/Profile/Profile';
import { verifyTeacher, getLastPath } from './global/globalFunctions';

const drawerWidth = 200;

const App = () => {
	const { isLoggedIn, onLogout, user, userType, getUserType, teacherId, setTeacherVerified, teacherVerified } =
		useAuthContext();
	const location = useLocation();
	const history = useHistory();
	const [isTeacher, setIsTeacher] = useState(Boolean(userType === 'teacher'));
	const [hideMenu, setHideMenu] = useState([]);
	const [mobileOpen, setMobileOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	// console.log('isTeacher: ', isTeacher);
	// console.log('user: ', user);
	// console.log('userType: ', userType);
	// console.log('isLoggedIn: ', isLoggedIn);

	const verify = useCallback(
		async (path) => {
			const verified = await verifyTeacher(path);
			setTeacherVerified(true);
			if (!verified) {
				window.sessionStorage.removeItem('studentTeacherDocID');
				setTeacherVerified(false);
				setLoading(false);
				await onLogout();
			}
		},
		[onLogout, teacherId, loading] //eslint-disable-line react-hooks/exhaustive-deps
	);

	useEffect(() => {
		if (location.pathname.includes('user/login')) {
			const path = getLastPath(location.pathname);
			verify(path);
			setLoading(false);
		}
	}, [location.pathname]); //eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (location.pathname.includes('login')) {
			setHideMenu({ drawerWidth: 0, hiddenMenu: true });
		} else if (location.pathname.includes('/nb/')) {
			setHideMenu({ drawerWidth: drawerWidth, hiddenMenu: true });
		} else if (location.pathname.includes('/p/')) {
			setHideMenu({ drawerWidth: 0, hiddenMenu: true });
		} else {
			setHideMenu({ drawerWidth: drawerWidth, hiddenMenu: false });
		}
	}, [location.pathname]);

	useEffect(() => {
		if (user && userType === 'teacher') {
			setIsTeacher(true);
		} else {
			setIsTeacher(false);
		}
	}, [user, userType]);

	useEffect(() => {
		// Handle redirects for base route "/"
		if (location.pathname === ROUTES.base) {
			if (isLoggedIn) {
				if (userType && userType === 'teacher') {
					return history.push(ROUTES.teachers.home);
				} else if (userType && userType === 'student') {
					return history.push(ROUTES.users.home);
				}
			} else {
				if (userType && userType === 'teacher') {
					return history.push(ROUTES.teachers.login);
				} else if (userType && userType === 'student') {
					return history.push(ROUTES.users.login);
				}
			}
		}
	}, [location.pathname, isLoggedIn, userType, history]);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const handleDrawerOpen = () => {
		setMobileOpen(!mobileOpen);
	};

	if (isLoggedIn && !userType) {
		getUserType();
	}
	return (
		<div className="App">
			<Appbar
				handleDrawerToggle={handleDrawerToggle}
				handleDrawerOpen={handleDrawerOpen}
				drawerWidth={hideMenu.drawerWidth}
				signOut={onLogout}
				loggedIn={isLoggedIn}
				hideMenu={hideMenu.hiddenMenu}
				isTeacher={isTeacher}
			/>
			<Switch>
				<Route path={ROUTES.teachers.login} component={Login} />
				<Route exact path={ROUTES.teachers.openurl} component={OpenURL} />
				<Route exact path={'/p/:id'} component={Permalink} />
				<Route
					path={ROUTES.users.login}
					render={(props) => <StudentSignin {...props} teacherVerified={teacherVerified} />}
				/>
				{user && isTeacher && (
					<>
						<PrivateRoute exact path={ROUTES.teachers.home} component={Homescreen} />
						<PrivateRoute exact path={ROUTES.teachers.members} component={Students} />
						<PrivateRoute exact path={ROUTES.teachers.member} component={Student} />
						<PrivateRoute exact path={ROUTES.teachers.projects} component={Projects} />
						<PrivateRoute exact path={ROUTES.teachers.project} component={Project} />
						<PrivateRoute exact path={ROUTES.teachers.notebook} component={Notebook} />
						{/* <PrivateRoute
              exact
              path={ROUTES.teachers.notebookstep}
              component={NotebookModule}
            /> */}
						<PrivateRoute exact path={ROUTES.teachers.account} component={Account} />
						<PrivateRoute exact path={ROUTES.teachers.openpo} component={OpenPO} />
						<PrivateRoute exact path={ROUTES.teachers.profile} component={Profile} />
						<PrivateRoute exact path={ROUTES.teachers.helpfile} component={Helpfile} />
						<PrivateRoute exact path={ROUTES.teachers.adminhome} component={AdminHome} />
						<PrivateRoute exact path={ROUTES.teachers.help} component={Help} />
						<PrivateRoute path="/user/home" component={Homescreen} />
						<Redirect exact to="/" />
					</>
				)}
				{user && !isTeacher && (
					// <HomeStudent>
					<>
						<PrivateRoute exact path={ROUTES.users.home} component={StudentHomescreen} />
						<PrivateRoute exact path={ROUTES.users.projects} component={StudentProjects} />
						<PrivateRoute exact path={ROUTES.users.members} component={StudentsMentor} />
						<PrivateRoute exact path={ROUTES.users.member} component={Student} />
						<PrivateRoute exact path={ROUTES.users.mentorprojects} component={Projects} />
						<PrivateRoute exact path={ROUTES.users.mentorproject} component={Project} />
						<PrivateRoute exact path={ROUTES.users.mentornotebook} component={Notebook} />
						<PrivateRoute exact path={ROUTES.users.notebookintro} component={NotebookIntro} />
						<PrivateRoute exact path={ROUTES.users.discussion} component={Discussion} />
						<PrivateRoute exact path={ROUTES.users.notebookentry} component={StudentNotebook} />
						<PrivateRoute exact path={ROUTES.users.notebook} component={StudentNotebook} />
						{/* <PrivateRoute
              exact
              path={ROUTES.users.notebookstep}
              component={NotebookModuleStudent}
            /> */}
						<PrivateRoute exact path={ROUTES.users.profile} component={StudentProfile} />
						<PrivateRoute exact path={ROUTES.users.help} component={StudentHelp} />
						<PrivateRoute path="/teacher/home" component={StudentHomescreen} />
						{/* <Redirect exact to="/" /> */}
					</>
					// </HomeStudent>
				)}
				{!user && <Redirect exact from={ROUTES.base} to={ROUTES.teachers.login} />}
				{!user && !isLoggedIn && <Redirect to={ROUTES.teachers.login} />}
				<Route path="*" component={Fourohfour} />
			</Switch>
			{/* {state.user ? (
          <Switch>
            <Route path="/test/" component={Test} />
            {isTeacher && (
              <>
                <Redirect exact from="/login/" to="/home/" />
                <Route path="/home/" component={Homescreen} />
                <Route path="/members/" component={Students} />
                <Route path="/member/:id/" component={Student} />
                <Route path="/projects/" component={Projects} />
                <Route path="/project/:id/" component={Project} />

                <Route path="/account/" component={Account} />
                <Route path="/profile/" component={Profile} />
                <Route path="/login/" component={Login} />
              </>
            )}
            {isStudentTeacherVerified && (
              <StudentContext.Provider value={{ ucvalue, setUcvalue }}>
                <Route
                  exact
                  path="/memberhome/" --> user.userHome
                  component={StudentHomescreen}
                />
                <Route path="/notebook/:id/" component={StudentNotebook} /> --> user.notebook
                <Route path="/myprojects/" component={StudentProjects} /> --> user.projects
                <Route path="/myprofile/" component={StudentProfile} /> --> user.profile
              </StudentContext.Provider>
            )}
            <Route path="*" component={Fourohfour} />
          </Switch>
        ) : (
          <Switch>
            <Route path="/login/" component={Login} />
            <Route
              exact
              path="/membersignin/:userdocid/" --> user.login
              render={(props) => (
                <StudentSignin
                  {...props}
                  verifiedTeacher={isStudentTeacherVerified}
                  docID={docID}
                />
              )}
            />
            <Route path="*" component={Fourohfour} />
          </Switch>
        )} */}
			{/* <div style={{ background: "purple" }}>
        studentTeacherDocID:{" "}
        {window.sessionStorage.getItem("studentTeacherDocID")}
      </div> */}
			{/* <div style={{ background: "gray" }}>docID: {docID}</div> */}
			{/* <div style={{ background: "lightblue" }}>
        isTeacher: {isTeacher ? "true" : "false"}
      </div> */}
			{/* <div style={{ background: "lightblue" }}>
        isStudentTeacherVerified: {isStudentTeacherVerified ? "true" : "false"}
      </div> */}
			{/* <div style={{ background: "yellow" }}>
        DOC ID:
        {JSON.stringify(docID, 0, 3)}
      </div> */}
			{/* <div style={{ background: "lime" }}>
        USER
        {JSON.stringify(state.user, 0, 3)}
      </div> */}
		</div>
	);
};

export default withRouter(App);
