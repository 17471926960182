import React, { useState } from "react";
import Accounts from "./Accounts/Accounts";
import Help from "./Help/Help";
import Purchases from "./Purchases/Purchases";
import Feedback from "./Feedback/Feedback";
import Home from "../Home";
import styles from "./Admin.module.css";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useAuthContext } from "../context";

const Homescreen = () => {
  const [boxClicked, setBoxClicked] = useState(null);
  const { user } = useAuthContext();
  const returnVal = () => {
    return (
      <>
        {!boxClicked && (
          <div>
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Paper
                  className={styles.Link}
                  onClick={() => setBoxClicked("acct")}
                >
                  <div className={styles.Text}>Accounts</div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Paper
                  className={styles.Link}
                  onClick={() => setBoxClicked("purch")}
                >
                  <div className={styles.Text}>Purchases</div>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Paper
                  className={styles.Link}
                  onClick={() => setBoxClicked("help")}
                >
                  <div className={styles.Text}>Help File</div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Paper
                  className={styles.Link}
                  onClick={() => setBoxClicked("feedback")}
                >
                  <div className={styles.Text}>Feedback</div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
        {boxClicked === "acct" && (
          <Accounts acctClicked={() => setBoxClicked(null)} />
        )}
        {boxClicked === "purch" && (
          <Purchases acctClicked={() => setBoxClicked(null)} />
        )}
        {boxClicked === "help" && (
          <Help acctClicked={() => setBoxClicked(null)} />
        )}
        {boxClicked === "feedback" && (
          <Feedback acctClicked={() => setBoxClicked(null)} />
        )}
      </>
    );
  };
  return (
    <Home>
      {((user && user.uid === "EjUhKS1konSFxJGPI2LeFhVjFOP2") ||
        user.uid === "Cr4Avj1qE1bzUxx8eVhpz9IidyG3" ||
        user.uid === "AoqBo9QzsIPjgZCkgCbkzVDMyAO2") &&
        returnVal()}
      {/* <pre>{JSON.stringify(boxClicked, 0, 3)}</pre> */}
    </Home>
  );
};

export default Homescreen;
