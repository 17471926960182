import React, { useState } from "react";
import ClassicEditor from "../../CKEditor";
import Textbox from "../../UI/Forms/Textbox";
import Button from "../../UI/Forms/Button";
import { Paper } from "@material-ui/core";

export default function Scoring(props) {
  const [ckData, setCkData] = useState("");

  const saveHandler = (event) => {
    event.preventDefault();
    let formError = false;
    const arr = props.scoringArr.map((el, i) => {
      const thisScore = parseInt(event.target.elements[el.id].value);
      if (!thisScore || thisScore < el.scoreLow || thisScore > el.scoreHigh) {
        formError = true;
      }
      return {
        id: el.id,
        title: el.title,
        unit: el.unit,
        score: thisScore,
      };
    });
    if (formError) {
      props.sendScores(false);
    } else {
      props.sendScores(arr, ckData);
    }
    setCkData("");
    document.getElementById("frmScore").reset();
  };

  return (
    <Paper style={{ padding: 10 }}>
      <form id="frmScore" autoComplete="off" onSubmit={saveHandler}>
        {props.scoringArr.map((s, i) => {
          return (
            <div key={i} style={{ marginBottom: 20 }}>
              <Textbox
                label={s.title}
                // type="number"
                custom="number"
                required={true}
                maxlength={
                  s.scoreHigh ? s.scoreHigh.toString().length : props.maxlength
                }
                name={s.id}
                id={s.id}
                numberRange={{
                  low: s.scoreLow,
                  high: s.scoreHigh,
                }}
                InputProps={{
                  endAdornment: s.unit,
                  maxLength: s.scoreHigh.toString().length,
                  min: s.scoreLow,
                  max: s.scoreHigh,
                }}
                InputLabelProps={{ shrink: true }}
                // variant="outlined"
                helperText={s.desc}
              />
              {/* <div
                style={{ paddingLeft: 8, fontSize: "0.8rem", color: "#999999" }}
              >
                {s.desc}
              </div> */}
            </div>
          );
        })}
        <div>
          <ClassicEditor
            data={ckData}
            onChange={setCkData}
            placeholder="Optional Notes on Scoring..."
            teacherDocID={props.teacherDocID}
          />
        </div>
        <div>
          <Button type="submit" buttonType="save">
            Submit Scores
          </Button>
        </div>
      </form>
      {/* <pre>{JSON.stringify(ckData, 0, 3)}</pre> */}
    </Paper>
  );
}
