import React, { useState } from "react";
import Dropdown from "../../UI/Forms/Dropdown";
import Textbox from "../../UI/Forms/Textbox";
import Button from "../../UI/Forms/Button";
import { newNotebookToc, updateEntryToc } from "../../global/globalFunctions";

const TocModal = (props) => {
  const [tocSel, setTocSel] = useState({
    sel: props.tocId ? props.tocId : props.tocArr.length === 0 ? "new" : "",
    txt: "",
    tocTtl: props.tocTtl ? props.tocTtl : "",
  });
  const txtShow = () => {
    if (tocSel.sel === "new") {
      return (
        <div>
          <Textbox
            label="New Category"
            name="txtToc"
            id="toc"
            width="98%"
            value={tocSel.txt}
            required={true}
            textChange={(nm, txt) => setTocSel({ ...tocSel, txt: txt })}
          />
        </div>
      );
    }
    return "";
  };

  const saveHandler = async (event) => {
    event.preventDefault();
    try {
      let tocSelId = tocSel.sel;
      if (tocSel.sel === "new") {
        const procTocID = await newNotebookToc(
          props.teacherID,
          props.notebookID,
          tocSel.txt
        );
        tocSelId = procTocID;
        // await props.updateTocArr(tocSelId, tocSel.txt);
      }
      updateEntryToc(
        props.teacherID,
        props.notebookID,
        props.entryId,
        tocSelId,
        tocSel.tocTtl
      );
      props.handleClose();
    } catch (err) {
      console.log(err);
      props.handleClose();
    }
  };

  return (
    <>
      <div>
        Specify a title for this entry, and where in the Table of Contents this
        entry will be listed under.
      </div>
      <form id="frmScore" autoComplete="off" onSubmit={saveHandler}>
        <div>
          <Textbox
            label="Entry Title"
            name="txtTtl"
            id="tocTtl"
            width="98%"
            value={tocSel.tocTtl}
            required={true}
            textChange={(nm, txt) => setTocSel({ ...tocSel, tocTtl: txt })}
          />
        </div>
        <div>
          <Dropdown
            options={props.ddOptions}
            label="Table of Contents Category"
            // width="15%"
            // value={props.teacherProfile.state ? props.teacherProfile.state : ""}
            value={tocSel.sel}
            name="sel"
            required={true}
            handleChange={(sel) => {
              setTocSel({ ...tocSel, sel: sel });
            }}
          />
        </div>
        {txtShow()}
        <div>
          <Button type="submit">OK</Button>
          <Button buttonType="delete" buttonClicked={props.handleClose}>
            Cancel
          </Button>
        </div>
      </form>
      {/* <pre>{JSON.stringify(props, 0, 3)}</pre> */}
      {/* <pre>{JSON.stringify(tocSel, 0, 3)}</pre> */}
    </>
  );
};
export default TocModal;
