import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import appSettings from "../../appSettings";

const Dropdown = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#FFF",
      margin: theme.spacing(1),
      width: props.width ? props.width : "96%",
      borderColor: appSettings.themeColor,
      "& .MuiInput-underline:after": {
        borderBottomColor: appSettings.themeColor,
      },
      "& label.Mui-focused": {
        color: appSettings.themeColor,
      },

      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: appSettings.themeColor,
        },
        "&:hover fieldset": {
          borderColor: appSettings.themeColor,
        },
        "&.Mui-focused fieldset": {
          borderColor: appSettings.themeColor,
        },
      },
    },
  }));
  const classes = useStyles();
  // const [selectValue, setSelectValue] = React.useState("");
  const handleChange = (event) => {
    if (!props.handleChange) return;
    // setSelectValue(event.target.value);
    event.preventDefault();
    props.handleChange(event.target.value);
  };
  return (
    <FormControl className={classes.root}>
      <InputLabel id={props.id + "-label"}>{props.label}</InputLabel>
      <Select
        required={props.required}
        labelId={props.id + "-label"}
        name={props.name}
        id={props.id}
        defaultValue={props.value}
        onChange={handleChange}
      >
        {props.options.map((val, i) => {
          return (
            <MenuItem key={i} value={val.id}>
              {val.title}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default Dropdown;
