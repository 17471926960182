import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthContext } from "../context";
import { ROUTES } from "../global/constants/routes";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuthContext();

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !!user ? (
          <Component {...routeProps} />
        ) : (
          <Redirect to={ROUTES.base} />
        )
      }
    />
  );
};

export default PrivateRoute;
