import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import FileRepository from '@ckeditor/ckeditor5-upload/src/filerepository';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Image from '@ckeditor/ckeditor5-image/src/image';
// import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight";
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor';
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import Link from '@ckeditor/ckeditor5-link/src/link';
// import MathType from "@wiris/mathtype-ckeditor5";
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import TodoList from '@ckeditor/ckeditor5-list/src/todolist';
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import UploadAdapter from '../PagesStudent/Notebook/UploadAdapter';

const defaultPlaceholder = 'Type notebook entry here...';

class EditorInstance extends ClassicEditor {
	// eslint-disable-next-line
	constructor(sourceElementOrData, config) {
		super(sourceElementOrData, config);
	}

	static create(sourceElementOrData, config = {}) {
		return ClassicEditor.create(sourceElementOrData, {
			plugins: [
				Autoformat,
				Heading,
				Essentials,
				BlockQuote,
				// Highlight,
				FontColor,
				FontBackgroundColor,
				Paragraph,
				Bold,
				Italic,
				Strikethrough,
				// MathType,
				Image,
				ImageCaption,
				ImageToolbar,
				ImageStyle,
				ImageResize,
				ImageUpload,
				Table,
				TableToolbar,
				TableProperties,
				TableCellProperties,
				TodoList,
				ListStyle,
				Link,
				FileRepository,
			],
			fontColor: {
				colors: [
					{
						color: 'hsl(0, 0%, 0%)',
						label: 'Black',
					},
					{
						color: 'hsl(0, 0%, 30%)',
						label: 'Dim grey',
					},
					{
						color: 'hsl(0, 0%, 60%)',
						label: 'Grey',
					},
					{
						color: 'hsl(0, 0%, 90%)',
						label: 'Light grey',
					},
					{
						color: 'hsl(0, 0%, 100%)',
						label: 'White',
						hasBorder: true,
					},
					{
						color: 'hsl(0, 100%, 82%)',
						label: 'Light Red',
					},
					{
						color: 'hsl(0, 100%, 50%)',
						label: 'Red',
					},
					{
						color: 'hsl(0, 100%, 34%)',
						label: 'Dark Red',
					},
					{
						color: 'hsl(59, 100%, 52%)',
						label: 'Yellow',
					},
					{
						color: 'hsl(59, 100%, 37%)',
						label: 'Dark Yellow',
					},
					{
						color: 'hsl(40, 100%, 27%)',
						label: 'Brown',
					},
					{
						color: 'hsl(40, 100%, 13%)',
						label: 'Dark Brown',
					},
					{
						color: 'hsl(36, 100%, 46%)',
						label: 'Orange ',
					},
					{
						color: 'hsl(27, 100%, 39%)',
						label: 'Dark Orange',
					},
					{
						color: 'hsl(132, 100%, 34%)',
						label: 'Green',
					},
					{
						color: 'hsl(110, 100%, 14%)',
						label: 'Dark Green',
					},
					{
						color: 'hsl(228, 100%, 87%)',
						label: 'Light Blue',
					},
					{
						color: 'hsl(228, 100%, 58%)',
						label: 'Blue',
					},
					{
						color: 'hsl(228, 100%, 33%)',
						label: 'Dark Blue',
					},
					{
						color: 'hsl(272, 100%, 71%)',
						label: 'Light Purple',
					},
					{
						color: 'hsl(272, 100%, 51%)',
						label: 'Purple',
					},
					{
						color: 'hsl(272, 100%, 26%)',
						label: 'Dark Purple',
					},
					{
						color: 'hsl(314, 100%, 82%)',
						label: 'Light Pink',
					},
					{
						color: 'hsl(314, 100%, 63%)',
						label: 'Pink',
					},
					{
						color: 'hsl(314, 100%, 35%)',
						label: 'Dark Pink',
					},

					// ...
				],
			},
			fontBackgroundColor: {
				colors: [
					{
						color: 'hsl(0, 75%, 60%)',
						label: 'Red',
					},
					{
						color: 'hsl(30, 75%, 60%)',
						label: 'Orange',
					},
					{
						color: 'hsl(60, 75%, 60%)',
						label: 'Yellow',
					},
					{
						color: 'hsl(90, 75%, 60%)',
						label: 'Light green',
					},
					{
						color: 'hsl(120, 75%, 60%)',
						label: 'Green',
					},

					// ...
				],
			},
			// highlight: {
			//   options: [
			//     {
			//       model: "greenMarker",
			//       class: "marker-green",
			//       title: "Green marker",
			//       color: "var(--ck-highlight-marker-green)",
			//       type: "marker",
			//     },
			//     {
			//       model: "redPen",
			//       class: "pen-red",
			//       title: "Red pen",
			//       color: "var(--ck-highlight-pen-red)",
			//       type: "pen",
			//     },
			//   ],
			// },
			toolbar: {
				items: [
					'heading',
					'bold',
					'italic',
					// "highlight",
					'fontcolor',
					'fontbackgroundcolor',
					'link',
					'|',
					'strikethrough',
					'bulletedList',
					'numberedList',
					'todoList',
					'imageUpload',
					'insertTable',
					'blockQuote',
					'undo',
					'redo',
					'|',
					// "MathType",
					// "ChemType",
				],
			},
			image: {
				styles: ['alignLeft', 'alignCenter', 'alignRight'],
				resizeOptions: [
					{
						name: 'resizeImage:original',
						label: 'Original',
						value: null,
					},
					{
						name: 'resizeImage:25',
						label: '25%',
						value: '25',
					},
					{
						name: 'resizeImage:50',
						label: '50%',
						value: '50',
					},
					{
						name: 'resizeImage:75',
						label: '75%',
						value: '75',
					},
				],
				toolbar: [
					'imageStyle:alignLeft',
					'imageStyle:alignCenter',
					'imageStyle:alignRight',
					'|',
					'resizeImage',
					'|',
					'imageTextAlternative',
				],
			},
			table: {
				contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
			},
			placeholder: defaultPlaceholder,
			...config,
		});
	}
}

export default function Editor(props) {
	const { data, onChange, teacherDocID, notebookID, placeholder = '' } = props;

	return (
		<CKEditor
			editor={EditorInstance}
			data={data}
			config={{
				placeholder: placeholder.length ? placeholder : defaultPlaceholder,
			}}
			onReady={(editor) => {
				// console.log("Editor is ready to use!", editor);

				// editor.editing.view.focus();

				editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
					return new UploadAdapter(loader, teacherDocID, notebookID);
				};
			}}
			onChange={(event, editor) => {
				const data = editor.getData();
				// console.log({ event, editor, data });
				onChange(data);
			}}
			onBlur={(event, editor) => {
				const data = editor.getData();
				// console.log("Blur.", editor);
				onChange(data);
			}}
			onFocus={(event, editor) => {
				// console.log("Focus.", editor);
			}}
		/>
	);
}
