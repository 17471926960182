import React, { useState, useEffect } from "react";
import Home from "../../Home";
import {
  getStudentNotebook,
  getStepsAndProcessesProject,
} from "../../global/globalFunctions";
import Spinner from "../../UI/Spinner/Spinner";
import AllEntries from "./AllEntries";

const Notebook = (props) => {
  const [spinner, setSpinner] = useState(true);
  const [notebookDetails, setNotebookDetails] = useState([]);
  const [steps, setSteps] = useState([]);
  const [notebookID] = useState(props.match.params.notebookid);
  const [teacherDocID, setTeacherDocID] = useState(null);
  const [processSelect, setProcessSelect] = useState(null);

  useEffect(() => {
    const getUIDandNotebook = async () => {
      try {
        setTeacherDocID(props.match.params.teacherid);
        const processedResponse = await getStudentNotebook(
          props.match.params.teacherid,
          props.match.params.notebookid
        );
        setNotebookDetails(processedResponse);

        const fetchSteps = await getStepsAndProcessesProject(
          props.match.params.teacherid,
          processedResponse.projectID
        );
        setSteps(fetchSteps);
        setSpinner(false);
      } catch (error) {
        console.log("getUIDandNotebook error", error);
        setSpinner(false);
      }
    };
    getUIDandNotebook();
  }, [props.match.params.teacherid, props.match.params.notebookid]);

  if (spinner) {
    return <Spinner />;
  }

  return (
    <Home
      isNotebookPage={true}
      steps={steps}
      projectID={notebookDetails.projectID}
      notebookID={props.match.params.notebookid}
      // passProcessID={(id) => setProcessSelect(id)}
      xxx={(id) => {
        setProcessSelect(id);
      }}
      type="open"
    >
      {notebookDetails.isViewable ? (
        <>
          {/* {notebookDetails.isCommentable && <div>Comment</div>} */}
          <AllEntries
            notebookID={notebookID}
            steps={steps}
            passProcessID={processSelect}
            teacherDocID={teacherDocID}
            type="open"
            isCommentable={notebookDetails.isCommentable}
          />
        </>
      ) : (
        <>
          The notebook {notebookDetails.title} is not currently available for
          viewing.
        </>
      )}
      {/* <pre>props: {JSON.stringify(notebookDetails, 0, 3)}</pre> */}
    </Home>
  );
};

export default Notebook;

// import React, { useState, useEffect } from "react";
// import Home from "../../Home";
// const OpenURL = (props) => {
//   return (
//     <Home isNotebookPage={true}>
//       <div>Open URL</div>
//       <pre>props: {JSON.stringify(props, 0, 3)}</pre>
//     </Home>
//   );
// };
// export default OpenURL;
